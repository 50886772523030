@import '../../../../scss/--var.scss';
@import '../../../../scss/--mixing.scss';

// new back all page button
.new_all_page_back_btn {
  display: flex;
  align-items: center;
  background: rgba(208, 215, 222, 0.2);
  padding: 10px 1rem;
  span {
    svg {
      cursor: pointer;
      width: 25px;
      height: 21px;
      color: var(--first-text-color);
    }
    margin-right: 0.5rem;
  }
  p {
    margin-bottom: 0;
    text-transform: capitalize;
    color: var(--first-text-color);
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
    @include bg_img;
    border-radius: 100%;
  }
}
// new back all page button end
// NewOutlinedButtonWithIcon
.new_btn_with_icon {
  height: 40px !important;
  padding: 0.5rem !important;
  border: 1px solid var(--search-box-border-color) !important;
  text-transform: capitalize;
  background-color: var(--element-bg) !important;
  padding-right: 12px !important;
  .text {
    color: var(--first-text-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.00938rem;
    text-transform: capitalize;
  }
  .icon_div {
    padding: 0.4375rem 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    svg {
      width: 1rem;
      height: 1rem;
      color: var(--second-text-color);
    }
  }
}
// NewOutlinedButtonWithIcon end
// text btn
.text_btn {
  color: var(--first-text-color);
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 166.667% */
  &:hover {
    background-color: var(--search-box-data-hover);
  }
}
// text btn end
// Blue Button Text
.BlueButtonText_btn {
  color: #fff !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  height: 40px !important;
  padding: 8px 12px !important;
  line-height: 20px !important; /* 166.667% */
  background-color: #3656f4 !important;
  &:hover {
    background-color: var(--search-box-data-hover);
  }
}
// Blue Button Text end
// icon BlueButton
.IconButton_div {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  &:hover {
    background-color: var(--search-box-data-hover);
  }
  svg {
    width: 16px;
    height: 16px;
    color: var(--second-text-color);
  }
}
// icon BlueButton end
