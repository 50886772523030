@import '../../../../../../scss/--mixing.scss';
@import '../../../../../../scss/--var.scss';

.addNotes_title_inputDiv {
  padding: 16px;
  padding-top: 0;
  .MuiInputBase-root {
    &::before {
      border-color: var(--search-box-border-color) !important;
    }
  }

  .MuiFormControl-root {
    width: 100% !important;
  }

  textarea {
    color: var(--first-text-color);
    font-size: 28px !important;
    font-weight: 600 !important;
    line-height: normal;
    min-height: 43px !important;

    &::placeholder {
      color: rgba(140, 149, 159, 0.7);
      font-size: 28px;
      font-weight: 600;
      line-height: normal;
    }
  }
}
//

.New_ckEditer_div {
  margin: 16px;
  margin-top: 0;
  border: 1px solid var(--search-box-border-color);
  border-radius: 6px;
  margin-bottom: 36px;
  @include NewUser;
  .ck-content {
    min-height: 8rem !important;
    color: var(--first-text-color);
  }
  // .ck.ck-toolbar {
  //   position: relative;
  //   z-index: 0;
  // }
  .ck-toolbar {
    border: none !important;
    border-bottom: 1px solid var(--search-box-border-color) !important;
    border-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none !important;
    border: none !important;
  }
  .New_ckEditer_div_bottom_btn {
    border-top: 1px solid var(--search-box-border-color);
    padding: 5px;
  }
}
.New_ckEditer_dataView_div {
  margin: 16px;
  position: relative;
  @include NewUser;
  transition: all 0.3s ease-in-out;
  margin-bottom: 24px;
  &:hover {
    .New_ckEditer_InnerBtnDiv {
      display: flex;
    }
  }
  .dataDiv {
    color: var(--first-text-color);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    display: flex;
    .data_show {
      width: 26rem;
    }
  }
  .dataDiv_inner {
    display: flex;
    .Priority_show {
      margin-bottom: 0;
      margin-right: 10px;
      color: var(--first-text-color);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  .New_ckEditer_Dock_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .New_ckEditer_InnerBtnDiv {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: var(--element-bg2);
    box-shadow: var(--element-bg-box-shadow);
    border-radius: 6px;
    width: fit-content;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 10%;
  }
  .MobileMenuMain_div {
    .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
    .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu {
      left: -5rem;
    }
    .rs-dropdown-toggle {
      width: 36px;
      height: 36px;
      padding: 0;
      color: var(--first-text-color);
      background-color: transparent;
      &:hover {
        background-color: var(--search-box-data-hover);
      }
    }
    .rs-dropdown-toggle-caret {
      display: none;
    }
  }
}
.PriorityMenu_div {
  margin-right: 4px !important;
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu {
    left: 0 !important;
  }
  .rs-dropdown-toggle {
    width: 64px !important;
  }
  .icon_2 {
    font-size: 18px;
  }
}
.ckDeletBtnColor {
  color: var(--delete-color) !important;
  svg {
    color: var(--delete-color) !important;
  }
}
@media (max-width: 576px) {
  .CustomToolbarRender_div {
    margin-left: 5px;
    .yoopta-toolbar-root {
      display: block;
    }
  }
}
