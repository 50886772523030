.errorMsg {
  margin-top: 5px;
  color: #d1242f;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
}

.errorMsg span svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-bottom: 1px;
}

.errorMsg span {
  margin-right: 5px;
}
