@mixin bg_img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@mixin sh {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
@mixin FontFamily {
  font-family: 'Poppins' !important;
}
@mixin NewUser {
  .MuiAvatar-root {
    border-color: var(--search-box-border-color) !important;
    color: var(--first-text-color);
  }
  .MuiSvgIcon-root {
    color: var(--second-text-color);
  }
  .MuiAvatar-circular {
    background-color: var(--element-bg2);
  }
  .MuiAvatar-circular {
    width: 20px;
    height: 20px;
  }
  .Participants_icon {
    color: var(--first-text-color);
  }
}
