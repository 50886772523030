@import '../../../../scss/--mixing.scss';
@import '../../../../scss/--var.scss';
.new_top_nav_div {
  background-color: var(--top-nav-bg);
  height: 56px;
  border-bottom: 1px solid var(--search-box-border-color);
}
.Timesheet_main_btn_top {
  position: relative;
}

.Timesheet_main_btn {
  border-radius: 6px;
  border: 1px solid var(--search-box-border-color);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  // width: 6.3125rem;
  height: 2.5rem;
  transition: all 0.3s ease-in-out;

  // hide for phone screen
  @media screen and (max-width: 768px) {
    display: none;
  }

  &:hover {
    border-color: #9747ff;
  }

  p {
    margin-bottom: 0;
    color: var(--first-text-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
    margin-left: 0.5rem;
  }
  span {
    cursor: pointer;
    // background-color: #9747ff;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
    svg {
      color: #ffffff;
      font-size: 18px;
      transition: all 0.3s ease-in-out;
    }
  }
}
//
.avatar_container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-left: var(--margin-sm);
  z-index: 1;

  //SUBMENU
  .menu {
    box-shadow: var(--element-bg-box-shadow);
    position: absolute;
    top: 27px;
    left: -152px;
    background: var(--search-box-bg-color);
    border-radius: 6px;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 0.3s;
    width: 18rem;
    height: 20.5625rem;
  }

  .menu_active {
    display: block;
    pointer-events: initial;
    opacity: 1;
    overflow-y: scroll;
  }
}
.avatar_container_profile {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 0 16px;

  //SUBMENU
  .menu {
    box-shadow: var(--element-bg-box-shadow);
    position: absolute;
    top: 30px;
    right: 0;
    background: var(--search-box-bg-color);
    border-radius: 6px;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 0.3s;
    width: 312px;
    min-height: auto;
    max-height: 529px;
    transform: translateY(-10%);
  }

  .menu_active {
    display: block;
    pointer-events: initial;
    opacity: 1;
    transform: translateY(0%);
  }
}
//
.Timesheet_main_open_div {
  hr {
    margin: 0 0;
    opacity: 1;
    border-top: 1px solid var(--search-box-border-color);
  }
  .title_div {
    padding: 1rem;
    .timesheet_title_inner_div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      p {
        color: var(--first-text-color);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 111.111% */
        margin-bottom: 0;
      }
    }
    .timesheet_title_menu_div {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.timesheet_menu_div {
  .timesheet_menu_btn {
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    padding: 0 !important;
    @include FontFamily;
    span {
      svg {
        width: 16px;
        height: 16px;
        margin-left: 0.4rem;
      }
    }
  }
}
.timesheet_menu_inner_text {
  color: var(--first-text-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.timesheet_menu_inner_icon {
  margin-right: 0.5rem;
  svg {
    color: #848d97;
    width: 16px;
    height: 16px;
  }
  img {
    @include bg_img;
    margin-left: -3px;
  }
}
.timesheet_menu_inner_div {
  .MuiList-root {
    background-color: var(--element-bg2);
  }
  .MuiPaper-root {
    background-color: var(--element-bg2);
    border-radius: 6px;
    top: 100px !important;
    padding: 0.5rem 0.25rem;
  }
  .MuiButtonBase-root {
    height: 32px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    border-radius: 3px !important;
    &:hover {
      background-color: var(--search-box-data-hover) !important;
    }
  }
}
.top_nav_right_div {
  width: 100%;
  height: 56px;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .btn_div {
    display: flex;
    align-items: center;
    button {
      border: 1px solid #3656f4;
      border-radius: 6px;
      color: #3656f4;
      text-transform: capitalize;
      padding: 6px 8px;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
      &:hover {
        transition: all 0.3s ease-in-out;
        color: #ffffff;
        font-weight: 500;
        background-color: #3656f4;
        span {
          svg {
            color: #ffffff;
          }
        }
      }
      span {
        margin-right: 5px;
        display: flex;
        svg {
          color: #3656f4;
        }
      }
    }
    .line_div {
      margin-left: 1rem;
      margin-right: 0.5rem;
      width: 1px;
      height: 1rem;
      background-color: var(--search-box-border-color);
    }
  }
}

.top_nav_left_div {
  width: 100%;
  height: 56px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  .top_nav_menu_icon {
    margin-right: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
      color: var(--first-text-color);
    }
  }
}
.time_sheet_activity_div {
  padding: 1rem;
  .activity_title {
    color: var(--second-text-color);
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    span {
      svg {
        color: var(--b-w);
        width: 16px;
        height: 19px;
      }
    }
  }
  .activity_data_div {
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
      // background-color: #9747ff;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      transition: all 0.3s ease-in-out;
      svg {
        color: #ffffff;
        font-size: 18px;
        transition: all 0.3s ease-in-out;
      }
    }
    .activity_data_text {
      margin-left: 0.5rem;
      p {
        color: var(--first-text-color);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        margin-bottom: -4px;
      }
      small {
        color: var(--second-text-color);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.activity_data_scroll_div {
  // overflow: scroll;
  // height: 103px;
  .activity_data_div {
    margin-bottom: 10px;
  }
}
.Timesheet_no_data_div {
  height: 273px;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 3.125rem;
    height: 3.125rem;
    @include bg_img;
  }
  .no_data_title {
    color: var(--second-text-color);
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 0;
  }
  .no_data_link {
    color: #3656f4;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.top_nav_left_icon {
  cursor: pointer;
  margin-bottom: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  &:hover {
    background-color: var(--search-box-data-hover) !important;
  }
  .new_icon {
    font-size: 19px !important;
    height: unset !important;
    width: unset !important;
  }
  svg {
    color: var(--first-text-color);
    width: 16px;
    height: 16px;
  }
}
.top_nav_Search_modal_div {
  .ant-modal-close {
    display: none;
  }
  .input_div {
    padding-top: 1rem;
    padding-bottom: 10px;
    padding-right: 1rem;
    input {
      background-color: var(--search-box-bg-color);
      color: var(--second-text-color);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 100% */
      border: none;
      padding: 8px 10px;
      @include FontFamily;
      &::placeholder {
        color: var(--second-text-color);
      }
    }
  }
  .ant-modal-content {
    background-color: var(--element-bg2);
    // padding: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.top_nav_search_modal_tabs {
  .ant-tabs-tab-btn {
    color: var(--second-text-color);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-transform: capitalize;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--first-text-color);
  }
  .ant-tabs-nav {
    // margin-bottom: 0;
    &::before {
      border-bottom: 1px solid var(--search-box-border-color);
    }
  }
}
.top_nav_search_all {
  margin-bottom: 1.06rem;
  .ClearAll_btn {
    cursor: pointer;
    color: #d1242f;
    font-size: 0.75rem;
    font-weight: 400;
    margin-right: 20px;
    margin-bottom: 0.5rem;
  }
  .top_title {
    color: var(--first-text-color);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 166.667% */
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    // margin-top: 1.06rem;
  }
  .top_nav_search_data_all {
    margin-right: 20px;
    cursor: pointer;
    background-color: transparent;
    padding: 0.38rem;
    padding-left: 0.5rem;
    border-radius: 0.375rem;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: var(--search-box-data-hover);
      a {
        display: flex;
        transition: all 0.3s ease-in-out;
      }
      .delete_btn {
        display: flex;
      }
    }
    .delete_btn {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 1rem;
      width: 24px;
      height: 24px;
      display: none;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--search-box-border-color);
      border-radius: 6px;
      background: var(--element-bg2);
      svg {
        font-size: 13px !important;
        color: #d1242f !important;
      }
    }
    a {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      position: absolute;
      top: 5px;
      right: 4rem;
      display: none;
      transition: all 0.3s ease-in-out;
      background: var(--element-bg2);
      width: 24px;
      height: 24px;
      display: none;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--search-box-border-color);
      border-radius: 6px;
      svg {
        width: 13px;
        height: 13px;
        color: var(--first-text-color);
      }
    }
    span {
      margin-right: 1rem;
      svg {
        color: var(--first-text-color);
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
.top_nav_search_all_scroll {
  overflow: scroll;
  height: 357px;
  .top_nav_search_all_inner_scroll {
    height: 345px;
    overflow: auto;
  }
}
.top_nav_search_box_filter_data_div {
  border-left: 1px solid var(--search-box-border-color);
  padding-top: 1rem;
  padding-left: 2rem !important;
  position: relative;
  .close_btn_modal {
    position: absolute;
    top: 3px;
    right: -21px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background-repeat: 6px;
    transition: all 0.3s ease-in-out;
    margin: 0.5rem;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
    svg {
      color: var(--first-text-color);
      width: 18px;
      height: 18px;
    }
  }
}
.top_nav_search_box_filter_title_div {
  h3 {
    color: var(--first-text-color);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 166.667% */
    text-transform: uppercase;
    margin-top: 2rem;
  }
  .my_date_picker {
    .main_btn {
      border: 1px solid var(--search-box-border-color);
      border-radius: 6px;
    }
    .react-datepicker-popper {
      transform: translate(34px, 131px) !important;
    }
  }
}
.top_nav_dropdown_filter_title_div {
  h3 {
    color: var(--first-text-color);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 166.667% */
    text-transform: uppercase;
    margin-top: 2rem;
  }
  .dropdown_outer_div {
    .dropdown_inner_div {
      color: var(--first-text-color);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      span {
        margin-right: 0.5rem;
      }
    }
    .dropdown_inner_2_div {
      display: flex;
      justify-content: flex-end;
      .menu_btn_div {
        cursor: pointer;
        margin-bottom: 0;
        color: var(--second-text-color);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        span {
          margin-left: 0.5rem;
          svg {
            color: var(--second-text-color);
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

.timesheet_menu_inner_add_link_div {
  .MuiList-root {
    background-color: var(--element-bg2);
  }
  .MuiPaper-root {
    background-color: var(--element-bg2);
    border-radius: 12px;
    padding: 0.5rem 0.25rem;
  }
  .MuiButtonBase-root {
    height: 32px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    border-radius: 3px !important;
    &:hover {
      background-color: var(--search-box-data-hover) !important;
      border-radius: 6px !important;
    }
  }
}
.timesheet_menu_top_add_link_div {
  .MuiPaper-root {
    left: 940px !important;
    width: 17rem;
    top: 309px !important;
    background-color: var(--element-bg2);
  }
  .input_div {
    padding: 0.5rem;
    .ant-input-affix-wrapper {
      background-color: var(--search-box-bg-color);
      border: 1px solid var(--search-box-border-color);
    }
    .ant-input-prefix {
      color: var(--second-text-color);
    }
    input {
      width: 100%;
      background-color: var(--search-box-bg-color);
      color: var(--second-text-color);
      &::placeholder {
        color: var(--second-text-color);
      }
    }
  }
  .menu_item_div {
    display: flex;
    align-items: center;

    img {
      @include bg_img;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
    span {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
  }
  .MuiButtonBase-root {
    margin: 4px !important;
    border-radius: 6px !important;
    padding: 0.5rem !important;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
  }
}
.timesheet_menu_top_Created_by_div {
  .MuiPaper-root {
    left: 940px !important;
    width: 17rem;
    top: 340px !important;
    background-color: var(--element-bg2);
  }
  .input_div {
    padding: 0.5rem;
    .ant-input-affix-wrapper {
      background-color: var(--search-box-bg-color);
      border: 1px solid var(--search-box-border-color);
    }
    .ant-input-prefix {
      color: var(--second-text-color);
    }
    input {
      width: 100%;
      background-color: var(--search-box-bg-color);
      color: var(--second-text-color);
      &::placeholder {
        color: var(--second-text-color);
      }
    }
  }
  .menu_item_div {
    display: flex;
    align-items: center;

    img {
      @include bg_img;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
    span {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
  }
  .MuiButtonBase-root {
    margin: 4px !important;
    border-radius: 6px !important;
    padding: 0.5rem !important;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
  }
}
.timesheet_status_filter_div {
  .MuiPaper-root {
    left: 1071px !important;
    width: 7rem;
    top: 370px !important;
    background-color: var(--element-bg2);
  }
  .menu_item_div {
    display: flex;
    align-items: center;

    img {
      @include bg_img;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
    span {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
  }
  .MuiButtonBase-root {
    margin: 4px !important;
    border-radius: 6px !important;
    // padding: 0.5rem !important;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
  }
}
.top_nav_propfile_top_icon_div {
  border-radius: 100%;
  border: 2px solid;
  cursor: pointer;
  .MuiAvatar-root {
    background-color: var(--search-box-data-hover) !important;
    border-radius: 100% !important;
    padding-bottom: 3px !important;
    color: var(--first-text-color) !important;
    cursor: pointer;
  }
  .user_img {
    @include bg_img;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 1px;
    border-radius: 100%;
  }
}
.top_nav_top_inner_section {
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--element-bg2);
  .top_nav_top_section {
    padding: 16px;
    background-color: var(--search-box-data-hover);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom: 1px solid var(--search-box-border-color);
  }
  .sign_out_btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      @include FontFamily;
    }
  }
  .sign_out_main_title_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
  .user_inner_icon_div {
    margin: 0;
    display: flex;
    align-items: center;

    .user_li {
      margin-right: 8px;
      padding: 8px 0;
      .user_img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
      .MuiAvatar-root {
        background-color: #9747ff !important;
        border-radius: 100% !important;
        padding-bottom: 3px !important;
      }
    }
    h3 {
      color: var(--first-text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    p {
      color: var(--first-text-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}
.top_nav_top_subscription {
  padding: 16px;
  border-bottom: 1px solid var(--search-box-border-color);
  h3 {
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 16px;
  }
  p {
    color: var(--first-text-color);
    margin-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  a {
    color: #3656f4;
    text-transform: capitalize;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.top_nav_top_attendence {
  position: relative;
  border-bottom: 1px solid var(--search-box-border-color);
  padding-bottom: 16px;
  h3 {
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    padding: 16px;
    margin: 0;
  }
  p {
    color: var(--first-text-color);
    background-color: var(--checked-bg);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    border-left: 2px solid #1a7f37;
    margin-bottom: 8px;
    padding: 4px 16px;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 19px;
    right: 16px;
    svg {
      color: var(--first-text-color);
      width: 16px;
      height: 16px;
    }
  }
  .btn_inner_div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 16px;
    .active {
      background: rgba(54, 86, 244, 0.12);
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 8px;
      padding: 6px 10px;
    }
    button {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 8px;
      padding: 6px 10px;
      &:hover {
        background: rgba(54, 86, 244, 0.12);
      }
    }
  }
}
.top_nav_top_account {
  // border-bottom: 1px solid var(--search-box-border-color);
  padding: 16px;
  h3 {
    color: var(--first-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .link_div {
    margin-bottom: 5px;
    a {
      span {
        margin-right: 8px;
        svg {
          color: var(--first-text-color);
          width: 16px;
          height: 16px;
        }
      }
      &:hover {
        color: #3656f4;
        span {
          svg {
            color: #3656f4;
          }
        }
      }
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
.mode_nav_icon {
  display: flex;
  svg {
    color: #3656f4;
  }
}
.mode_nav_inner_icon {
  .MuiButtonBase-root {
    border-radius: 6px !important;
  }
  .top_nav_left_icon {
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}
.mode_nav_inner_menu {
  // .MuiPaper-root {
  //   left: 1266px !important;
  // }
  .MuiButtonBase-root {
    &:hover {
      .timesheet_menu_inner_icon {
        svg {
          color: #3656f4 !important;
        }
      }
      .timesheet_menu_inner_text {
        color: #3656f4 !important;
      }
    }
  }
}
.top_nav_search_people_data {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  transition: all 0.3s ease-in-out;
  padding: 8px !important;
  margin-right: 24px;
  border-radius: 6px;

  &:hover {
    background-color: var(--search-box-data-hover);
    p {
      .icon_copy {
        display: flex;
      }
    }
  }
  h3 {
    color: var(--first-text-color);
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  p {
    color: var(--first-text-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    display: flex;
    .icon_copy {
      display: none;
      margin-left: 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        color: #3656f4;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    padding-bottom: 4px;
  }
  .user_img_div {
    margin-right: 8px;
  }
}
.notification_top_nav_title {
  padding: 0.5rem 1rem;
  padding-top: 16px;
  position: relative;
  h3 {
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .close_btn_modal {
    position: absolute;
    top: 3px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background-repeat: 6px;
    transition: all 0.3s ease-in-out;
    margin: 0.5rem;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
    svg {
      color: var(--first-text-color);
      width: 18px;
      height: 18px;
    }
  }
  .close_menu_modal {
    position: absolute;
    top: 3px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    cursor: pointer;
    background-repeat: 6px;
    transition: all 0.3s ease-in-out;
    margin: 0.5rem;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
    svg {
      color: var(--first-text-color);
      width: 18px;
      height: 18px;
    }
  }
}
.notification_one_main_div_top {
  .MuiPaper-root {
    // margin-top: 90px !important;
    // border-radius: 6px !important;
    // margin-right: 10px !important;
    // box-shadow: none !important;
    background: var(--element-bg) !important;
  }
}
.notification_one_main_div {
  background-color: var(--element-bg);
  width: 450px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.notification_top_nav_tab {
  .ant-tabs-nav {
    &::before {
      border-bottom: 1px solid var(--search-box-border-color);
    }
  }
  .ant-badge {
    .ant-badge-count {
      background-color: #3656f4;
      color: #fff;
      font-weight: 500;
    }
  }
  .ant-tabs-nav-wrap {
    padding-left: 1rem;
  }
  .ant-tabs-tab-btn {
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--first-text-color) !important;
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
    }
  }
  .ant-tabs-tab-disabled {
    .ant-tabs-tab-btn {
      // opacity: 0.5 !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}
.notification_tab_inner {
  // padding: 1rem;
  // padding-top: 0;
  overflow: scroll;
  height: 40rem;
  position: relative;
  .all_show_page_link {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 1rem;
    color: #3656f4;
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
    background-color: var(--element-bg2);
    width: 60px;
    height: 25px;
    z-index: 1;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notification_tab_inner_data_div {
    // overflow: scroll;
    // min-height: auto;
    // max-height: 28rem;
    .top_title {
      color: var(--second-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      letter-spacing: -0.00938rem;
      margin-bottom: 0;
      padding-left: 1rem;
      padding-bottom: 0.5rem;
      margin-top: 10px;
    }
  }
}
.notification_main_data_div {
  display: flex;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem 1rem;
  align-items: center;
  position: relative;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    background-color: var(--search-box-data-hover);
  }
  img {
    width: 2rem;
    height: 2rem;
    @include bg_img;
    margin-right: 0.5rem;
  }
  h3 {
    color: var(--first-text-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    margin-bottom: 0;
    width: 16rem;
  }
  p {
    margin-bottom: 0;
    color: var(--second-text-color);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 166.667% */
    position: absolute;
    top: 6px;
    right: 1rem;
    display: flex;
    align-items: center;
    span {
      display: none;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 15px;
        color: #3656f4;
        margin-top: 3px;
      }
    }
  }
}
.quick_note_one_main_div_top {
  .MuiPaper-root {
    // margin-top: 81px !important;
    background-color: var(--element-bg) !important;
  }
}
.Riminder_one_main_new_div {
  background-color: var(--element-bg);
  width: 500px;
}
.quick_note_one_main_div {
  background-color: var(--element-bg);
  width: 598px;
}
.timesheet_Notification_Menu_div {
  .MuiList-root {
    background-color: var(--element-bg2);
  }
  .MuiPaper-root {
    background-color: var(--element-bg2);
    border-radius: 6px;
    top: 40px !important;
    padding: 0.5rem 0.25rem;
  }
  .MuiButtonBase-root {
    height: 32px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    border-radius: 3px !important;
    &:hover {
      background-color: var(--search-box-data-hover) !important;
    }
  }
}
.quick_note_header_div {
  padding: 16px;
  .title_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h3 {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 144.444% */
      margin: 0;
    }
  }
  .icon_btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon_btn {
      margin-right: 8px;
      cursor: pointer;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--search-box-data-hover);
      }
      svg {
        width: 16px;
        height: 16px;
        color: var(--first-text-color);
      }
    }
    button {
      color: var(--first-text-color) !important;
      text-transform: capitalize;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        color: var(--first-text-color);
      }
      &:hover {
        background-color: var(--search-box-data-hover);
      }
    }
  }
}
.quick_note_Search_div {
  padding: 16px;
  padding-top: 0;
  .ant-input-affix-wrapper {
    height: 40px;
    padding: 8px;
    border-color: var(--search-box-border-color);
    background-color: var(--element-bg);
    border-radius: 6px !important;
  }
  .ant-input-prefix {
    margin-right: 8px;
    svg {
      color: var(--second-text-color);
      width: 16px;
      height: 16px;
    }
  }
  .ant-input {
    color: var(--second-text-color) !important;
    @include FontFamily;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    &::placeholder {
      color: var(--second-text-color) !important;
    }
  }
}
.quick_note_top_filter_div {
  padding: 16px;
  padding-top: 0;
}
.quick_note_date_picker_div {
  position: relative;
  .date_icon {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: 1;
    left: 7px;
    top: 11px;
  }
  .my_date_picker {
    .react-datepicker-popper {
      transform: translate(7px, 45px) !important;
    }
    .main_btn {
      padding-left: 30px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.15px;
      span {
        svg {
          width: 16px;
          height: 16px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.quick_note_filter_btn_div {
  color: var(--first-text-color) !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px; /* 142.857% */
  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: var(--search-box-data-hover) !important;
  }
}
.quick_note_filter_menu_div {
  .MuiPaper-root {
    background-color: var(--element-bg) !important;
  }
  .Mui-selected {
    background-color: var(--search-box-data-hover) !important;
  }
  .MuiTypography-root {
    color: var(--first-text-color) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: none !important;
  }
  .MuiListItemIcon-root {
    svg {
      color: var(--first-text-color);
    }
  }
  .MuiButtonBase-root {
    &:hover {
      background-color: var(--search-box-data-hover);
    }
  }
}
.quick_note_total_div {
  padding: 16px;
  padding-top: 0;
  .total_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h2 {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      margin: 0;
      span {
        margin-right: 8px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.list_card_div {
  position: relative;
  transition: all 0.3s ease-in-out;
  padding: 16px;
  padding-left: 20px;
  margin-bottom: 4px;
  li {
    input {
      width: 24rem;
    }
  }
  &:hover {
    background-color: var(--search-box-data-hover) !important;
    transition: all 0.3s ease-in-out;
    .icon_Grabber {
      display: flex;
    }
    .icon_btn_pin_div {
      display: flex;
    }
    .list_card_inner_div {
      display: flex;
    }
  }
  .icon_btn_pin_div {
    display: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: var(--element-bg2);
    border: 1px solid var(--search-box-border-color);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 16px;
    svg {
      width: 16px;
      height: 16px;
      color: var(--first-text-color);
    }
  }
  .list_card_inner_div {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: none;
    justify-content: center;
    align-items: center;
    .box_ui {
      background-color: var(--element-bg2);
      border-radius: 6px;
      max-width: fit-content;
      padding: 4px;
      padding-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      box-shadow: 0px 4px 4px 0px rgba(66, 74, 83, 0.04),
        0px 6px 18px 0px rgba(66, 74, 83, 0.12), 0px 0px 0px 1px #fff;
      .icon_btn_div {
        cursor: pointer;
        width: 40px;
        height: 40px;
        transition: all 0.3s ease-in-out;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        &:hover {
          background-color: var(--search-box-data-hover);
        }
        img {
          width: 16px;
          height: 16px;
          @include bg_img;
        }
        .svg1 {
          width: 16px;
          height: 16px;
          color: var(--first-text-color);
        }
        .svg2 {
          font-size: 20px;
          color: var(--first-text-color);
        }
      }
    }
  }
  .user_info {
    display: flex;
    align-items: center;
    color: var(--second-text-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    padding-top: 4px;

    .ant-avatar {
      border-radius: 100%;
      border: 1px solid #3656f4;
      margin-right: 4px;
    }
  }
  .icon_Grabber {
    position: absolute;
    left: 0;
    top: 20px;
    display: none;
    svg {
      font-size: 22px;
      color: var(--first-text-color);
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    .icon_div {
      margin-right: 12px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    p {
      color: var(--first-text-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}
.quick_note_pin_total_div {
  border-bottom: 1px solid var(--search-box-border-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.top_nav_note_visible_color_div {
  background-color: var(--element-bg2);
  // height: 46px;
  width: 332px;
  padding: 4px;
  padding-top: 5px;
  border-radius: 6px;
  position: absolute;
  // border: 1px solid var(--search-box-border-color);
  box-shadow: var(--element-bg-box-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  padding-right: 0;
  top: -5px;
  left: 6rem;
  z-index: 1;
  .top_nav_note_visible_color_div_btn {
    margin-bottom: 4px;
    position: relative;
    flex-direction: row;
    align-self: flex-start;
    width: 32px;
    height: 32px;
    margin-right: 4px;
    background-color: var(--search-box-data-hover);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    .ActiveIcon {
      position: absolute;
      width: 16px;
      height: 16px;
      @include bg_img;
      top: -2px;
      right: -2px;
    }
    .color_div {
      background-color: black;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
    .close_btn {
      color: var(--first-text-color);
      width: 16px;
      height: 16px;
    }
    .img_1 {
      @include bg_img;
      width: 14px;
      height: 14px;
    }
  }
}
.ant-skeleton-content {
  .ant-skeleton-title {
    background: var(--search-box-data-hover) !important;
  }
  .ant-skeleton-paragraph {
    background: var(--search-box-data-hover) !important;
  }
}
.ant-skeleton-avatar {
  background: var(--search-box-data-hover) !important;
}
.ant-skeleton-avatar-circle {
  background: var(--search-box-data-hover) !important;
}
.delete_color {
  color: #d1242f !important;
  svg {
    color: #d1242f !important;
  }
}
.Add_note_menu_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--search-box-data-hover);
  }
  svg {
    color: var(--first-text-color);
    width: 18px;
    height: 18px;
    transform: rotate(90deg);
  }
}
.quickNotesEditInput_div {
  position: relative;
  .ant-input {
    padding-right: 60px;
  }
}
.quickNotesEditInputBtn_div {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 3px;
  .btn_1 {
    background-color: #d1242f;
    color: #fff;
    margin-right: 3px;
  }
  .btn_2 {
    background-color: #1a7f37;
    color: #fff;
  }
  button {
    width: 25px;
    height: 25px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border-radius: 6px;
    svg {
      width: 13px;
      height: 13px;
    }
  }
}
.my_date_picker {
  .react-datepicker__close-icon {
    &::after {
      background-color: #9747ff;
      height: 18px;
      width: 18px;
      font-size: 16px;
    }
  }
}
.NewAudioUI_div {
  display: flex;
  align-items: center;
  button {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: var(--search-box-data-hover);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    svg {
      font-size: 16px;
      color: var(--first-text-color);
    }
  }
  .time {
    color: var(--first-text-color);
    margin-left: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3px;
  }
  .delete_btn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: var(--search-box-data-hover);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border-radius: 6px;
    margin-bottom: 3px;
    margin-left: 8px;
    svg {
      color: #d1242f;
      font-size: 16px;
    }
  }
  .MuiSlider-track {
    background-color: #3656f4 !important;
  }
  .MuiSlider-thumb {
    background-color: #3656f4 !important;
  }
  .MuiSlider-rail {
    background-color: var(--second-text-color) !important;
  }
  // .MuiSlider-roo {
  //   // width: 70%;
  // }
}
.MobileMenuMain_div {
  .rs-dropdown-menu {
    background-color: var(--element-bg2);
  }
  .rs-dropdown-toggle {
    background-color: var(--search-box-data-hover);
    color: var(--first-text-color);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
  }
  .MobileMenuMainInner_div {
    .rs-dropdown-toggle {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon_div {
        margin-right: 4px;
        svg {
          width: 16px;
          height: 16px;
          color: var(--first-text-color);
        }
      }
    }
  }
  .MobileMenuMainItem_div {
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: var(--first-text-color);
      display: flex;
      align-items: center;
    }
    .icon_div {
      margin-right: 8px;
      svg {
        width: 16px;
        height: 16px;
        color: var(--first-text-color);
      }
    }
  }
}
@media (max-width: 320px) {
  .avatar_container_profile {
    padding: 0 4px;
  }
  .notification_one_main_div {
    width: 316px !important;
  }
  .quick_note_one_main_div {
    width: 316px !important;
  }
  .Riminder_one_main_new_div {
    width: 316px !important;
  }
}
@media (max-width: 576px) {
  .top_nav_search_box_filter_data_div {
    display: none;
  }
  .quick_note_header_div {
    .title_div {
      h3 {
        font-size: 15px;
      }
    }
  }
  .quick_note_one_main_div {
    width: 360px;
  }
  .Riminder_one_main_new_div {
    width: 360px;
  }
  .avatar_container_profile {
    .menu {
      right: -5px;
    }
  }
  .notification_one_main_div {
    width: 373px;
    .notification_tab_inner_data_div {
      width: 28rem;
    }
  }
  .top_nav_left_div {
    .top_nav_menu_icon {
      margin-right: 10px;
    }
  }
  .avatar_container {
    .menu {
      top: 27px;
      left: -140px;
    }
  }
}
