@import '../../../../scss/--var.scss';
@import '../../../../scss/--mixing.scss';
:root {
  /* right-navbar */
  --right-navbar-background-color: #fff;
  --right-navbar-link-color: #1f2328;
  --right-navbar-link-active-background-color: #d1ccfe80;
  --right-navbar-link-active-color: #3656f4;
  --right-navbar-link-hover-color: #eef1f4;
  --right-navbar-link-hover-text-color: #3656f4;
  --right-navbar-link-hr-color: #d0d7de;
  --nav-bar-drop-text-color: #00544e;
  --nav-bar-drop-text-bg: #7ff8f0;
  --new-nav-border-right-color: #d0d7de;
  --new-nav-drop-text-color: #1f2328;
  --nav-logo-bg: #0a0e30;
  /* right-navbar end */
}

[data-theme='dark'] {
  /* right-navbar */
  --right-navbar-background-color: #161b22;
  --right-navbar-link-color: #848d97;
  --right-navbar-link-active-background-color: #2a3284;
  --right-navbar-link-active-color: white;
  --right-navbar-link-hover-color: #292e36;
  --right-navbar-link-hover-text-color: #879dff;
  --right-navbar-link-hr-color: #30363d;
  --nav-bar-drop-text-color: #00544e;
  --nav-bar-drop-text-bg: #00afa5;
  --new-nav-border-right-color: #30363d;
  --new-nav-drop-text-color: #e6edf3;
  --nav-logo-bg: #24292f;
  /* right-navbar end */
}

.logo_div {
  background: var(--top-nav-bg);
  height: 56px;
  border-bottom: 1px solid var(--search-box-border-color);
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  align-items: center;
  img {
    @include bg_img;
    width: 8rem;
    height: 3rem;
    margin: auto;
  }
  .text_div {
    width: 2rem;
    height: 2rem;
    background-color: #3656f4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    span {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}
.navbar_container {
  // background-color: white;
  background-color: var(--right-navbar-background-color);
  --nav_width: 256px;
  height: 100%;
  transition: transform 300ms ease-in-out;
  // transition: background-color 0.25s ease-in-out;

  @media screen and (max-width: 1024px) {
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
  }

  @media screen and (max-width: 18rem) {
    width: 100vw;
  }
}

.navbar_mobile_active {
  @media screen and (max-width: 1024px) {
    transform: translateX(0);
  }
}

nav {
  position: relative;
  width: var(--nav_width);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: width linear 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }
}

.logo {
  padding: 0 0.35rem;
  font-size: 2.2rem;
  display: flex;
  .logo_icon {
    color: var(--color_nav_active);
  }

  .mobile_cancel_icon {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .logo_icon {
      display: none;
    }

    .mobile_cancel_icon {
      display: block;
      cursor: pointer;
      font-size: 2.2rem;
      padding: 0.2rem;
      color: var(--color_nav_active);
    }
  }
}

.menu_container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  height: 30rem;
  border-right: 1px solid var(--new-nav-border-right-color);
}

.categories {
  color: var(--color_nav_txt);
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: var(--margin-xxs);

  svg {
    font-size: 1rem;
  }
}

.second_category {
  margin-top: 3rem;
}

.li_navlink {
  margin-bottom: 2px;
  margin-top: 2px;
  position: relative;
  a {
    height: 36px;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 8px 8px;
    text-decoration: none;
    color: var(--right-navbar-link-color);
    transition: all ease-in-out 0.2s;
    background-color: var(--right-navbar-background-color);
    // border-left: 3px solid white;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    .btn_badge {
      position: absolute;
      top: 7px;
      right: 16px;
      margin-bottom: 0;
      color: white;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      padding: 2px 5px;
      border-radius: 6px;
    }
    small {
      padding: 4px;
      svg {
        color: var(--right-navbar-link-color);
        font-size: 16px;
      }
    }
    span {
      margin-left: 8px;
    }
    .description {
      font-weight: 500;
      font-size: 14px;
      transition: opacity 200ms ease;
      text-transform: capitalize;
    }

    svg {
      font-size: 18px;
      color: var(--color_nav_not_active);
      flex-shrink: 0;
    }

    &:hover {
      background: var(--right-navbar-link-hover-color);
      // border-left: 3px solid $c2;
      color: var(--right-navbar-link-color);
      border-radius: 6px;
    }

    &:hover svg {
      color: var(--color_nav_active);
    }
  }
}
.test {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 5px 8px 15px;
  text-decoration: none;
  color: $c2;
  transition: all ease-in-out 0.2s;
  background-color: white;
  cursor: pointer;
  // border-left: 3px solid white;

  .description {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 15px;
    transition: opacity 200ms ease;
    text-transform: capitalize;
  }

  svg {
    font-size: 18px;
    color: var(--color_nav_not_active);
    flex-shrink: 0;
  }

  &:hover {
    background: $c1;
    border-left: 3px solid $c1;
    color: $c2;
  }

  &:hover svg {
    color: var(--color_nav_active);
  }
}

.li_navlink .active {
  background: var(--right-navbar-link-active-background-color);
  // border-left: 3px solid $c2;
  color: var(--right-navbar-link-active-color);
  &:hover {
    background: var(--right-navbar-link-active-background-color);
    color: var(--right-navbar-link-active-color);
  }
  small {
    svg {
      color: var(--right-navbar-link-active-color);
    }
  }
}

.btn_logout {
  position: absolute;
  right: 12%;
  bottom: 2%;

  svg {
    font-size: 1.5rem;
    color: var(--color_nav_active);
    cursor: pointer;
    transform: scaleX(-1);
  }
}

.mobile_nav_background_active {
  width: 0;
  height: 100vh;
  transition: all ease-out 500ms;
  transition-delay: 300ms;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    left: calc(var(--nav_width) + var(--padding-md));
    width: calc(100vw - var(--nav_width) - var(--padding-md));
    background: rgba(0, 0, 0, 0.185);
    z-index: -1;
  }
}
.EmployeesAttendanceList_main_div {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

//NAVIGATION SMALL
.nav_small {
  width: 56px;
  .li_navlink a {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn_badge {
      right: 5px;
      top: 1px;
      font-size: 8px;
    }
    .description {
      display: none;
      svg {
        display: none !important;
      }
    }
  }
  .logo_div {
    justify-content: center;
    padding-left: 0;
    img {
      display: none;
    }
  }

  .li_navlink a {
    span {
      margin-left: 0;
      label {
        display: none;
      }
    }
  }
  .categories {
    padding: 0 1rem;
  }
  .description {
    opacity: 0;
  }

  .btn_logout {
    svg {
      transform: scaleX(1);
    }
  }
}
//
.nav_bar_drop_top_div {
  cursor: pointer;
  // display: inline-block;
  a {
    padding: 6px 8px;
  }
  span {
    margin-left: 8px !important;
    color: var(--right-navbar-link-color);
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    svg {
      margin-right: 0 !important;
      margin-left: 8px;
    }
  }
}
.nav_bar_drop_inner_menu_div {
  ul {
    background-color: var(--right-navbar-background-color);
    li {
      &:hover {
        background-color: var(--right-navbar-link-hover-color);
      }
    }
  }
}
.menu_title_nav {
  margin-bottom: 8px;
  color: var(--new-nav-drop-text-color);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 16px;
}
.nav_bar_drop_inner_item_div {
  display: flex;
  align-items: center;
  .hr_workspace {
    color: #865900;
    background: #f8cf7f;
  }
  .Sales {
    color: #617100;
    background: #e7f87f;
  }
  .Maxlence {
    color: #00544e;
    background: #7ff8f0;
  }

  span {
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 4px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 0;
    color: var(--new-nav-drop-text-color);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  small {
    color: var(--new-nav-drop-text-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.nav_bar_drop_text {
  color: var(--nav-bar-drop-text-color);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
  background-color: var(--nav-bar-drop-text-bg);
  padding: 4px 6px;
  border-radius: 4px;
}
.my_new_hr {
  margin: 0;
  margin-bottom: 8px;
  margin-top: 6px;
  opacity: 1;
  color: var(--right-navbar-link-hr-color);
}
// media queries
@media (max-width: 576px) {
  .menu_container {
    height: 33rem;
  }
}
@media (min-width: 768px) {
  .menu_container {
    height: 35rem;
  }
}
@media (min-width: 1024px) {
  .menu_container {
    height: 40rem;
  }
}
@media (min-width: 1440px) {
  .nav_small {
    .EmployeesAttendanceList_main_div {
      display: inline-block !important;
    }
  }
}
@media (min-width: 1700px) {
}
