@import '../../../../../scss/--var.scss';
@import '../../../../../scss/--mixing.scss';

.my_timesheet_main_div {
  .my_timesheet_nav_div {
    margin-top: 0.5rem;
    border-bottom: 1px solid var(--search-box-border-color);
    .active {
      color: var(--first-text-color);
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: transparent;
      }
    }
    button {
      color: var(--second-text-color);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      padding: 0.5rem;
      background-color: transparent;
      text-transform: capitalize;
      outline: none;
      position: relative;
      transition: all 0.3s ease-in-out;
      border-radius: 6px;
      margin-bottom: 0.5rem;
      &:hover {
        background-color: var(--search-box-data-hover);
      }
      div {
        height: 2px;
        background: #3656f4;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -8px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .my_timesheet_inner_div {
    padding: 1.5rem;
  }
}
.timesheet_list_top_div {
  background-color: var(--element-bg2);
  border-radius: 0 0 6px 6px;
  border: 1px solid var(--search-box-border-color);
  .top_border {
    border-top: 1px solid var(--search-box-border-color);
  }
  .page_link {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #3656f4;
    }
  }
  .timesheet_list_top_title_div {
    padding: 1.4375rem 1rem;
    display: flex;
    align-items: center;

    span {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
    }
    p {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
      margin-bottom: 0;
      margin-left: 1.38rem;
    }
  }
  .timesheet_list_top_Total_title_div {
    padding: 0.625rem 0.5rem;
    height: 66px;
    span {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
    }
    p {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
      margin-bottom: 0;
    }
  }
  .timesheet_list_table_div {
    table {
      overflow-x: auto;
      white-space: nowrap;
      display: block;
    }
    .table_th_inner_main_div {
      width: 8.75rem;
      padding: 0;
      vertical-align: middle;
    }
    .table_tr_inner_div {
      border-top: 1px solid var(--search-box-border-color);
      height: 3.3125rem;
      .text_box {
        padding: 0.5rem;
        border-radius: 0.125rem;
        background: var(--tabel-box-bg);
        color: var(--first-text-color);
        text-align: center;
        text-transform: capitalize;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: auto;
        max-width: 6rem;
        position: relative;
        // margin-top: 3px;
      }
    }
    .table {
      margin-bottom: 0;
      border-left: 1px solid var(--search-box-border-color);
      border-right: 1px solid var(--search-box-border-color);
      thead {
        height: 66.3px;
      }
    }
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-color: var(--search-box-border-color);
      border-style: none;
      border-width: 0;
    }
    .active_class {
      background: var(--timesheet-top-bg);
    }
    .table_th_inner_div {
      padding: 0.5rem;
      position: relative;
      height: 63px;
      margin-top: 3px;
      .line {
        background: #3656f4;
        width: 100%;
        height: 0.25rem;
        position: absolute;
        left: 0;
        top: -3px;
      }

      p {
        margin-bottom: 0;
        color: var(--first-text-color);
        text-transform: capitalize;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
      }
      span {
        color: var(--first-text-color);
        text-transform: capitalize;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
      }
    }
  }
  .timesheet_list_project_name_div {
    display: flex;
    align-items: center;
    height: 3.3125rem;
    position: relative;
    .time_icon {
      position: absolute;
      right: 9px;
      top: 12px;
      cursor: pointer;
      svg {
        width: 0.84375rem;
        height: 1rem;
        color: var(--first-text-color);
      }
    }
    .sr_no {
      color: var(--second-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      padding-left: 1rem;
    }
    img {
      width: 2rem;
      height: 2rem;
      @include bg_img;
      border-radius: 100%;
      margin-left: 1.81rem;
    }
    h3 {
      color: var(--first-text-color);
      text-transform: capitalize;
      margin-bottom: 0;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      margin-left: 0.5rem;
    }
  }
  .end_text_box_div {
    height: 3.3125rem;
    display: flex;
    align-items: center;
    .end_text_box {
      margin-left: 0.5rem;
      padding: 0.5rem;
      border-radius: 0.125rem;
      background: var(--tabel-box-bg);
      color: var(--first-text-color);
      text-align: center;
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: auto;
      max-width: 6rem;
    }
  }
}
.timesheet_list_top_inner_main_div {
  .task_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.81rem;
    svg {
      width: 1rem;
      height: 0.8125rem;
      color: var(--first-text-color);
      margin-top: 1px;
    }
  }
  .timesheet_list_top_title_div {
    padding-bottom: 19px;
    img {
      width: 1.5rem;
      height: 1.5rem;
      @include bg_img;
      border-radius: 100%;
      margin-left: 20px;
    }
    p {
      margin-left: 0.5rem;
    }
  }
}
.all_timesheet_list_top_main_div {
  .timesheet_list_top_Total_title_div {
    span {
      font-weight: 700;
    }
  }

  .timesheet_list_top_div {
    .timesheet_list_top_title_div {
      padding: 0.625rem 0.5rem;
    }
    .timesheet_list_top_Total_title_div {
      height: 40px;
    }
    .timesheet_list_table_div {
      .table {
        thead {
          height: 40.3px;
        }
      }
      .table_th_inner_div {
        height: unset;
        margin-top: 0;
        .line {
          top: 0;
        }
      }
    }
  }
}
.search_box_page_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .search_box_div {
    margin-right: 0.5rem;
  }
}
.page_pagination_list_div {
  border-top: 1px solid var(--search-box-border-color);
}
.page_pagination_div {
  ul {
    display: flex;
    align-items: center;
  }
  .page_pagination_text_div {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.88rem 0.5rem;
    p {
      margin-bottom: 0;
      color: #636c76;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 166.667% */
    }
  }
  .page_pagination_main_number_div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.88rem 0.5rem;
  }
  .number_btn {
    border-radius: 6px;
    height: 32px;
    width: 32px;
    color: var(--first-text-color);
    background-color: transparent;
    outline: none;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    margin-left: 0.25rem;
  }
  .selected {
    border-radius: 6px;
    height: 32px;
    width: 32px;
    color: #fff;
    outline: none;
    background-color: #3656f4;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    margin-left: 0.25rem;
  }
  button[disabled] {
    // opacity: 0.5;
    color: #bdbdbd;
  }
  .previous_btn {
    background-color: transparent;
    outline: none;
    color: #3656f4;
    text-align: center;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    display: flex;
    align-items: center;
    margin-right: 1rem;
    span {
      display: flex;
      align-items: center;
      margin-top: 2px;
    }
  }
  .next_btn {
    background-color: transparent;
    outline: none;
    color: #3656f4;
    text-align: center;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    display: flex;
    align-items: center;
    margin-left: 1rem;
    span {
      display: flex;
      align-items: center;
      margin-top: 2px;
    }
  }
}
.page_pagination_main_number_div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.88rem 0.5rem;
  .Mui-selected {
    background-color: #3656f4 !important;
    color: #fff !important;
  }
  .MuiButtonBase-root {
    color: var(--first-text-color);
  }
}
@media (max-width: 576px) {
  .timesheet_list_top_main_div {
    width: 100%;
    overflow: scroll;
    .timesheet_list_top_div {
      width: 65rem;
    }
  }
  .search_box_page_div {
    flex-direction: column;
    align-items: flex-end;
    .search_box_div {
      margin-bottom: 1rem;
      margin-right: 0;
      width: 100%;
      input {
        width: 100%;
      }
      .search_result_div {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) {
  .timesheet_list_top_main_div {
    width: 100%;
    overflow: scroll;
    .timesheet_list_top_div {
      width: 65rem;
    }
  }
}
@media (min-width: 1024px) {
  .timesheet_list_top_main_div {
    width: 100%;
    overflow: unset;
    .timesheet_list_top_div {
      width: unset;
    }
  }
}
@media (min-width: 1440px) {
}
