@import '../../../../scss/--var.scss';
@import '../../../../scss/--mixing.scss';
// select_box
.new_select_box {
  .label {
    color: var(--first-text-color);
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.00938rem;
    margin-bottom: 5px;
  }
  .small {
    margin-top: 5px;
    color: #d1242f;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
      svg {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: 1px;
      }
    }
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-1xc3v61-indicatorContainer {
    svg {
      font-size: 20px;
      color: var(--second-text-color);
    }
  }
  .css-13cymwt-control {
    border: 1px solid var(--search-box-border-color);
    background-color: var(--element-bg);
  }
  .css-t3ipsp-control {
    background-color: var(--element-bg);
  }
  .css-1dimb5e-singleValue {
    color: var(--first-text-color);
  }
  input {
    color: var(--first-text-color) !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important; /* 142.857% */
    letter-spacing: -0.00938rem !important;
    &::placeholder {
      color: var(--first-text-color) !important;
      text-transform: capitalize !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--element-bg);
    box-shadow: var(--element-bg-box-shadow);
  }
  .css-10wo9uf-option {
    div {
      color: var(--first-text-color);
    }
  }
}
