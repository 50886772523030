@import '../../../../scss/--var.scss';
@import '../../../../scss/--mixing.scss';
.my_date_picker {
  .main_btn {
    padding: 0.5rem;
    border-radius: 6px;
    outline: none;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    color: var(--first-text-color);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.00938rem;
    span {
      margin-left: 0.5rem;
      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
      }
    }
    &:hover {
      background-color: var(--search-box-data-hover);
    }
  }
  .react-datepicker-popper {
    transform: translate(40px, 216px) !important;
  }
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: var(--search-box-data-hover);
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    color: #fff !important;
  }
  .bottom_btn_div {
    button {
      background-color: transparent;
      text-transform: capitalize;
      margin-right: 5px;
      color: #3656f4;
    }
  }
  .react-datepicker {
    border: 1px solid var(--search-box-border-color);
    background-color: var(--element-bg);
    // margin-left: 7rem;
  }
  .react-datepicker__header {
    border-bottom: 1px solid var(--search-box-border-color);
    background-color: var(--element-bg);
  }
  .react-datepicker__month-container {
    background-color: var(--element-bg);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    color: var(--first-text-color);
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: var(--first-text-color);
  }
  .react-datepicker__navigation--previous {
    left: 2px;
    top: 10px;
    color: var(--first-text-color);
  }
  .react-datepicker__navigation--next {
    right: 2px;
    top: 10px;
    color: var(--first-text-color);
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: black !important;
  }
}
