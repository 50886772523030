@import '../--mixing.scss';
@import '../--var.scss';
a {
  text-decoration: none !important;
}
// Dashboard
.welcome_note_div {
  h3 {
    font-weight: 600;
    font-size: 28px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 0;
    color: #525252;
    margin-bottom: 1rem;
  }
}
.welcome_note_hr_div {
  hr {
    opacity: 1 !important;
    margin: 0;
    margin-bottom: 1rem;
  }
}
.management_btn_full {
  text-transform: capitalize;
  background-color: $blue;
  color: white;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 6px;
  transition: all 0.3s;
  border: none;
  outline: none;
  svg {
    margin-bottom: 2px;
    margin-right: 5px;
    font-size: 18px;
  }
  &:hover {
    color: white;
    background-color: $blue;
    box-shadow: 0px 0px 12px rgba(81, 67, 239, 0.5);
  }
  a:focus,
  a:hover {
    color: white !important;
  }
}

.management_btn_full2 {
  a:focus,
  a:hover {
    color: white !important;
  }
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
  background-color: $blue;
  color: white;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 6px;
  transition: all 0.3s;
  width: 100%;
  border: none;
  outline: none;
  &:hover {
    color: white;
    background-color: $blue;
    box-shadow: 0px 0px 12px rgba(81, 67, 239, 0.5);
  }
  svg {
    font-size: 18px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
}
.management_btn_outline {
  text-transform: capitalize;
  background-color: transparent;
  color: $blue;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 20px;
  width: 100%;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  &:hover {
    color: white;
    background-color: $blue;
    box-shadow: 0px 0px 12px rgba(81, 67, 239, 0.5);
  }
}
.management_btn_outline_with_icon {
  text-transform: capitalize;
  background-color: transparent;
  color: $blue;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
  width: 100%;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  &:hover {
    color: white;
    background-color: $blue;
    box-shadow: 0px 0px 12px rgba(81, 67, 239, 0.5);
  }
  svg {
    font-size: 20px;
    margin-left: 5px;
    margin-top: 1px;
  }
}

.project_summary_title {
  h3 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
  }
}
.project_summary_card_top_div {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}
.table-hover > tbody > tr:hover > * {
  .progress {
    background-color: white;
  }
}
.link_a {
  color: #626262;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    color: #1a7fdd;
  }
}
.project_summary_card {
  padding: 1rem;
  padding-top: 20px;
  border-radius: 8px;
  transition: all 0.3s;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    span {
      font-weight: 500;
      font-size: 24px;
      margin-right: 10px;
    }
  }
}
.project_summary_card_icon {
  @include bg_img;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  margin-left: 7px;
}
.project_summary_card_icon_dot {
  position: absolute;
  top: 6px;
  // left: 65px;
  svg {
    font-size: 10px;
  }
}

.intput_box {
  border: 1px solid hsl(0, 0%, 80%) !important;
  background-color: white !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 8px 10px 8px 15px !important;
  border-radius: 6px !important;
}
.textareaBox_box {
  border: 1px solid #949494 !important;
  background-color: white !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 8px 10px 8px 15px !important;
  border-radius: 6px !important;
}
.all_page_my_label_new {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
  color: #252525;
  text-transform: capitalize;
  margin-left: 13px;
}
.Filters_Search_div {
  position: relative;
  svg {
    position: absolute;
    font-size: 20px;
    top: 10px;
    left: 12px;
  }
  input {
    padding-left: 40px;
    border: none;
  }
}
.ClientProfile_normal_dot {
  color: #c9bdbd !important;
  background: black;
  border-radius: 100%;
}
.Client_Profile_main_top_div {
  position: relative;
  display: block;
}
.Appraisal_progress_bar_man {
  height: 5px;
}
//
.Client_Profile_main_top_div_inner {
  progress {
    border: none;
    background: #eb703b;
  }

  progress {
    color: #eb703b;
  }

  progress::-webkit-progress-value {
    background: #eb703b;
  }

  progress::-moz-progress-bar {
    background: #eb703b;
  }
}

//
.Appraisal_progress_text_bar_mann {
  svg {
    color: #eb703b !important;
  }
}
.Client_Profile_svg {
  svg {
    font-size: 25px !important;
    position: absolute;
    top: 2px;
    margin-left: -7px;
    background: white;
    border-radius: 100%;
  }
  label {
    margin-left: -36px;
    display: block;
    margin-top: 13px;
  }
}
.Client_Profile_svg_last {
  svg {
    margin-left: 0px !important;
  }
}
.Client_Profile_svg_last_text {
  margin-top: 13px;
  margin-bottom: 0;
  display: block;
  margin-left: -31px;
  position: absolute;
  width: 100%;
}
.Client_Profile_title {
  text-align: left;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  h3 {
    font-weight: 500;
    font-size: 24px;
    text-transform: capitalize;
    letter-spacing: 1px;
  }
}
.Client_Profile_btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .management_btn_full {
    margin-left: 1.5rem;
  }
}
.SelectBox_box {
  border: 1px solid hsl(0, 0%, 80%) !important;
  background-color: white !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 8px 10px 8px 15px !important;
  border-radius: 6px !important;
}
.drop_box {
  .css-1rhbuit-multiValue {
    background: #ffbb96;
    padding: 3px;
    font-weight: 500;
    font-size: 14px;
    color: #252525;
  }
  .css-1pahdxg-control {
    box-shadow: none !important;
    border-radius: 6px !important;
    padding-left: 10px !important;
  }
  .css-1s2u09g-control {
    border: 1px solid #949494 !important;
    background-color: white !important;
    border-radius: 6px !important;
    padding-left: 10px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .css-9gakcf-option {
    background-color: #e0e0e0;
    color: #6c757d !important;
    border-bottom: 1px solid #8d8d8d;
  }
  .css-yt9ioa-option {
    border-bottom: 1px solid #8d8d8d;
  }
  .css-6j8wv5-Input {
    color: #6c757d !important;
  }
}
.select_client {
  .css-1pahdxg-control {
    box-shadow: none !important;
    padding-left: 10px !important;
    border-radius: 10px !important;
    max-width: 19rem !important;
    min-width: 19rem !important;
  }
  .css-1s2u09g-control {
    width: 20rem !important;
    border: 1px solid #949494 !important;
    background-color: white !important;
    padding-left: 10px !important;
    border-radius: 10px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    max-width: 19rem !important;
    min-width: 19rem !important;
  }
  .css-9gakcf-option {
    background-color: #e0e0e0;
    color: #6c757d !important;
    border-bottom: 1px solid #8d8d8d;
  }
  .css-yt9ioa-option {
    border-bottom: 1px solid #8d8d8d;
  }
  .css-6j8wv5-Input {
    color: #6c757d !important;
  }
}
.Select_Services_row {
  justify-content: center;
  align-items: flex-end;
  .management_btn_outline {
    padding: 7px 20px;
  }
}
.checkbox_label {
  font-size: 14px;
  font-weight: 400;
}
.Select_Services_row_new_inner {
  display: none;
}
.Benchmark_title_div {
  h3 {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 1.5rem;
  }
}
.CreateBenchmark_brn {
  text-align: center;
  button {
    width: 15%;
  }
}
.SavedBenchmark_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  .management_btn_full2 {
    margin-left: 10px;
  }
  .management_btn_outline {
    margin-right: 10px;
  }
  button {
    width: 15%;
  }
}
.top_title_pipline_projects_div {
  .profil_emp_inter_page_title {
    margin: 0;
  }
}
.project_List_view_all_link {
  display: flex;
  justify-content: flex-end;
  a {
    font-weight: 500;
    font-size: 14px;
    color: #1a7fdd;
    text-decoration: underline !important;
  }
}
.project_list_title {
  th {
    font-weight: 600;
    font-size: 16px;
    color: #484848;
  }
}

.project_list_name_top_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 2px;
  transition: all 0.3s;
  &:hover {
    background-color: #ececec;
  }
  .project_list_name {
    font-size: 12px;
    font-weight: 400;
    p {
      font-size: 10px;
    }
  }
}
.project_list_name_boder {
  border-left: 4px solid;
  padding-left: 8px;
}
.project_list_name {
  font-weight: 500;
  font-size: 14px;
  color: #626262;
  h3 {
    margin-bottom: 0;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    span {
      color: #a5a5a5;
      font-size: 15px;
    }
  }
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    color: #a5a5a5;
    span {
      color: black;
    }
  }
}
.project_list_priority {
  font-weight: 500;
  font-size: 12px;
  color: white;
  text-transform: capitalize;
}
.project_list_progress {
  width: 13rem;
  margin-top: -17px;
  span {
    font-weight: 600;
    font-size: 12px;
  }
  .progress {
    height: 8px;
    .progress-bar {
      background-color: #39c222;
    }
  }
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}
.project_list_progress_td {
  display: flex;
  position: relative;
}
.project_list_progress_td_link {
  position: absolute;
  top: 0;
  right: 0;
  a {
    font-weight: 500;
    font-size: 14px;
    color: #1a7fdd;
  }
}
.project_list_priority_div {
  width: 4rem;
  height: 25px;
  border-radius: 6px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project_list_name_tr {
  td,
  tfoot,
  th,
  thead,
  tr {
    padding-bottom: 7.5px !important;
    padding-top: 16px !important;
  }
}
.today_meetings_btn_div {
  margin-top: -6px;
  .management_btn_outline {
    padding: 3px 4px;
  }
}
.user_logo_upload_main_div {
  position: relative;
  display: inline-block;
  label {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    bottom: -8px;
    right: -3px;
    border: 1px solid #ff8059;
    outline: none;
    background-color: white;
    width: 2rem;
    height: 2rem;
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    svg {
      color: #ff8059;
    }
  }

  .project_details_card_img {
    @include bg_img;
    width: 10rem;
    height: 10rem;
    margin: auto;
    border-radius: 6px;
  }
}
.user_logo_upload_img {
  width: 110px !important;
  height: 110px !important;
}
.breff_label_add_project {
  font-weight: 500;
  font-size: 20px;
  color: black;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.user_logo_inner_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.client_profile_uplod_btn_ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  li {
    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      margin-right: 10px;
      span {
        svg {
          transform: rotate(317deg);
          margin-right: 5px;
        }
      }
    }
  }
}
.upload_document_btn {
  // background: #f5f7ff;
  span {
    svg {
      font-size: 17px;
      margin-left: 12px;
    }
  }
}
.project_leads {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
  margin-left: 13px;
}
.add_project_modal_btn_one {
  margin-right: 12px;
  margin-top: 4px;
  .management_btn_outline {
    height: 37px;
  }
}
.add_project_modal_title {
  text-transform: capitalize;
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 24px;
  }
}
.add_project_modal_tadio_btn_div {
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.ExistingClientShow {
  display: block;
}
.ExistingClientHide {
  display: none;
}
.project_list_tabs_inner_div {
  position: relative;
}
.project_list_tabs_inner_btn {
  position: absolute;
  top: 0;
  right: 0;

  .management_btn_outline_with_icon {
    border: none;
    background-color: white;
    color: black;
  }
}
.Dashboard_menu_right {
  position: absolute;
  top: 0;
  right: 10px;
  .chat_div_dropdown .rs-dropdown-toggle,
  .chat_div_dropdown .rs-dropdown-toggle.rs-btn {
    svg {
      font-size: 30px;
    }
  }
}
.Dashboard_menu_right_innwr {
  .rs-dropdown .rs-dropdown-menu {
    width: 15rem;
  }
  .css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #219b0d;
  }
  label {
    padding: 8px 12px;
    span {
      font-size: 13px;
      font-weight: 600 !important;
      padding-left: 10px;
    }
  }
}
.Dashboard_menu_right_innwr_item {
  position: relative;
  padding: 0 !important;
  .MuiSwitch-root {
    position: absolute;
    top: 5px;
    right: 8px;
  }
}
.project_list_tabs_main_div {
  position: relative;
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: black;
    background-color: #cdd4ff;
  }
  .css-1pahdxg-control {
    min-height: 42px;
  }

  .nav-pills .nav-link {
    background: white;
    margin-right: 9px;
    color: black;
    cursor: pointer;
    text-transform: capitalize;
  }
}
.page_All_top_title {
  text-transform: capitalize;
  margin-bottom: 1.5rem;
  h3 {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: black;
    span {
      svg {
        cursor: pointer;
        font-size: 20px;
        color: black;
        margin-right: 11px;
        margin-bottom: 2px;
      }
    }
  }
}
.progress_all_tabel {
  span {
    font-weight: 600;
    font-size: 12px;
  }
  .progress {
    height: 8px;
    .progress-bar {
      background-color: #39c222;
    }
  }
}
.progress_all_tabel_td {
  width: 15rem;
}
.progress_all_tabel_tr {
  background-color: #0a0e30;
  color: white;
  th {
    font-weight: 600;
    font-size: 12px;
  }
}
.progress_all_tabel_tr_mor {
  td {
    font-weight: 600;
    font-size: 12px;
  }
}
.progress_all_option_icon {
  svg {
    font-size: 20px;
  }
}
.chat_option_name_div {
  font-size: 12px;
  font-weight: 500;
}
.projec_tabelt_list_pagination_div {
  ul {
    justify-content: flex-end;
  }
}
.milestones_emp {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    color: #1a7fdd;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    transition: all 0.3s;
    &:hover {
      color: #2a7ecc;
    }
    span {
      margin-right: 5px;
      svg {
        font-size: 19px;
        margin-bottom: 3px;
      }
    }
  }
}
.create_task_title {
  text-align: center;
  h3 {
    font-weight: 500;
    font-size: 24px;
    color: black;
    text-transform: capitalize;
  }
}
.Participants_main_div {
  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    height: 24px;
    width: 24px;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }

  .css-7g00h4-MuiAvatar-root {
    position: unset;
  }
}

.Participants_main_div_inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .MuiAvatar-circular {
    width: 25px;
    height: 25px;
    position: unset;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
}
.Participants_main_div_tr {
  margin-top: 5px;
}
.Participants_main_div_tr_btn {
  .management_btn_outline {
    padding: 4px 4px;
  }
}
.notes_card_top_div {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  h3 {
    color: black;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0;
    span {
      font-weight: 400;
      font-size: 14px;
      color: #666;
    }
  }
  p {
    color: #666;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
  }
  h5 {
    color: black;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      color: black;
      margin: auto;
      font-size: 15px;
      span {
        margin-right: 5px;
        svg {
          font-size: 20px;
          font-size: 16px;
          margin-bottom: 4px;
          color: #979797;
        }
      }
    }
  }
}
.notes_card_img {
  @include bg_img;
  width: 4rem;
  height: 4rem;
  margin: auto;
}
.notes_card_img_div {
  padding: 3rem 1rem;
}
.notes_card_top_opsiton {
  position: absolute;
  top: 4px;
  right: 7px;
  svg {
    font-size: 30px;
    color: #3786ee;
  }
}
.nav_img {
  width: 18px;
}

.project_details_card_name {
  margin-bottom: 3.4rem;
  h3 {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: #3786ee;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
  }
}
.project_details_card_box_inner {
  p {
    color: #ff3e48;
  }
}
.project_details_card_box {
  border: 1px solid #808080;
  display: inline-block;
  display: inline-block;
  padding: 7px 13px;
  border-radius: 3px;
  width: 7rem;
  text-align: center;
  margin-right: 10px;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
  }
  span {
    color: #949494;
    font-weight: 500;
    font-size: 12px;
  }
}
.project_details_card_box_main_div {
  display: flex;
  align-items: center;
}
.circular_static_div {
  .css-1l6rwmg-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
    background-color: #eeeeee;
  }
  span {
    width: 7rem !important;
    height: 7rem !important;
    transform: rotate(268deg) !important;
    position: relative;
    z-index: 1;
    color: #3786ee !important;
    background: aliceblue;
    border-radius: 100%;
  }
  .MuiTypography-root {
    font-weight: 500 !important;
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    background: white !important;
    width: 84% !important;
    height: 84% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    z-index: 2 !important;
    border-radius: 100% !important;
  }
}
.circular_static_div_text {
  margin: 0 20px;
  text-transform: capitalize;
  h3 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 12px;
  }
}
.dashbaord_white_card_new {
  padding: 2rem 1rem 2rem 2rem;
  position: relative;
}
.ProjectDetailsCard_img {
  width: 10rem;
  height: 10rem;
  @include bg_img;
  border-radius: 3px;
}
.circular_static_div_main_div {
  display: flex;
}
.progress_all_tabel_tr2 {
  background-color: white;
  color: #484848;
}
.team_work_progress_tabel_img {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
}
.team_work_progress_tabel_btn {
  position: relative;
  a {
    position: absolute;
    font-size: 10px;
    padding: 5px 0;
    top: -2px;
    width: 80px;
  }
}
.team_work_progress {
  display: inline-table;
  overflow-x: auto;
  white-space: nowrap;
}
.project_details_bar_div {
  position: relative;
  width: 15rem;
  .progress {
    height: 8px;
    .progress-bar {
      background-color: #39c222;
    }
  }
}
.project_details_status_text {
  color: #e74c3c;
}
.overview_basic_details_div {
  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    li {
      font-weight: 600;
      font-size: 14px;
      span {
        margin-left: 1px;
      }
      label {
        top: 0;
        position: absolute;
        right: 15px;
      }
      p {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 0;
        color: #808080;
        margin-left: 10px;
      }
    }
  }
}
.overview_basic_details_inner_div {
  width: 9rem;
}
.all_edit_icon {
  position: relative;
  .all_edit_icon_inner {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #ff8059;
    border-radius: 100%;
    font-size: 15px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    svg {
      color: #ff8059;
    }
  }
}
.brief_summery_div {
  text-transform: capitalize;
  margin-top: 1rem;
  h3 {
    font-weight: 600;
    font-size: 16px;
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    margin-top: 20px;
    letter-spacing: 0.06em;
  }
}

//
.Attachments_top_div {
  text-align: center;
  position: relative;
  transition: all 0.3s;
  border-radius: 3px;
  p {
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: 9px;
    padding: 3px;
    background-color: #eee7e7;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &:hover {
    .Attachments_top_div_inner_top {
      display: block;
      transition: all 0.3s;
    }
  }
}
.Attachments_top_div_cut {
  background: white;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 15px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 2px;
  }
}
.Attachments_top_div_inner_top {
  transition: all 0.3s;
  display: none;
}
.Attachments_top_div_inner {
  transition: all 0.3s;
  position: absolute;
  background: #00000073;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    background-color: #eee7e7;
    color: black;
    padding: 1px 8px;
    border-radius: 3px;
  }
}
.delete_modal {
  ul {
    margin: 0;
    display: flex;
    text-transform: capitalize;
    justify-content: center;
    text-align: center;
    li {
      svg {
        font-size: 30px;
        margin-right: 1rem;
      }
    }
    p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
.delete_modal_btn_div {
  ul {
    margin: 0;
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    .btn_one {
      margin-right: 10px;
    }
    .btn_two {
      margin-left: 10px;
      .management_btn_full {
        background: rgba(209, 36, 47, 0.05) !important;
        color: #d1242f !important;
        border: 1px solid #d1242f;
      }
    }
  }
}
//
.doc_img {
  @include bg_img;
  background-position: top !important;
  width: 100%;
  height: 3rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.service_overview_details {
  text-transform: capitalize;
  h3 {
    font-weight: 500;
    font-size: 14px;
  }
  h4 {
    font-weight: 500;
    font-size: 12px;
    color: #626262;
  }
  h5 {
    font-weight: 600;
    font-size: 12px;
    color: #2ba317;
  }
  p {
    margin-bottom: 0;
    color: #252525;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.service_overview_details_div {
  .progress {
    height: 13px;
    .progress-bar {
      background-color: #23db62;
    }
  }
}
.Client_Profile_main_top_div_new {
  margin: 0 10rem;
}
.Client_Profile_svg_new {
  margin-left: -69px !important;
}
.Client_Profile_svg_new_new {
  svg {
    margin-left: -45px !important;
  }
}
.text_btn {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}
.drop_box2 {
  .css-1s2u09g-control {
    height: 64px;
  }
  .css-1pahdxg-control:hover {
    height: 64px;
  }
  .css-1rhbuit-multiValue {
    padding: 8px;
  }
  .css-1pahdxg-control {
    height: 64px;
  }
  .css-1s2u09g-control {
    height: 64px;
  }
  .css-9gakcf-option {
    height: 64px;
  }
  // .css-yt9ioa-option {
  //   height: 64px;
  // }
  // .css-6j8wv5-Input {
  //   height: 64px;
  // }
}
.inner_mr {
  margin: 0 5rem;
}
.demo {
  position: relative;
  z-index: 1;
}
.avatar_inner {
  width: 2.5rem;
  height: 2.5rem;
  @include bg_img;
  border-radius: 100%;
  border: 1.5px solid white;
}
.avatar_inner_div_over {
  margin-left: -10px;
}
.avatar_inner_div {
  display: flex;
}
.avatar_inner_plus {
  background-color: #e3e6e8;
  border: 1.5px solid white;
  font-weight: 600;
  font-size: 12px;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
// Dashboard end
// chat
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0px 13px;
}

.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -9px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 9px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: green;
  }
  50%,
  100% {
    background-color: rgb(149, 138, 138);
  }
}

.chat_div {
  // background-color: #f4f7fb;
  width: 100%;
  // height: 100%;
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    .chat_list_mess_number {
      color: white;
      background-color: rgba(246, 246, 249, 0.25);
    }
    .chat_list_name_div {
      .chat_status_div_new {
        .chat_label_status {
          font-size: 10px;
          color: #666;
        }
      }
      p {
        color: white;
      }
    }
    .chat_list_time {
      p {
        color: #666;
        text-transform: uppercase;
      }
    }
    background-color: #4eac6d;
  }
  .nav-link {
    padding-left: 10px;
    border-radius: 0;
    transition: all 0.3s;
    border-bottom: 1px solid #f1e9e9 !important;
    &:hover {
      background-color: #e9ecef;
    }
  }
}
.chat_list_time {
  p {
    color: black;
    text-transform: uppercase;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    right: -7px;
    font-size: 11px;
  }
}
.chat_list_mess_number {
  position: absolute;
  display: flex;
  background-color: rgba(52, 58, 64, 0.18);
  width: 20px;
  border-radius: 6px;
  height: 20px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  bottom: 3px;
  right: 0;
  color: #343a40;
  font-weight: 500;
}
.chat_search_div {
  position: relative;
  span {
    position: absolute;
    top: 6px;
    left: 8px;
    svg {
      font-size: 20px;
      color: #7b7b7b;
    }
  }
  input {
    width: 100%;
    padding: 7px;
    margin-bottom: 1rem;
    border-radius: 18px;
    border: none;
    padding-left: 33px;
    color: #7b7b7b;
    &::placeholder {
      text-transform: capitalize;
      color: #7b7b7b;
    }
  }
}
.chat_list_top_div {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  padding-left: 0;
  padding-right: 0;
  height: 40.7rem;
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.chat_list_div {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    align-items: center;
  }
}
.chat_list_img {
  @include bg_img;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}
.chat_list_name_div {
  margin-left: 10px;
  p {
    margin-bottom: 0;
    color: black;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 500;
  }
}
.chat_status_div {
  display: flex;
  align-items: baseline;
  h3 {
    font-size: 10px;
    text-transform: capitalize;
    color: #666;
    margin-bottom: 0;
    margin-top: 2px;
  }
}
.chat_label_div {
  background: #dde2ff;
  padding: 1rem;
  border-radius: 10px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.chat_mes_main_div {
  background-color: #e7ebef;

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;

  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  height: 35.7rem;
  position: relative;
  // background-image: url("../../pics/chat.png");
  @include bg_img;
  // height: 100%;
  width: 100%;
  background-position: top !important;
}
.chat_label_text_div {
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
  margin-bottom: 1px;
  margin-top: 2px;
}
.chat_label_status {
  margin-bottom: 0;
  color: #666;
  font-size: 10px;
  span {
    text-transform: capitalize;
    margin-right: 5px;
  }
  label {
    margin-left: 3px;
    text-transform: uppercase;
  }
}
.chat_status_div_new {
  .chat_label_status {
    font-size: 10px;
    color: #666;
    margin-top: 0;
  }
}
.chat_mes_main_div_input_box {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #dde2ff;

  padding: 1.5rem;
  width: 100%;
  border-bottom-right-radius: 10px;
}
.chat_mes_input_div {
  display: flex;
  align-items: center;
  .chat_Input_main_div {
    margin-left: 3.3rem;
  }
}
.chating_div {
  overflow: scroll;
  height: 30rem;
  padding-top: 1.5rem;
}
.chat_Input_main_div {
  // textarea {
  //   // height: auto !important;
  //   // overflow: scroll !important;
  //   // min-height: 30px !important;
  // }
  .MuiInputBase-root {
    padding: 10px 10px 10px 10px;
    border: 1px solid #e0d8d8;
    max-height: 97px;
    overflow: scroll;
    border-radius: 8px;
    background-color: white;
    // font-size: 15px;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}
.chat_pules_btn {
  button {
    width: 35px;
    height: 35px;

    box-shadow: none;
    svg {
      width: 26px;
      height: 26px;
    }
  }
  .MuiSpeedDialIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.chat_send_btn {
  button {
    outline: none;
    border: none;
    background: none;
  }
  svg {
    font-size: 23px;
    margin-left: 1rem;
    cursor: pointer;
  }
}
.left_chat_time {
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 5px;
}
.left_chat_div {
  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0px;
    }
    .li_left_chat_div {
      background-color: #e8ffe9;
      display: inline-block;
      padding: 10px;
      border-radius: 6px;
      position: relative;
      transition: all 0.3s;
      margin-top: 10px;
      &:hover {
        .option_chat {
          display: block;
        }
      }
      .option_chat {
        padding: 1px 5px;
        background-color: white;
        border-radius: 3px;
        .option_chat_inner_new {
          display: flex;
          align-items: center;
          .option_chat_inner__nner {
            margin-right: 7px;
            margin-bottom: 0;
            margin-top: 0;
            cursor: pointer;
            .line {
              width: 2px;
              height: 17px;
              background: #d6c5c5;
              position: absolute;
              top: 3px;
              right: -4px;
            }
          }
        }
        position: absolute;
        top: -16px;
        right: -96px;
        display: block;

        button {
          svg {
            width: 17px;
            height: 17px;
            margin-bottom: 3px;
            color: #d6c5c5;
            &:hover {
              color: #1a7fdd;
            }
          }
        }
      }
    }
  }
}
.left_chat_user {
  margin-right: 10px;
}
.left_chat_div_inner {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  max-width: 26rem;
  text-align: justify;
}
.chat_option {
  .MuiButtonBase-root {
    font-size: 12px;
  }
}
.MuiMenuItem-root {
  font-size: 12px !important;
  text-transform: capitalize;
}
.chat_option_name_div {
  width: 100%;
  position: relative;
  .icon {
    position: absolute;
    top: -6px;
    right: 0;
    font-size: 18px;
  }
}
.right_chat_div {
  position: relative;
  .right_chat_div {
    background-color: #cee1d4;
    display: inline-block;
    padding: 10px;
    border-radius: 6px;
    position: relative;
    margin-top: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
  .right_chat_user {
    margin-left: 10px;
  }
  .right_chat_div_inner {
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
    max-width: 26rem;
    text-align: justify;
    &:hover {
      .option_chat {
        display: block;
      }
    }
  }

  .option_chat {
    padding: 1px 5px;
    background-color: white;
    border-radius: 3px;
    .option_chat_inner_new {
      display: flex;
      align-items: center;
      .option_chat_inner__nner {
        margin-left: 7px;
        margin-bottom: 0;
        margin-top: 0;
        cursor: pointer;
        .line {
          width: 2px;
          height: 17px;
          background: #d6c5c5;
          position: absolute;
          top: 3px;
          left: -3px;
        }
      }
    }
    position: absolute;
    top: -16px;
    left: -96px;
    button {
      svg {
        width: 17px;
        height: 17px;
        margin-bottom: 2px;
        margin-bottom: 3px;
        color: #d6c5c5;
        &:hover {
          color: #1a7fdd;
        }
      }
    }
  }
  .col_div_inner {
    display: flex;
    justify-content: flex-end;
  }
}
.chat_inner_img_shar {
  @include bg_img;
  height: 8rem;
  width: 8rem;
  border-radius: 6px;
}
.chat_inner_img_shar_right_li {
  margin-left: 10px;
}
.p1 {
  padding-right: 0 !important;
}
.p2 {
  padding-left: 0 !important;
}
.chat_sub_menu {
  margin-bottom: 0;
  border-top: 1px solid;
  background: #eee6e6;
  padding: 7px 17px;
}
.chat_div_dropdown {
  .rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-item-toggle {
    padding: 4px 27px 4px 12px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item
    > .rs-dropdown-item-toggle
    .rs-dropdown-menu-toggle-icon {
    top: 8px;
  }
  .rs-dropdown-item {
    justify-content: flex-start !important;
    color: black !important;
    padding: 5px 12px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-menu {
    position: absolute;
    top: 0%;
  }

  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    padding: 0;
    background-color: transparent;
  }
  .rs-dropdown-toggle-caret {
    display: none;
  }
}
.chat_div_dropdown_right {
  .rs-dropdown-item-submenu .rs-dropdown-menu {
    left: -99%;
  }
}
.chat_div_dropdown_inner {
  width: 9rem;
  position: relative;
  font-size: 13px;
  font-weight: 600 !important;
  span {
    position: absolute;
    top: 5px;
    right: 10px;
    svg {
      font-size: 18px;
    }
  }
}
.mes_reax {
  background-color: white;
  position: absolute;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 25px;
  bottom: -12px;
  right: -10px;
  span {
    font-weight: 600;
    margin-left: 5px;
  }
}
.mes_reax2 {
  left: -10px;
  background-color: white;
  position: absolute;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 25px;
  bottom: -12px;
  span {
    font-weight: 600;
    margin-left: 5px;
  }
}
// chat end
// Projects
.Progress_title_details {
  text-align: right;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 12px;
}
.Progress_title_details_midel {
  color: #eb703b;
}
.Progress_title_details_top {
  margin-top: -11px;
}

.Progress_title_details_white {
  height: 20.3rem;
  position: relative;
  padding: 20px;
  padding-left: 30px;
}
.drag_img {
  position: absolute;
  top: 21px;
  left: 8px;
  width: 22px;
  cursor: pointer;
}
.team_status_title {
  text-align: center;
  h3 {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
  }
}

.team_status_inner_title {
  margin-bottom: 0;
  display: flex;
  li {
    margin: auto;
    font-weight: 500;
    font-size: 12px;
  }
}
.team_status_inner_title2 {
  margin-bottom: 0;
  display: flex;
  li {
    margin: auto;
    font-weight: 700;
    font-size: 14px;
    margin-right: 45px;
  }
}
.team_status_inner_color {
  color: #f02934;
}

.team_status_uper_div {
  display: flex;
  align-items: center;
}
.weekly_digest_title {
  position: relative;
  img {
    position: absolute;
    top: 13px;
    left: 12.3rem;
    z-index: 1;
  }
  .form-select:focus {
    box-shadow: unset;
  }
  select {
    border: none;
    position: absolute;
    top: 6px;
    width: 4rem;
    font-size: 12px;
    font-weight: 500;
    left: 146px;
    padding: 0;
    background-image: none;
    color: #1a7fdd !important;
  }
  h3 {
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 13px;
    text-transform: capitalize;
    color: #525252;
  }
}
.custom_dropdown_last {
  .rs-dropdown {
    width: 100%;
    height: 100%;
    button {
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-weight: 600;
      color: black;
      text-align: left;
      padding-left: 10px !important;
      margin-top: -6px;
      transition: all 0.3s;
      border-radius: 0;
      padding-bottom: 5px !important;
      padding-top: 5px !important;
      margin-top: 0px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      &:hover {
        background-color: #e5f3ff;
      }
    }
  }
}
.custom_dropdown_inner {
  span {
    float: right;
    padding-right: 10px;
    svg {
      font-size: 18px !important;
    }
  }
}
.project_tab_mb {
  margin-bottom: 1.5rem;
}
.header_my {
  margin-bottom: 1.5rem;
  align-items: center;
  .page_All_top_title {
    margin-bottom: 0;
    h3 {
      margin-bottom: 0;
    }
  }
  .header_btn {
    text-align: end;
    .management_btn_full2 {
      width: 35%;
    }
  }
}
.header_input_div {
  position: relative;
  input {
    color: #949494;
    border: none;
    font-weight: 400;
    font-size: 14px;
    padding-right: 34px;
    height: 35px;
  }
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: #949494;
    cursor: pointer;
  }
}
// Projects end
// Tasks
.tasks_icon {
  width: 24px;
  height: 24px;
  @include bg_img;
}
.view_change_label {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
}
.view_change_main_div {
  position: absolute;
  top: 0;
  right: 136px;
  .css-b62m3t-container {
    outline: none;
  }
  .css-1s2u09g-control {
    border: none;
    box-shadow: none;
    min-height: 42px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0px 0px 12px rgb(81 67 239 / 50%);
    }
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  // .css-319lph-ValueContainer {
  //   padding-right: 0;
  // }
  .css-tlfecz-indicatorContainer {
    padding-left: 0;
  }
  .css-1gtu0rj-indicatorContainer {
    padding-left: 0;
  }
}
.classic_view_outer {
  .accordion-button {
    // padding: 0;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    text-transform: capitalize;
    background-color: transparent;
    color: black;
    padding: 10px;
    &:focus {
      box-shadow: none;
    }
  }
  .accordion-item {
    background-color: #f0eaea;
    border: none;
  }
  .accordion-button:not(.collapsed) {
    color: #1a7fdd;
    background-color: transparent;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url(../../pics/Management/outer.png);
    transform: rotate(-180deg);
    @include bg_img;
    background-size: auto;
  }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: '';
    background-image: url(../../pics/Management/outer.png);
    @include bg_img;
    background-size: auto;
    transition: transform 0.2s ease-in-out;
  }

  .classic_view_outer_body {
    padding: 10px;
    .accordion-button {
      // padding: 0;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      color: #818181;
      span {
        padding-left: 25px;
      }
    }
    .accordion-button:not(.collapsed) {
      color: #ff3e48;
    }
    .accordion-item {
      bottom: 0;
      background-color: transparent;
    }
    .accordion-button::after {
      background-image: url(../../pics/Management/Vector2.png);
      @include bg_img;
      background-size: auto;
      position: absolute;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url(../../pics/Management/Vector2.png);
    }
  }
}
.classic_view_tabel_heder {
  button {
    padding: 0 !important;
  }
}
.classic_view_inner_header {
  button {
    padding-top: 0 !important;
  }
}
.classic_view_inner_body {
  padding: 0px 15px 15px 15px !important;
}
.classic_view_tabel_body {
  padding: 8px 5px 8px 5px !important;
}
// Tasks end
// View All Tasks
.view_all_tasks_btn {
  .view_change_main_div {
    position: unset;
    .view_change_label {
      span {
        display: block !important;
      }
    }
  }
}
.view_all_tasks_title_div {
  display: flex;
  align-items: baseline;
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
  }
  a {
    font-weight: 500;
    font-size: 14px;
    color: #1a7fdd;
    text-decoration: underline !important;
    margin-left: 10px;
  }
}
.back_btn_mb {
  .page_All_top_title {
    margin-bottom: 10px;
  }
}
.inner_taska_div_main {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
}
.inner_taska_title_div_main {
  position: relative;
  h3 {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
  }
}
.inner_taska_title_btn_div_main {
  position: absolute;
  top: 0;
  right: 0;
  .management_btn_full {
    font-weight: 600;
    font-size: 10px;
    padding: 5px 6px;
    border-radius: 3px;
  }
}
.open_tasks_card {
  background-color: #eae3ff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  min-height: 7rem;
  h3 {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
  }
  .row {
    margin-top: 2rem;
  }
  .project_list_priority_div {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
}
.open_tasks_img_div {
  position: absolute;
  bottom: 10px;
  left: 8px;
  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    height: 25px;
    width: 25px;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
  .MuiAvatar-circular {
    width: 25px;
    height: 25px;
    position: unset;
  }
  .css-1xs323g-MuiAvatar-root {
    position: unset;
  }
}
.open_tasks_card2 {
  background-color: #e7ffe3;
}
// View All Tasks end
// Meetings
.filter_btn_top_div {
  display: flex;
  justify-content: flex-end;
  .management_btn_outline_with_icon {
    width: 12%;
    background-color: white;
    border: none;
    color: black;
  }
}
.notes_list_img_div {
  margin-left: 5px;
  p {
    margin: 0;
    font-weight: 400;
  }
}
.notes_warning {
  position: absolute;
  right: 13%;
}
.filter_btn_top_new {
  .management_btn_outline_with_icon {
    background-color: white;
    border: none;
    color: black;
  }
}
.sehedule_notification_time_div {
  display: none;
  align-items: baseline;
  p {
    margin-bottom: 0;
    margin-right: 15px;
    font-size: 15px;
    margin-left: 15px;
  }
}

.sehedule_notification_delete_btn {
  position: relative;
  span {
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 6px;
    cursor: pointer;
    svg {
      font-size: 21px;
    }
  }
}
.sehedule_notification_top_div {
  display: flex;
  .my_m {
    margin-right: 10px;
  }
}
.sehedule_notification_number_input_div {
  input {
    width: 70px;
  }
}
.add_reminder_notification_btn_div {
  .management_btn_full {
    text-align: center;
    text-transform: capitalize;
    display: inline;
    cursor: pointer;
  }
}
// popup
.meeting_custom_popup_top_div {
  h3 {
    color: #252525;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.5rem;
  }
}
.meeting_custom_popup_repeat_every_div {
  display: flex;
  align-items: center;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  p {
    color: #252525;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  .count_input {
    text-align: center;
    width: 4rem;
    margin-right: 10px;
  }
  .count_input_btn_div {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    button {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 13px;
      height: 13px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: #efefef;
      }
      svg {
        font-size: 11px;
      }
    }
  }
}
.meeting_toggle_btn_div {
  display: flex;
  .MuiToggleButton-root.Mui-selected {
    background-color: #0f62fe !important;
    color: white !important;
  }
  button {
    border-radius: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 30px !important;
    width: 30px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin-right: 10px !important;
  }
}
.meeting_popup_end_div {
  p {
    color: #252525;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  .on {
    .date_picker {
      padding: 5px 10px;
      border-radius: 6px;
      border: 1px solid #ced4da;
      background-color: #dfe2e3;
    }
    .date_picker2 {
      opacity: 0.5;
    }
  }
  .After {
    display: flex;
    .After_inner_div2 {
      opacity: 0.5;
    }
    .After_inner_div {
      display: flex;
      align-items: center;
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .After_input {
        text-align: center;
        width: 4rem;
        border: none;
        background-color: #dfe2e3;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &:focus {
          color: #212529;
          border-color: transparent;
          outline: 0;
          box-shadow: none;
        }
      }
      .count_input_btn_div {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        button {
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          width: 13px;
          height: 13px;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #efefef;
          }
          svg {
            font-size: 11px;
          }
        }
      }
      .text_p2 {
        cursor: no-drop;
      }
      .text_p {
        color: #ababab;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.28px;
        margin-bottom: 0;
        background-color: #dfe2e3;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        margin-right: 18px;
        text-transform: capitalize;
      }
    }
  }
}
.meeting_custom_popup_top_div_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}
// Meetings end
// Approvals
.approval_modal_sub_title_div2 {
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    li {
      color: rgba(0, 0, 0, 0.75);
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        border-radius: 2px;
        background: black;
        color: #fff;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        padding: 3px 15px;
        text-align: center;
        border-radius: 3px;
        margin-left: 4px;
      }
    }
    .li_first {
      width: 12rem;
    }
  }
}
.approval_request_details_feedback_ul {
  margin: 0;
  margin-top: 1rem;
  list-style-type: none;
  padding-left: 0 !important;
  li {
    border-left: 1px dashed #000000;
    padding-left: 20px;
    position: relative;
  }
  .feedback_modal_ul_icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -2px;
    left: -12px;
    border-radius: 100%;
  }
  .feedback_modal_ul_requested {
    border: 1px solid #1a7fdd;
    background-color: #e8f2fc;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    padding: 5px 10px;
    border-radius: 6px;
  }
  .feedback_modal_ul_name {
    color: #252525;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-bottom: 0;
    margin-top: 10px;
    text-transform: capitalize;
    span {
      color: #626262;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
    }
  }
  .feedback_modal_ul_commant {
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    margin-top: 12px !important;
    img {
      width: 16px;
      height: 16px;
      @include bg_img;
      margin-right: 5px;
    }
    span {
      color: #000;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 1px;
    }
  }
  .feedback_modal_ul_commant_coyp_btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    cursor: pointer;
  }
  .add_new_subtask_Uploaded_mai_div {
    width: 23rem;
  }
  .feedback_modal_ul_time {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 0;
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    padding-bottom: 1rem;
    svg {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .feedback_modal_ul_main_div {
    margin-bottom: 10px;
  }
}
.approval_request_details_subtask_top_btn_div {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: end;
  align-items: center;
  label {
    background-color: #f2f2f2;
    margin-left: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 3px;
    border-radius: 4px;
    cursor: pointer;
    svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}
.modal_body_new {
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  position: relative;
  margin-top: 0 !important;
}
.sub_task_scroll_new {
  overflow: scroll;
  min-height: auto;
  max-height: 360px;
}
.sub_task_approval_request_details_commants_view_div {
  display: flex;
  margin-top: 1rem;
  img {
    width: 16px;
    height: 16px;
    @include bg_img;
    margin-right: 5px;
    margin-top: 2px;
  }
}
.sub_task_approval_request_details_commants_view {
  margin-bottom: 0;
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}
.sub_task_filed_approvls_tab {
  .accordion-button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .accordion-button {
    border: none !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding: 15px 10px 15px 15px !important;
    border-radius: 6px !important;
  }
}
.approval_request_input_switch_text {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}
.approval_request_input_add_number {
  background-color: #f5f7ff;
  margin-bottom: 0;
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.approval_request_input_remove_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  svg {
    font-size: 24px;
    cursor: pointer;
  }
}
.approval_request_input_add_btn {
  margin-bottom: 0;
  color: #1a7fdd;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;
  cursor: pointer;
  svg {
    font-size: 18px;
    margin-bottom: 3px;
    margin-right: 8px;
  }
}
.uplode_menu_item {
  img {
    @include bg_img;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.new_uplode_btn {
  color: #1a7fdd;

  svg {
    color: #1a7fdd;
    margin-right: 5px;
  }
}
.reassign_my_label_new {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
  color: #252525;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.reassign_btn_div {
  margin-top: 6rem !important;
}
.reassign_btn1_div {
  button {
    color: #000 !important;
    .MuiButton-startIcon {
      margin-right: 0 !important;
    }
  }
}
.reassign_btn2_div {
  display: flex;
  justify-content: flex-end;
}
.Participants_main_div_Approvals {
  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    display: flex;
    width: 24px;
    height: 24px;
    font-size: 12px;
    margin-left: 0px !important;
    justify-content: center;
    align-items: center;
    color: #626262;
    background: #c4c4c4;
  }
}
// Approvals end
// clint profile card
.clint_profile_card_text {
  text-transform: capitalize;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    color: #3786ee;
  }
}
.clint_profile_card_poits {
  text-transform: capitalize;
  display: flex;
  align-items: baseline;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
  }
  span {
    svg {
      margin-right: 5px;
      font-size: 17px;
    }
    img {
      width: 15px;
      margin-right: 5px;
    }
  }
}
.Project_list_card {
  position: relative;
  background-color: #e7ffe3;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 2rem;
  .user_logo_upload_main_div {
    label {
      display: none;
    }
  }
}
.Project_list_card_img {
  width: 4rem;
  height: 4rem;
  border-radius: 6px;
  @include bg_img;
}
.Project_list_card_img_div {
  text-align: center;
  position: relative;
  top: -40px;
  p {
    margin-bottom: 0;
    text-transform: capitalize;
    color: #eb703b;
    font-weight: 600;
    font-size: 12px;
  }
}
.Project_list_card_time {
  background: #fcdbff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 5px 8px;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  border-radius: 3px;
  right: 1rem;
  top: 1rem;
  span {
    svg {
      font-size: 18px;
      margin-right: 3px;
    }
  }
}
.Project_list_card_title {
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
  }
}
.Project_list_card_inner_img {
  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    height: 35px;
    width: 35px;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
  .MuiAvatar-circular {
    width: 35px;
    height: 35px;
    position: unset;
  }
  .css-1xs323g-MuiAvatar-root {
    position: unset;
  }
}

.Project_list_card_inner_img_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Project_list_card_inner_img_top_div {
  margin-top: -50px;
}
.Project_list_card_inner_card_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.Project_list_card_progress {
  .progress {
    height: 25px;
    background-color: #ffffff;
  }
  .progress-bar {
    background-color: #23db62;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    color: #252525;
    text-align: center;
    margin-bottom: 5px;
  }
}
.client_tasks_div {
  position: relative;
}

// clint profile card end
// scroll all
.project_list_scroll {
  margin-top: 1rem;
  min-height: auto;
  max-height: 50rem;
  overflow: scroll;
}
.clints_list_scroll {
  overflow: scroll;
  max-height: 63.4rem;
  min-height: auto;
  margin-top: 1rem;
}
.dashbaord_scroll_new {
  overflow: scroll;
  height: 18rem;
}
.dashbaord_scroll {
  max-height: 22.3rem;
  min-height: 22.3rem;

  // min-height: auto;
  margin-top: 1rem;
}
.project_details_dashboard_div {
  overflow: scroll;
  max-height: 16rem;
  min-height: 16rem;
}
.set_view_type_btn {
  display: flex;
  justify-content: flex-end;
  // align-items: center;
  .new_btn_nav {
    display: block;
  }
}
.set_view_type_btn_inner {
  margin-right: 1rem;
  .view_change_label {
    span {
      display: block !important;
    }
  }
}
.dashbaord_scroll_cla {
  height: 22.3rem;
  margin-top: 1rem;
}
// scroll all end
.documents_app_btn {
  svg {
    margin-left: 5px;
  }
}
// user card list
.user_card_div {
  width: 25rem;
  min-height: auto;
  max-height: 15rem;
  padding: 1rem;
  background-color: #e3e6e8;
  overflow: scroll;
}
.user_card_div_inner {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d5c9c9;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #ffffff;
  .MuiAvatar-circular {
    width: 35px !important;
    height: 35px !important;
  }
  p {
    margin-bottom: 0;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: bold;
  }
  h6 {
    margin: 0;
    text-transform: capitalize;
    position: absolute;
    right: 10px;
    color: #969696;
    font-size: 11px;
  }
}
.user_card_div_title {
  border-bottom: 1px solid #ddc8c8;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 10px;
  background-color: #e3e6e8;
  h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0;
  }
}
.card_user_img {
  .Participants_icon {
    right: -13px;
  }
  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    height: 35px;
    width: 35px;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
  .MuiAvatar-circular {
    width: 35px;
    height: 35px;
    position: unset;
  }
  .css-1xs323g-MuiAvatar-root {
    position: unset;
  }
}
.Participants_icon {
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: 8px;
  display: none;
}
.Participants_icon_div {
  position: relative;
  &:hover {
    .Participants_icon {
      display: block;
    }
  }
}
.all_user_page {
  margin-bottom: 1.5rem;
  width: 10rem;
}
// user card list end
// filter new
.date_filter_div {
  display: flex;
  .from {
    margin-right: 5px;
  }
  .to {
    margin-left: 5px;
  }
}
.filter_new_top_div {
  padding: 1rem;
}
.filter_new_top_div_inner {
  width: 25rem;
  .accordion-button::after {
    transform: rotate(270deg);
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
  }
  button {
    font-weight: 600 !important;
    font-size: 15px !important;
    border: none !important;
    outline: none !important;
    text-transform: capitalize;
  }
  .accordion-item {
    border: none;
    background-color: #ecf6ff;
  }
  .accordion-button:not(.collapsed) {
    background-color: white;
    box-shadow: none;
    color: black;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .header_input_div {
    margin-bottom: 15px;
    margin-top: 15px;
    input {
      border: 1px solid #c4c4c4;
    }
  }
}
.Priority_filter_inner {
  background: black;
  display: inline-block;
  color: white;
  padding: 5px 18px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;
  text-transform: capitalize;
  width: 5rem;
  text-align: center;
}
.Requested_by_div {
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 10px;
  }
}
.filter_title_1 {
  font-weight: 500;
  font-size: 16px;
  color: #252525;
  text-transform: capitalize;
  margin-bottom: 0;
}
.filter_title_2 {
  font-weight: 500;
  font-size: 16px;
  color: #1a7fdd;
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 0;
}
// filter new end
// create poll modal
.create_poll_modal_icon {
  cursor: pointer;
  font-size: 20px;
  margin-top: -5px;
}
.create_poll_modal_input {
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #8d8d8d !important;
  border-radius: 0 !important;
}
.create_poll_modal_input_data {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  padding: 7px 10px;
  border-radius: 10px !important;
  width: 90% !important;
}
.create_poll_modal_input_data_div {
  margin-top: 17px;
  display: flex;
  align-items: center;
  label {
    transform-origin: top !important;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 20px;
      cursor: pointer;
      color: #252525;
      transition: all 0.3s;
      &:hover {
        color: #1976d2;
      }
    }
  }
}
.create_poll_modal_input_btn {
  color: #1a7fdd !important;
  padding-left: 0 !important;
  margin-top: 1rem;
  text-transform: capitalize;
  span {
    svg {
      font-size: 18px;
      margin-top: -3px;
      margin-right: 5px;
    }
  }
}
.create_poll_modal_top_div {
  border-right: 1px solid #d9d9d9;
  padding-right: 30px !important;
  h3 {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
}
.create_poll_modal_main_div {
  position: relative;
}
.create_poll_modal_close_icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 23px;
}
.create_poll_modal_preview_title {
  padding-top: 18px;
  padding-left: 10px;
  h5 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 1.5rem;
  }
}
.create_poll_modal_preview_box_div {
  border: 1px solid #949494;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  p {
    font-weight: 500;
    font-size: 12px;
    border-bottom: 1px solid #dde2ff;
    padding-bottom: 5px;
    text-transform: capitalize;
    margin-bottom: 7px;
  }
  span {
    position: absolute;
    top: 10px;
    right: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    color: #949494;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
  }
}
.create_poll_modal_preview_box_inner_div {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: #626262;
  margin-top: 6px;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
}
.icon_color_div2 {
  svg {
    color: #ffb46f !important;
    font-size: 21px !important;
  }
}
.create_poll_modal_preview_box_btn {
  text-align: center;
  margin-top: 15px;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: #0f62fe;
    border: 1px solid #0f62fe;
    display: inline-block;
    padding: 5px 15px !important;
    border-radius: 6px;
    text-align: center;
    padding: 0;
  }
}
.create_poll_modal {
  width: 50%;
}
.create_poll_modal_send_btn {
  text-align: center;
  margin-top: 1.5rem;
  button {
    background-color: #0f62fe !important;
    color: white !important;
    img {
      margin-left: 5px;
      width: 16px;
      height: 16px;
    }
  }
}
.poll_settings_div {
  p {
    cursor: pointer;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    padding-top: 1.5rem;
  }
}
.poll_settings_div_list {
  position: relative;
  margin-top: 15px;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    color: #696969;
  }
  .form-select:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  select {
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
    border: 0;
    color: #0f62fe;
    font-size: 16px;
    font-weight: 500;
    background-image: url('../../pics/Management/down2.svg');
    background-size: 13px 10px;
  }
}
.poll_settings_btn_div {
  margin-top: 2rem;
  text-align: end;
  .management_btn_full2 {
    width: auto;
  }
}

// create poll modal end
// Task Right Drawer Ui
.task_right_drawer_ui_main_div {
  width: 50rem;
}
.task_right_drawer_ui_header_div {
  position: relative;
  background-color: #f4f4f4;
  padding: 1rem;
  .close_svg {
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 18px;
  }
  a {
    color: black;
    font-size: 21px;
    margin-right: 12px;
  }
  .menu_svg {
    font-size: 22px;
    cursor: pointer;
  }
}
.task_right_drawer_ui_inner_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.team_members_ui_modal {
  width: 400px;
}
.team_members_ui_modal_title {
  padding: 1rem;
  position: relative;
  h3 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 20px;
    cursor: pointer;
  }
}
.team_members_data_ui_div {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  img {
    @include bg_img;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    // margin-right: 10px;
    width: 35px;
    height: 35px;
  }
  span {
    position: absolute;
    right: 0;
    top: auto;
    font-size: 14px;
    color: #3786ee;
    cursor: pointer;
  }
}
.team_members_ui_modal_body {
  padding: 1rem;
  padding-top: 0;
}
.team_members_data_ui_div_scorll {
  overflow: scroll;
  max-height: 27rem;
  min-height: auto;
}
.task_right_drawer_ui_body_div {
  padding: 1rem;
}
.task_right_drawer_ui_body_nav_div {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
  }
  span {
    margin: 0 2px;
    svg {
      margin-bottom: 2px;
      font-size: 18px;
    }
  }
}
.task_right_drawer_ui_body_project_title {
  margin-top: 1.5rem;
  position: relative;
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: #252525;
    max-width: 25rem;
    min-width: auto;
  }
}
.task_right_drawer_ui_body_user {
  position: absolute;
  top: 0;
  right: 0;
  .MuiAvatar-circular {
    width: 25px;
    height: 25px;
    position: unset;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
  .MuiAvatar-circular {
    width: 25px;
    height: 25px;
    position: unset;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
}
.task_right_drawer_ui_body_user_add {
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  color: #252525;
  margin-right: 10px;
  span {
    color: #3786ee;
  }
}
.task_right_drawer_ui_body_user_add_date {
  font-weight: 600;
  font-size: 12px;
  color: #949494;
  text-transform: capitalize;
}
.task_right_drawer_ui_body_created {
  display: flex;
  margin-top: 1rem;
  p {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #949494;
  }
  span {
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    color: #252525;
  }
  .li_1 {
    margin-right: 4rem;
  }
}
.task_right_drawer_ui_body_created2 {
  display: flex;
  margin-top: 1rem;
  p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #949494;
  }
  span {
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    color: #252525;
  }
}
.task_right_drawer_ui_body_Progress {
  color: #1a7fdd !important;
}
.task_right_drawer_ui_body_Priority {
  width: 54px;
  display: flex;
  height: 24px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  span {
    color: white;
  }
}
.task_right_drawer_ui_body_Dependency {
  display: flex;
  align-items: center;
  p {
    margin-left: 0;
    font-weight: 600;
    font-size: 12px;
    color: #949494;
    margin-right: 10px;
  }
}
.task_right_drawer_ui_body_Dependency_show {
  margin-top: 1.5rem;
}
.task_right_drawer_ui_body_Dependency_show_text {
  margin-top: 1.5rem;
}
.task_right_drawer_ui_body_Dependency_select {
  margin-bottom: 10px;
  p {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    color: #949494;
  }
  select {
    width: 50%;
  }
}
.task_right_drawer_ui_body_description {
  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    color: #252525;
    margin-bottom: 1rem;
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

.task_right_drawer_ui_body_description_text {
  border: 1px solid lightgray;
  border-radius: 10px;
  letter-spacing: 0.6px;
  padding: 10px 13px;
  text-align: justify;
  font-size: 14px;
}
.task_right_drawer_ui_body_Subtasks_input_div {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  input {
    width: 50%;
  }
  svg {
    font-size: 22px;
    margin-left: 10px;
    color: #252525;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: #1a7fdd;
    }
  }
}
.text_button {
  background-color: transparent;
  outline: none;
  color: #1a7fdd;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  border: 1px solid #1a7fdd;
  padding: 7px 15px;
  border-radius: 4px;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
}
.task_right_drawer_ui_body_tab {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: transparent;
    color: #1a7fdd;
    border-bottom: 2px solid #1a7fdd;
    border-right: 0 !important;
  }
  .nav-link {
    border-radius: 0 !important;
    color: #949494;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
.comments_user_ui {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    margin-right: 14px;
    @include bg_img;
  }
  li {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
  }
}
.comments_img_inner {
  padding-left: 3.5rem;
  position: relative;
}
.comments_img_div {
  transition: all 0.3s;
  // min-width: 48%;
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 5px;
  position: relative;
  &:hover {
    transition: all 0.3s;
    .comments_img_name {
      transition: all 0.3s;
      display: none;
    }
    .comments_img_name_download_div {
      transition: all 0.3s;
      display: block;
    }
  }
  .comments_img_name {
    transition: all 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffffff8c;
    width: 100%;
    min-height: 31px;
    max-height: 80px;
    font-size: 10px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.comments_img {
  width: 8rem;
  height: 6.3rem;
  @include bg_img;
  border-radius: 10px;
  transition: all 0.3s;
}
.comments_img_ui_div {
  // max-width: 50%;
  // min-width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 31rem;
  transition: all 0.3s;
  // .comments_img_div {
  //   transition: all 0.3s;
  //   min-width: 48%;
  //   align-self: flex-start;
  //   margin-top: 5px;
  //   margin-right: 5px;
  //   position: relative;
  //   &:hover {
  //     transition: all 0.3s;
  //     .comments_img_name {
  //       transition: all 0.3s;
  //       display: none;
  //     }
  //     .comments_img_name_download_div {
  //       transition: all 0.3s;
  //       display: block;
  //     }
  //   }
  //   .comments_img_name {
  //     transition: all 0.3s;
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     background: #ffffff8c;
  //     width: 100%;
  //     height: 31px;
  //     font-size: 18px;
  //     text-transform: capitalize;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 5px;
  //     font-weight: 500;
  //     border-bottom-right-radius: 10px;
  //     border-bottom-left-radius: 10px;
  //   }
  // }
  // .comments_img {
  //   width: 100%;
  //   height: 15rem;
  //   @include bg_img;
  //   border-radius: 10px;
  //   transition: all 0.3s;
  // }
}
.comments_img_name_download_div {
  transition: all 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  color: black;
  display: none;
}
.comments_img_name_download {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff8c;
  span {
    background-color: #1a7fdd;
    width: 45px;
    height: 45px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      color: white;
      font-size: 30px;
    }
  }
}
.comments_menu_btn_div {
  position: relative;
}
.comments_menu_top_div {
  transition: all 0.3s;
  &:hover {
    transition: all 0.3s;
    .comments_menu_btn {
      transition: all 0.3s;
      display: block;
    }
  }
}
.comments_menu_btn {
  transition: all 0.3s;
  display: none;
  background: transparent;
  text-transform: capitalize;
  padding: 0;
  color: #1a7fdd;
  margin-right: 10px;
}
.comments_menu_outer_div {
  position: relative;
}
.comment_menu_div {
  position: absolute;
  top: -4px;
  right: -20px;
  font-size: 20px;
  cursor: pointer;
}
.comments_menu_time {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #949494;
  margin-left: 5px;
  cursor: auto;
  position: relative;
  span {
    margin-left: 6px;
  }
}
.comments_menu_time_div {
  display: flex;
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  margin-left: -4px;
  color: #1a7fdd;
  position: relative;
  li {
    margin-right: 10px;
    cursor: pointer;
  }
}
.comments_menu_time_div_inner {
  position: absolute;
  top: -2px;
  right: -98px;
}
.comments_user_ui_text {
  font-size: 15px;
  font-weight: 600;
  color: #666;
  text-align: justify;
  letter-spacing: 0.5px;
  padding-right: 1rem;
  word-wrap: break-all !important;
  a {
    word-wrap: break-all !important;
  }

  p {
    word-wrap: break-all !important;
  }
}
.comments_doc_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 40rem;
  transition: all 0.3s;
}
.comments_doc_item {
  border: 1px solid #e4d9d9;
  border-radius: 10px;
  width: 13rem;
  transition: all 0.3s;
  // min-width: 48%;
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 5px;
  &:hover {
    transition: all 0.3s;
    .comments_doc_name {
      display: none;
      transition: all 0.3s;
    }
    .comments_doc_name_dovnload_div {
      display: block;
      transition: all 0.3s;
    }
  }
  width: 8rem;
  height: 8rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.comments_doc_item_img {
  @include bg_img;
  width: 3rem;
  height: 3rem;
  transition: all 0.3s;
}
.comments_doc_name {
  padding: 5px 10px;
  border-top: 1px solid #e4d9d9;
  width: 100%;
  text-transform: capitalize;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
}
.comments_doc_name_dovnload_div {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e8e8df8c;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all 0.3s;
  display: none;
}
.comments_doc_name_dovnload {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    background-color: #1a7fdd;
    width: 45px;
    height: 45px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      color: white;
      font-size: 30px;
    }
  }
}
.comments_typing_div {
  display: flex;
  .comments_img {
    @include bg_img;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    margin-right: 14px;
  }
}
.comments_typing_inner_div {
  width: 100%;
  border: 1px solid #e4d9d9;
  border-radius: 3px;
  .css-1rcvvn7-MuiInputBase-root-MuiInput-root:before {
    border-bottom: none;
  }
  .css-1rcvvn7-MuiInputBase-root-MuiInput-root:hover:not(
      .Mui-disabled,
      .Mui-error
    ):before {
    border-bottom: none;
  }
  .css-1rcvvn7-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none;
  }
  .new_editer {
    .ql-editor {
      min-height: 15rem !important;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border: none;
    }
    .ql-toolbar.ql-snow {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border: none;
      border-bottom: 1px solid #ccc;
    }
  }
}
.time_zone_inner__top_div {
  .time_zone_inner_div {
    margin-bottom: 16px;
  }
}
.comments_typing_btn_div {
  border: 1px solid #e4d9d9;
  border-top: none;
}
.comments_typing_btn_top_div {
  background-color: #fafafa;
  padding: 1rem;
}
.comments_typing_btn_attach {
  background-color: transparent;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 550;
  transition: all 0.3s;
  color: #666;
  display: flex;
  align-items: center;
  &:hover {
    color: #1a7fdd;
  }
  svg {
    font-size: 18px;
    margin-right: 2px;
  }
}
.comments_typing_btn_inner_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .management_btn_full2 {
    width: 9rem;
  }
  .management_btn_outline {
    width: 9rem;
    margin-left: 10px;
  }
}
.comments_typing_Attach_div {
  // padding-bottom: 1rem;
  p {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 550;
    margin-bottom: 10px;
    padding: 0px 1rem;
    padding-top: 10px;
  }
}
.comments_typing_Attach_icon {
  width: 2.5rem;
  height: 2.5rem;
  @include bg_img;
}
.comments_typing_Attach_row {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem;
  background: white;
  transition: all 0.3s;
  &:hover {
    background: #f2ecec;
  }
  p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
    color: #666;
    padding: 0;
  }
  svg {
    color: #666;
    transition: all 0.3s;
    position: absolute;
    right: 1rem;
    font-size: 18px;
    top: auto;
    cursor: pointer;
    &:hover {
      color: #0f62fe;
    }
  }
}
.comments_typing_Attach_scroll {
  overflow: scroll;
  max-height: 10rem;
}
// new
.new_comments_doc_div_top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 40rem;
  transition: all 0.3s;
  // margin-bottom: 2rem;
  margin-top: 10px;
}
.new_comments_doc_div {
  text-align: center;
  align-self: flex-start;
  width: 8rem;
  margin-bottom: 5px;
  margin-right: 5px;
  .new_comments_doc_img_top_div {
    position: relative;
    transition: all 0.3s ease-in-out;
    .new_comments_doc_download_div {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5490196078);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      span {
        background-color: #1a7fdd;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          color: white;
          font-size: 23px;
        }
      }
    }
    &:hover {
      transition: all 0.3s ease-in-out;
      .new_comments_doc_download_div {
        display: flex;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .new_comments_doc_img_div {
    border: 1px solid #e4d9d9;
    padding: 10px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 6px;
    .new_comments_doc_div_img {
      width: 3rem;
      height: 3rem;
      @include bg_img;
      margin: auto;
    }
  }
  .new_comments_doc_text_div {
    border: 1px solid #e4d9d9;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 0;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-bottom: 0;
      text-transform: capitalize;
      font-size: 10px;
      margin-bottom: 0;
    }
  }
}
// new end
// Task Right Drawer Ui end
// top Search Result Data
.search_result_data_div {
  position: absolute;
  background-color: white;
  left: 0;
  // bottom: -5rem;
  margin-top: 7px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  min-height: auto;
  max-height: 22rem;
  overflow: scroll;
}
.search_result_no_data_div {
  font-size: 20px;
  font-weight: 600;
  color: #666;
  text-align: center;
  text-transform: capitalize;
}
.search_result_data_inner_top_div {
  position: relative;
  .search_result_data_inner_div {
    display: flex;
    // justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    border-radius: 6px;
    &:hover {
      background: #dde2ff;
    }
    img {
      margin-right: 1rem;
      width: 36px;
      height: 36px;
      @include bg_img;
    }
    p {
      margin-bottom: 0;
      color: #000;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.28px;
    }
  }
  .close_btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    transition: all 0.3s ease-in;
    background-color: white;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #dde2ff;
    }
    svg {
      color: #525252;
      font-size: 18px;
    }
  }
}
.new_btn_nav {
  display: flex;
  align-items: center;
  justify-content: end;
  .management_btn_outline_with_icon {
    width: auto;
    border: none;
    background-color: white;
    color: black;
  }
}
.search_result_bass_div {
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.search_result_bass_title_div {
  h3 {
    color: #252525;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }
}
.search_result_data_id {
  span {
    margin-right: 10px;
    svg {
      font-size: 18px;
      color: #000000;
    }
  }
  p {
    color: #626262 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.56px !important;
    text-transform: capitalize !important;
    margin: 0;
  }
}
// .search_result_item {
//   animation: fadeIn 0.5s ease-in-out;
// }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.search_result_div_btn {
  color: #0f62fe;
  text-align: center;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  background-color: transparent;
  margin-top: 1rem;
}
.search_result_nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  p {
    min-width: 14%;
    align-self: flex-start;
    min-width: auto;
  }
}
.chats_search_result_time_date {
  color: #949494;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.56px;
  margin-left: 10px;
}

// top Search Result Data end
.task_details_complete_btn {
  border-radius: 8px;
  border: 0.5px solid #949494;
  color: #949494;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;
  margin-right: 16px;
  background-color: transparent;
  outline: none;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  svg {
    font-size: 14px;
    margin-right: 5px;
    color: #949494;
  }
  &:hover {
    border-color: #2ba317;
    color: #2ba317;
    svg {
      color: #2ba317;
    }
  }
}
.timer_switch_div_top {
  // background: white;
  padding: 10px 13px;
  border-radius: 10px;
  margin-right: 16px;

  // hide for phone screen
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.timer_switch_div {
  display: flex;
  align-items: center;
  .switch {
    margin-right: 1.5rem;
  }
  svg {
    font-size: 18px;
    margin-right: 5px;
    color: #000000;
  }
  p {
    margin-bottom: 0;
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 1rem;
  }
  img {
    @include bg_img;
    width: 28px;
    height: 16px;
  }
  span {
    color: #000;
    text-align: right;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.task_details_complete_btn2 {
  border-color: #2ba317;
  color: #2ba317;
  svg {
    color: #2ba317;
  }
}
.task_details_user_avatar_top {
  margin-left: 25px;
}
.task_details_user_avatar {
  display: flex;
  margin-left: 5px;
  span {
    margin-left: 3px;
  }
}
.issues_top_new_div {
  display: flex;
  margin: 0;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}
.issues_top_new_btn_div {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
    svg {
      transition: all 0.3s ease-in-out;
      color: black;
      font-size: 15px;
      &:hover {
        color: #1a7fdd;
      }
    }
  }
}
.dashboard_top_week_Select {
  .css-1nmdiq5-menu {
    z-index: 20 !important;
  }
}
.Priority_dropdown {
  background-color: black;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 13px;
  border-radius: 6px;
  min-width: 5rem;
  max-width: 7rem;
  margin-bottom: 0;
  cursor: pointer;
  span {
    position: relative;
    color: white;
    text-transform: capitalize;
    font-weight: 600 !important;
    font-size: 12px !important;
    svg {
      display: none;
      position: absolute;
      top: 4px;
      right: -14px;
    }
  }
  &:hover {
    span {
      svg {
        display: block;
      }
    }
  }
}
.Priority_dropdown_item {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-weight: 550;
  text-transform: capitalize;
  svg {
    font-size: 10px;
    margin-right: 5px;
  }
}
//
.nx-confirm-button-ok {
  text-transform: capitalize !important;
  background-color: transparent !important;
  color: $blue !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 9px 20px !important;
  // width: 100% !important;
  border-radius: 6px !important;
  transition: all 0.3s !important;
  border: 1px solid $blue !important;
  // display: flex !important;
  // justify-content: center !important;
  // align-items: center !important;
  outline: none !important;
  &:hover {
    color: white !important;
    background-color: $blue !important;
    box-shadow: 0px 0px 12px rgba(81, 67, 239, 0.5) !important;
  }
}
.nx-confirm-button-cancel {
  cursor: pointer !important;
  text-align: center !important;
  text-transform: capitalize !important;
  background-color: $blue !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 10px 20px !important;
  border-radius: 6px !important;
  transition: all 0.3s !important;
  // width: 100%;
  border: none !important;
  outline: none !important;
  &:hover {
    color: white !important;
    background-color: $blue !important;
    box-shadow: 0px 0px 12px rgba(81, 67, 239, 0.5) !important;
  }
}
.notiflix-confirm-content {
  border-radius: 0 !important;
  padding: 32px !important;
}
[id^='NotiflixConfirmWrap']
  > div[class*='-content']
  > div[class*='-buttons']
  > a.nx-confirm-button-ok {
  border-radius: 6px !important;
}
[id^='NotiflixConfirmWrap']
  > div[class*='-content']
  > div[class*='-buttons']
  > a.nx-confirm-button-cancel {
  border-radius: 6px !important;
}
[id^='NotiflixConfirmWrap']
  > div[class*='-content']
  > div[class*='-head']
  > h5 {
  color: black !important;
}
.kanban_tasks_card {
  min-height: 32rem;
  max-height: 32rem;
  overflow-y: scroll;
}
// join meeting
.join_meeting_ul {
  display: flex;
  margin: 0;
  align-items: center;
  .join_meeting_title_img {
    @include bg_img;
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
  h3 {
    color: #252525;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
    margin-bottom: 0;
  }
  p {
    color: #626262;
    margin-bottom: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: capitalize;
  }
}
.join_meeting_time {
  p {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    color: #252525;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
    svg {
      color: #949494;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
.join_meeting_btn_div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-end;
  .management_btn_full {
    width: 10rem;
  }
}

.join_meeting_editor {
  .rdw-editor-toolbar {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 6px;
  }
  .rdw-editor-main {
    max-height: 10rem !important;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 6px;
  }
  .DraftEditor-root {
    height: auto;
  }
}
.join_meeting_title_div {
  .all_page_my_label_new {
    display: none;
  }
  position: relative;
  svg {
    position: absolute;
    top: 13px;
    right: 22px;
    font-size: 18px;
    cursor: pointer;
    color: hsl(0, 0%, 80%);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #ff0000;
    }
  }
}
.management_btn_full {
  &:active {
    color: white !important;
  }
  &:focus {
    color: white !important;
  }
}
.join_meeting_add_btn {
  color: #1a7fdd;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
  background-color: transparent;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  svg {
    margin-right: 3px;
    font-size: 15px;
  }
}
.join_meeting_side_line {
  background-color: #d9d9d9;
  width: 2px;
  height: 100%;
}
.join_meeting_side_line_div {
  display: flex;
}
.join_meeting_side_link_div {
  margin-left: 2rem;
  margin-top: 2rem;
  .notes_details_bottom_assigned_div {
    margin-bottom: 1.5rem;
    margin-top: 1.3rem;
    flex-direction: column;
    align-items: flex-start;
    p {
      color: #000;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.14px;
      margin-bottom: 10px;
    }
  }
}
.join_meeting_side_link_div_p {
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: 0.32px;
}
.join_meeting_side_inner_link {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  margin-bottom: 1rem;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  button {
    margin-left: 5px;
    background-color: transparent;
  }
  &:hover {
    color: #0f62fe;
    transition: all 0.3s ease-in-out;
  }
}
.join_meeting_side_user {
  margin-top: 4rem;
  width: 10.5rem;
  margin-bottom: 4rem;
  .MuiAvatarGroup-root {
    justify-content: flex-end !important;
  }
  .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    height: 35px;
    width: 35px;
    font-size: 12px;
    color: black;
    background-color: #e3e6e8;
    cursor: pointer;
  }
  .MuiAvatar-circular {
    width: 35px;
    height: 35px;
    position: unset;
  }
  .css-1xs323g-MuiAvatar-root {
    position: unset;
  }
}
.join_meeting_side_user_created_by {
  display: flex;
  align-items: baseline;
  li {
    margin-right: 10px;
  }
  h3 {
    color: #252525;
    text-transform: capitalize;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  p {
    margin-bottom: 0;
    color: #252525;
    text-transform: capitalize;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  span {
    color: #949494;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
  }
}
// join meeting end
// notes details
.notes_details_title {
  margin-bottom: 1.5rem;
}
.notes_details_user_info_div {
  display: flex;
  align-items: baseline;
  h3 {
    color: #252525;
    text-align: left;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
  p {
    color: #808080;
    font-family: Manrope;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}
.notes_details_data {
  position: relative;
  h3 {
    color: #252525;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
  p {
    color: #808080;
    text-align: justify;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.6px;
    margin-top: 0;
    margin-bottom: 5px;
    svg {
      font-size: 20px;
    }
  }
  .notes_card_top_opsiton {
    svg {
      color: #979797;
    }
  }
}
.notes_details_poll_div {
  position: relative;
  p {
    color: #252525;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  span {
    color: #949494;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.22px;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
  }
  h3 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-transform: capitalize;
  }
}
.notes_details_poll_btn_div {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  .management_btn_outline {
    width: auto;
  }
}
.notes_details_progress_div {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .notes_details_progress_number {
    margin-bottom: 0;
    color: #3d3d3d;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.32px;
    margin-right: 10px;
  }
  .notes_details_progress_title {
    color: #525252;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.1px;
    margin-bottom: 0;
    margin-bottom: 3px;
  }
  .progress {
    height: 6px;
    background-color: transparent;
    .progress-bar {
      border-radius: 10px;
    }
  }
}
// notes details end
// scheduling meeting
.scheduling_details_div {
  label {
    display: none;
  }
}
.scheduling_details_Location_div {
  position: relative;
  label {
    display: none;
  }
  svg {
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 20px;
    color: #949494;
    cursor: pointer;
  }
  input {
    padding-right: 2rem !important;
  }
}
.scheduling_details_time_select_div {
  display: flex;
  align-items: center;
  .all_user_page {
    width: 8rem;
    margin: auto;
    .css-1fdsijx-ValueContainer {
      padding: 0;
      text-align: center;
    }
    .css-1xc3v61-indicatorContainer {
      padding: 0;
      padding-right: 5px;
    }
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }
  .scheduling_details_time_select_p {
    margin-bottom: 0;
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: auto;
  }
}
.scheduling_details_time_switch_div {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    color: #808080;
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-right: 10px;
  }
  .MuiSwitch-root {
    width: 53px !important;
    height: 32px !important;
  }
}
.scheduling_details_time {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    text-transform: capitalize;
    color: #808080;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    span {
      background-color: #dadada;
      padding: 5px 25px;
      margin-right: 5px;
      border-radius: 3px;
    }
  }
}
.scheduling_details_title {
  .project_summary_title {
    h3 {
      margin-bottom: 0;
    }
  }
}
.scheduling_details_radio_title {
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: capitalize;
}
.time_zone_div {
  display: flex;
  .nav {
    width: 26% !important;
    align-items: center;
  }
}
// scheduling meeting end

// approvals
.list_link_title {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #0f62fe;
  }
}
.approval_request_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  .management_btn_outline {
    width: auto;
    margin-right: 1rem;
  }
  .management_btn_full {
    margin-left: 1rem;
  }
}
.approval_request_input {
  label {
    display: none;
  }
}
.approval_request_title {
  position: relative;
  h3 {
    color: #252525;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
  svg {
    color: black;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 1rem;
  }
  span {
    color: #1a7fdd;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 1rem;
  }
}
.received_list_modal_top_div {
  h3 {
    color: #252525;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    margin-bottom: 1.5rem;
  }
  h4 {
    color: #252525;
    text-transform: capitalize;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  ul {
    display: flex;
    align-items: baseline;
    .li_1 {
      color: #252525;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.28px;
      text-transform: capitalize;
      width: 11rem;
    }
    .li_2 {
      color: #808080;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
      text-align: right;
    }
  }
}
// .received_list_modal_timeline li:nth-last-child(1) {
//   border: none;
// }
.received_list_modal_disabled_input {
  input {
    opacity: 1;
    background-color: #f2f2f2 !important;
    color: #666666bf;
    text-transform: capitalize;
  }
}
.received_list_modal_date_time {
  color: #000;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.received_list_modal_timeline {
  margin: 0;
  margin-top: 1rem;
  li {
    border-left: 1px dashed #000000;
    padding-left: 1rem;
    position: relative;
    .received_list_modal_timeline_title_img {
      position: absolute;
      width: 24px;
      height: 24px;
      @include bg_img;
      top: -2px;
      left: -12px;
    }
    .received_list_modal_timeline_comm {
      position: relative;
      margin-bottom: 8px;
      img {
        position: absolute;
        transform: rotate(90deg);
        width: 6px;
        top: 1px;
        left: -11px;
        @include bg_img;
        // display: none;
      }
      p {
        color: #949494;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-bottom: 0;
        padding-left: 10px;
        span {
          color: #949494;
          font-family: Inter;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.4px;
        }
      }
    }

    h3 {
      color: #252525;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.28px;
      padding-left: 10px;
      span {
        color: #626262;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
      }
    }
  }
}
.rec_list_tb {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.rec_list_mid {
  border-right: 1px solid #d9d9d9;
}
.received_list_modal_timeline_uper_div {
  padding-left: 1rem;
  overflow: scroll;
  max-height: 43rem;
}
.approval_request_btn2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  .management_btn_outline {
    width: auto;
    margin-right: 0;
    color: #ff3e48;
    border-color: #ff3e48;
    &:hover {
      background-color: white;
    }
  }
  .management_btn_full {
    margin-left: 1rem;
  }
}
.approval_request_btn3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  .management_btn_outline {
    width: auto;
    margin-right: 1rem;
  }
}
.modal-content {
  border: none !important;
}
// approvals end
// new notes
.new_note_box_div {
  position: relative;
  border: 3px solid #f0dfac;
  border-radius: 12px;
  padding: 1rem;
  min-height: 270px;
  max-height: 270px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    h3 {
      color: #1a7fdd;
      transition: all 0.3s ease-in-out;
    }
  }
  .new_note_box_project_title {
    margin-bottom: 0;
    border-radius: 4px;
    border: 1px solid #1a7fdd;
    background: rgba(55, 134, 238, 0.08);
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    align-items: center;
    display: math;
    padding: 8px 8px;
    img {
      width: 16px;
      height: 16px;
      @include bg_img;
      margin-right: 5px;
    }
  }
  h3 {
    margin-top: 16px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    color: #252525;
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    cursor: pointer;
  }
  ul {
    margin-bottom: 0;
    li {
      color: #626262;
      display: flex;
      align-items: baseline;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.24px;
      margin-bottom: 5px;
      svg {
        font-size: 13px;
        margin-right: 5px;
        color: #626262;
      }
    }
  }
}
.new_note_box_user_icon_div {
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
    margin-bottom: 0;
    text-transform: capitalize;
    color: #949494;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
  }
}
.new_note_box_user_day_time {
  margin-bottom: 0;
  text-transform: capitalize;
  color: #949494;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 6px;
  span {
    text-transform: uppercase;
  }
}
.new_note_box_menu_div {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.new_note_box_user_bottom_div {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}
.assign_to_modal_ui {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
}
.notes_details_add {
  display: flex;
  align-items: center;
  border: 2px solid #f0dfac;
  border-radius: 18px;
  padding: 10px;
  margin-bottom: 1.5rem;
  svg {
    font-size: 15px;
  }
  .form-control:focus {
    border-color: transparent;
    box-shadow: none !important;
    // padding-top: 7px !important;
  }
  input {
    border: none;
    // padding-top: 10px !important;
    &::placeholder {
      color: #0a0e30 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
  }
}
.notes_details_add_todo {
  display: flex;
  align-items: baseline;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  position: relative;
  .notes_details_add_todo_dot {
    margin-right: 5px;
    svg {
      color: #dde2ff;
      font-size: 20px;
    }
  }
}
.notes_details_add_todo_text {
  color: #0a0e30;
  margin-bottom: 0;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.16px;
  padding-right: 10px;
}
.notes_details_add_todo_dropdown {
  color: #1a7fdd;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  .btn_icon {
    font-size: 12px;
    margin-right: 6px;
  }
  span {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
  }
  .Priority_dropdown {
    width: 6rem !important;
    max-width: unset;
    min-width: unset;
  }
}
.notes_details_project_title_div {
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    @include bg_img;
    margin-right: 5px;
  }
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    color: black;
  }
}
.notes_details_edit_title_div {
  display: flex;
  justify-content: flex-end;
}
.notes_details_bottom_btn {
  color: #1a7fdd;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  margin-bottom: 0;
  cursor: pointer;
  img {
    width: 17px;
    margin-right: 6px;
  }
}
.notes_details_bottom_btn_div {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .notes_details_bottom_btn_div_inner {
    margin-right: 1.5rem;
  }
}
.notes_details_bottom_btn_div_inner_top_div {
  display: flex;
  align-items: center;
}
.notes_details_bottom_assigned_div {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    color: #626262;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.1px;
    margin-right: 10px;
  }
}
.new_note_box_menu_div2 {
  position: absolute;
  top: 13px;
  right: 3px;
  color: #626262;
  cursor: pointer;
  font-size: 19px !important;
}
.notes_details_title_div {
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
  }
  p {
    margin-bottom: 0;
    color: #252525;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}
.note_date {
  margin-bottom: 0;
  color: #666666ba !important;
}
.management_btn_top_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .management_btn_outline {
    width: auto;
  }
  .mobile_btn {
    margin: 0 16px;
  }
  .management_btn_full {
    text-align: center;
  }
  .completed_btn {
    background-color: #f4f4f4 !important;
    color: #000000 !important;
    cursor: no-drop;
    svg {
      color: #2ba317 !important;
    }
    &:hover {
      box-shadow: none;
    }
  }
}
.warning_note_div {
  border-radius: 16px;
  border: 1px solid #fff3e5;
  background: #fff3e5;
  padding: 1rem;
  margin-bottom: 1.5rem;
  h3 {
    color: #ff3e48;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    img {
      @include bg_img;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
  p {
    color: #0a0e30;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.16px;
    margin-bottom: 0;
  }
}
.mark_completed_modal_div {
  text-align: center;
  img {
    @include bg_img;
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
  }
  h3 {
    color: #ff3e48;
    text-align: center;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  p {
    color: #49454f;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
  }
  .mark_completed_modal_fild {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .mark_completed_modal_btn_div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      text-transform: capitalize;
    }
  }
}
// new notes end

.user_card_div_inner_timer {
  h5 {
    color: #626262;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.user_card_div_inner_timer_timer_div {
  margin: 0;
  position: absolute;
  right: 20px;
  p {
    color: black;
    text-align: right;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  svg {
    font-size: 16px;
    margin-right: 5px;
    color: #000000;
  }
  img {
    width: 28px;
    height: 16px;
    @include bg_img;
  }
  span {
    color: #000;
    text-align: right;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
//
// profile
.setting_profile_img_div {
  display: flex;
  align-items: center;
  img {
    width: 8rem;
    height: 8rem;
    @include bg_img;
    border-radius: 100%;
    margin-right: 1rem;
  }
  h3 {
    color: #000;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 127.273% */
    letter-spacing: 1.32px;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 0;
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.035px;
    text-transform: capitalize;
  }
}
.setting_profile_text_div {
  p {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.7);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.06px;
  }
  h3 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.96px;
    word-break: break-all;
  }
}
.setting_profile_edit_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.received_list_modal_disabled_input2 {
  input {
    opacity: 1;
    background-color: #f2f2f2 !important;
    color: #666666bf;
  }
}
.setting_profile_save_btn {
  display: flex;
  align-items: flex-start;
  .management_btn_outline {
    width: auto;
    margin-right: 10px;
  }
  .management_btn_full {
    margin-left: 10px;
  }
}
.setting_profile_edit_img_div {
  position: relative;
  width: 8rem;
  height: 8rem;
  margin-right: 1rem;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    .setting_profile_edit_inner_img_div {
      display: flex;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.setting_profile_edit_inner_img_div {
  width: 8rem;
  height: 8rem;
  background-color: #0000006e;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  svg {
    color: white;
    font-size: 30px;
  }
}
// profile end
// meeting detail
.meeting_detail_new_title {
  color: #000;
  font-family: Manrope;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 128.571% */
  margin-bottom: 24px;
}
.meeting_detail_new_sub_title {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.07px;
  margin-bottom: 0;
}
.meeting_detail_new_Project_title {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
  margin-bottom: 24px;
}
.meeting_detail_new_desc {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.meeting_detail_new_add_notes_btn {
  color: #0f62fe;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  svg {
    font-size: 18px;
    margin-right: 2px;
  }
}
.join_meeting_sub_time {
  span {
    color: rgba(0, 0, 0, 0.5);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.07px;
    margin-right: 5px;
  }
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.07px;
}
.join_meeting_sub_time_div {
  margin-left: 27px;
}
.attendee_add_btn {
  color: #0f62fe !important;
  font-family: Manrope;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px; /* 142.857% */
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.edit_btn_meeting {
  svg {
    color: #000;
    font-size: 24px;
  }
}
.edit_btn_meeting_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
// meeting detail end
.add_sub_task_date_new {
  label {
    display: none;
  }
  input {
    width: 100%;
  }
}
.add_sub_task_date_new_div {
  display: flex;
  align-items: center;
}
.sub_task_edit_title {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px 10px 8px 15px;
  p {
    color: #212529;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }
}
.add_sub_task_date_new_icon_div {
  svg {
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}

// new sub Task
.new_sub_task_add_btn {
  background-color: rgba(15, 98, 254, 0.08) !important;
  box-shadow: none !important;
  color: #1a7fdd !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.new_sub_task_title {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 171.429% */
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.new_sub_task_title_row {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #d7d6d6;
  background: #f8f8fa;
}
.new_sub_task_title_col {
  display: flex;
  align-items: center;
}
.new_sub_task_title_col2 {
  justify-content: flex-end;
}
.new_sub_task_top_div {
  padding-left: 10px;
  padding-right: 15px;
}
.new_sub_task_list_top_div {
  border: 1px solid #d7d6d6;
  background: #fff;
  border-top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.new_sub_task_list_date {
  color: rgba(0, 0, 0, 0.8);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.new_sub_task_list_title_div {
  p {
    margin: 0;
    color: #000;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.5px;
  }
}
.new_sub_task_top_div_btn {
  color: #000 !important;
  font-family: Manrope;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize !important;
  letter-spacing: 0.5px;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
}
.new_sub_task_top_div_btn2 {
  color: #000 !important;
  font-family: Manrope;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;
  text-transform: capitalize !important;
  svg {
    font-size: 15px;
    margin-right: 5px;
  }
}
.new_sub_task_top_div_btn3 {
  color: #1a7fdd !important;
  font-family: Manrope;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;
  text-transform: capitalize !important;
  svg {
    font-size: 15px;
    margin-right: 5px;
  }
}
.new_sub_task_outer_btn_div {
  text-align: end;
  margin-bottom: 1rem;
}
.new_sub_task_list_checkbox {
  display: flex;
  align-items: center;
  span {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.new_sub_task_action_btn {
  font-size: 24px;
  color: #808090;
  cursor: pointer;
}
.new_sub_task_action_inner_btn {
  svg {
    font-size: 18px;
    margin-right: 5px;
  }
}
.new_sub_task_list_title_bottom_div {
  color: rgba(0, 0, 0, 0.8);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.new_sub_task_list_badge {
  border-radius: 4px;
  background: #f0f0f0;
  color: #1c1b1f;
  font-family: Manrope;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 200% */
  letter-spacing: 0.01px;
  text-transform: capitalize;
  margin-right: 5px;
  padding: 2px 10px;
  text-align: center;
}
.new_sub_task_list_badge2 {
  color: #3ba317;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.014px;
  border-radius: 4px;
  background: rgba(59, 163, 23, 0.15);
  padding: 8px;
  text-transform: capitalize;
  margin-top: 8px;
}
.new_sub_task_list_item_edite {
  display: flex;
  align-items: center;
  label {
    margin-left: 0;
  }
  input {
    margin-right: 16px;
  }
  .input1 {
    input {
      width: 20rem;
    }
  }
  .input2 {
    input {
      width: 8rem;
    }
  }
}
.new_sub_task_list_item_edite_btn {
  display: flex;
  margin-top: 27px;
}
.new_sub_task_list_scroll_div {
  overflow: scroll;
  width: 100%;
  max-height: 40rem;
  min-height: auto;
}
.new_sub_task_add_ui {
  label {
    margin-left: 0;
  }
}
.new_sub_task_add_ui_box {
  label {
    font-size: 14px;
    font-weight: 700;
  }
}
.approval_modal_title {
  color: #000;
  font-family: Manrope;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.approval_modal_sub_title_div {
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    li {
      color: rgba(0, 0, 0, 0.75);
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        border-radius: 2px;
        background: black;
        color: #fff;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        padding: 3px 15px;
        text-align: center;
        border-radius: 3px;
        margin-left: 4px;
      }
    }
    .li_first {
      width: 12rem;
    }
  }
}
.approval_modal_sub_task_title {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.approval_modal_sub_task_date_title {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.approval_modal_sub_list_task_title {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}
.approval_modal_sub_task_list_date_title {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;
}
.approval_modal_user_div {
  label {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
}
// new sub Task end
// add subtask in create task
.add_new_subtask_ui_title {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.add_new_subtask_upload_card_ui {
  p {
    margin-bottom: 0 !important;
  }
}

.add_new_subtask_main_ui {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;
  .add_new_subtask_main_ui_input_div {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    transition: all 0.3s ease-in-out;
    svg {
      font-size: 17px;
      margin-right: 8px;
      transition: all 0.3s ease-in-out;
    }
    input {
      width: 28rem;
      border: none;
      transition: all 0.3s ease-in-out;
      border: 1px solid hsl(0deg, 0%, 80%) !important;
      height: 39px;
    }
  }
}
.add_new_subtask_top_ui_inner_div {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.add_new_subtask_uploader_div {
  transition: all 0.3s ease-in-out;

  label {
    border: 1px solid #e5e5ea;
    width: 33px;
    height: 36px;
    display: none;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 20px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 8px;
    transition: all 0.3s ease-in-out;
    background-color: white;
  }
}
.add_new_subtask_top_ui_div {
  position: relative;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 8px;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
}

.add_new_subtask_Uploaded_img_div {
  // border: 1px solid;
  border-radius: 6px;
  width: 82px;
  height: 86px;
  position: relative;
  transition: all 0.3s ease-in-out;
  // display: inline-block;
  img {
    width: 82px;
    height: 4rem;
    @include bg_img;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  p {
    background-color: #eee7e7;
    color: #000 !important;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 9px !important;
    font-weight: 600 !important;
    margin-bottom: 0;
    padding: 3px !important;
    text-transform: capitalize;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
  }
  .close_btn {
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: -2px;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    svg {
      color: white;
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    transition: all 0.3s ease-in-out;
    .add_new_subtask_Uploaded_img_div_overlay {
      display: flex;
      transition: all 0.3s ease-in-out;
    }
  }
}
.add_new_subtask_Uploaded_img_div_overlay {
  background-color: #00000061;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: none;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.modal_img_view_btn_div {
  display: flex;
}
.modal_img_view_btn {
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 20px;
  border-radius: 3px;
  cursor: pointer;
  svg {
    color: #1a7fdd;
    font-size: 15px;
  }
}
.add_new_subtask_Uploaded_mai_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  .item_new2 {
    margin-right: -32px !important;
  }
  .item_new3 {
    margin-right: -10px !important;
  }
  .item_new4 {
    margin-right: -6px !important;
  }
  .item_new5 {
    margin-right: -10px !important;
  }
  .item {
    flex-direction: row;
    min-width: 12%;
    align-self: flex-start;
    margin-top: 15px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
  }
  .item2 {
    margin-right: 4px;
  }
}
.add_new_subtask_top_ui_btn_div {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  .btn1 {
    border: 0.5px solid rgba(0, 0, 0, 0.15) !important;
    color: rgba(0, 0, 0, 0.7) !important;
    margin-right: 10px !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    background-color: #ffffff;
  }
  .btn2 {
    background: #1a7fdd !important;
    color: #fff !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
  }
}
.rs-modal-wrapper {
  display: flex;
  align-items: center;
}
.add_new_subtask_data_top_ui {
  background-color: transparent;
  padding: 10px;
  position: relative;
  // border-radius: 6px;
  border-top: 1px solid #e5e5ea;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    background-color: #f7f8f9;
  }
  .show_btn {
    // display: block;
    transition: all 0.3s ease-in-out;
    background-color: white;
    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: #f7f8f9;
    }
    svg {
      margin-left: 5px;
    }
  }
  &:hover {
    .show_btn {
      display: block;
      transition: all 0.3s ease-in-out;
    }
  }
}
.add_new_subtask_text_view {
  margin-bottom: 0;
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 25rem;
  transition: all 0.3s ease-in-out;
}
.add_new_subtask_date_picker_show_div {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  // border: 1px solid #e5e5ea;
  height: 36px;
  width: 110px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  svg {
    width: 16px;
    margin-right: 5px;
  }
}
.add_new_subtask_text_view_div {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.add_new_subtask_data_top_title_ui {
  color: rgba(0, 0, 0, 0.6);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  padding-left: 5px;
  padding: 8px;
}
.add_new_subtask_data_top_title_ui2 {
  color: rgba(0, 0, 0, 0.6);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-align: right;
  padding-right: 10px;
  padding: 8px;
}
.approval_modal_sub_title_p {
  color: #252525;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  margin-bottom: 24px;
}
.approval_modal_sub_title_Description_top_div {
  .accordion-item {
    border: none !important;
    margin-top: 1rem;
  }
  .accordion-button {
    padding: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      background: #e7f1ff;
    }
  }
  .accordion-body {
    padding: 0;
  }
  .accordion-button:focus {
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('../../pics/Management/downp.svg');
  }
  .accordion-button {
    &::after {
      background-image: url('../../pics/Management/downp.svg');
    }
  }
}
.approval_modal_sub_title_Description_top_div2 {
  margin-top: 1rem;
  .MuiButtonBase-root {
    padding: 0 !important;
    min-height: 0 !important;
  }
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
  box-shadow: none !important;
  .pm_icon {
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    color: black;
    font-size: 25px;
    &:hover {
      transition: all 0.3s ease-in-out;
      background: #e7f1ff;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg);
  }
  .Mui-expanded {
    transform: rotate(180deg);
  }
}
.approval_modal_sub_title_Description_div {
  display: flex;
  align-items: center;
  .approval_modal_sub_title_Description {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 8px;
    margin-top: 2rem;
  }
}
.approval_modal_sub_title_Description_new {
  color: #000;
  font-family: Manrope;
  background: #eeeff1;
  font-size: 14px;
  font-weight: 400;
  padding: 3px 10px;
  border-radius: 6px;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
  margin-bottom: 8px;
  margin-top: 32px;
}
.approval_modal_top_title_div {
  display: flex;
  margin: 0;
  img {
    width: 47.998px;
    height: 47.998px;
    @include bg_img;
  }
  p {
    margin-bottom: 0;
    color: #252525;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 127.273% */
    margin-bottom: -4px;
    margin-left: 16px;
  }
  span {
    color: #0a0e30;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.048px;
    margin-left: 16px;
  }
  li {
    div {
      color: #1a7fdd;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.014px;
      border-radius: 4px;
      background: #dde2ff;
      padding: 8px;
      text-transform: capitalize;
      margin-left: 10px;
    }
  }
}
// add subtask in create task end
// new_task_detail_sub_taks
.new_task_detail_sub_taks {
  .task_icon {
    height: 16px;
    width: 16px;
    @include bg_img;
    margin-right: 8px;
  }
  .add_new_subtask_text_view {
    width: 21.3rem;
  }
}
.new_task_detail_inner_sub_taks {
  display: flex;
  justify-content: center;
  align-items: baseline;
  // width: 40px !important;
  .MuiFormControlLabel-root {
    margin-left: -5px !important;
    margin-right: 5px !important;
  }
  .MuiButtonBase-root {
    padding-top: 6px !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }
}
.new_task_detail_top_title_two {
  padding: 8px;
  text-align: right;
  .small {
    color: rgba(0, 0, 0, 0.6);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    padding-right: 20px;
  }
}
.new_task_detail_top_title_one {
  padding: 8px;
  .MuiButtonBase-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 23px !important;
    padding-right: 0 !important;
  }
  .small {
    color: rgba(0, 0, 0, 0.6);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    padding-left: 5px;
  }
}
.rs-modal-wrapper {
  z-index: 20000 !important;
}
.sub_task_modul_title_div {
  color: #252525 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-transform: capitalize;
}
.sub_task_modul_img_div {
  @include bg_img;
  width: 100%;
  max-height: 35rem;
  border-radius: 6px;
  border: 1px solid #000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.sub_task_modul_body_div {
  padding-bottom: 0 !important;
  margin-top: 0px !important;
  position: relative;
}
.sub_task_modul_top_close_btn_div {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.sub_task_modul_download_btn {
  &:focus {
    color: white !important;
  }
}
.feedback_modal_ul {
  margin: 0;
  margin-top: 1rem;
  list-style-type: none;
  padding-left: 50px !important;
  li {
    border-left: 1px dashed #000000;
    padding-left: 20px;
    position: relative;
  }
  .feedback_modal_ul_icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -2px;
    left: -12px;
    border-radius: 100%;
  }
  .feedback_modal_ul_requested {
    border: 1px solid #1a7fdd;
    background-color: #e8f2fc;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    padding: 5px 10px;
    border-radius: 6px;
  }
  .feedback_modal_ul_name {
    color: #252525;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-bottom: 0;
    margin-top: 10px;
    text-transform: capitalize;

    span {
      color: #626262;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
    }
  }
  .feedback_modal_ul_commant {
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    margin-top: 10px;
    img {
      width: 16px;
      height: 16px;
      @include bg_img;
      margin-right: 5px;
    }
    span {
      color: #000;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.5px;
    }
  }
  .feedback_modal_ul_commant_coyp_btn {
    position: absolute;
    top: 0;
    right: 4rem;
    font-size: 20px;
    cursor: pointer;
  }
  .add_new_subtask_Uploaded_mai_div {
    width: 23rem;
  }
  .feedback_modal_ul_time {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 0;
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    padding-bottom: 1rem;
    svg {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .feedback_modal_ul_main_div {
    margin-bottom: 10px;
  }
}
.add_new_subtask_top_btn_div {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: end;
  align-items: center;
  label {
    width: 33px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 20px;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;
    background-color: #f2f2f2;
  }
}
.add_new_subtask_requested_menu_dropdown {
  .task_icon {
    margin-right: 7px;
  }
  .Priority_dropdown {
    width: 7rem !important;
    height: 40px;
  }
}
.add_new_subtask_requested_menu_item {
  img {
    width: 16px;
    height: 16px;
    @include bg_img;
    margin-right: 8px;
  }
}
.add_new_subtask_menu_div_top {
  display: flex;
  align-items: center;
}
.add_new_subtask_menu_div_top2 {
  display: flex;
  align-items: center;
}
.task_detail_top_main_div {
  border-top: 1px solid #e5e5ea;
  transition: all 0.3s ease-in-out;
  background-color: white;
  padding: 16px;
  .my_checkbox {
    transition: all 0.3s ease-in-out;
    display: none;
  }
  .my_checkbox_icon {
    display: block;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: #f7f8f9;
    transition: all 0.3s ease-in-out;
    .my_checkbox {
      transition: all 0.3s ease-in-out;
      display: block;
    }
    .my_checkbox_icon {
      display: none;
      transition: all 0.3s ease-in-out;
    }
    .task_detail_main_btn_div {
      transition: all 0.3s ease-in-out;
      .show_btn {
        transition: all 0.3s ease-in-out;
        display: flex;
      }
    }
  }
}
.task_detail_text_div {
  display: flex;
  align-items: baseline;
  position: relative;
  margin-left: -13px;
  transition: all 0.3s ease-in-out;

  img {
    height: 18px;
    width: 18px;
    @include bg_img;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;
  }
  p {
    margin-bottom: 0;
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
    transition: all 0.3s ease-in-out;
    padding-top: 3px;
  }
  input {
    width: 30rem;
    transition: all 0.3s ease-in-out;
  }
}
.task_detail_main_btn_div {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  .show_btn {
    display: none;
  }
  label {
    border: 1px solid #e5e5ea;
    width: 33px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 20px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 8px;
    transition: all 0.3s ease-in-out;
    background-color: white;

    &:hover {
      background-color: #f7f8f9;
      transition: all 0.3s ease-in-out;
    }
  }
}
.task_detail_chakbox {
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: flex-start;
  .MuiButtonBase-root {
    padding-top: 0px !important;
    padding-left: 14px !important;
  }
  .task_icon {
    width: 19.997px;
    height: 19.992px;
    @include bg_img;
    margin-left: 5px;
    margin-top: 4px;

    // margin-top: 8px;
  }
}
.task_detail_top_mani_div {
  display: flex;
  align-items: baseline;
  position: relative;
  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
  .task_detail_chakbox {
    position: absolute;
  }
}
.task_detail_top_inner_div {
  margin-left: 52px;
  width: 100%;
}
.task_detail_text_div_top_col {
  display: flex;
  align-items: center;
}
// new_task_detail_sub_taks end
// approval send lists page
.send_list_page_detail_div {
  .my_title {
    color: #252525;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
  }
  .sub_title_div {
    p {
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.5);
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 200% */
      letter-spacing: 0.025px;
    }
    div {
      color: #000;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      letter-spacing: 0.04px;
      label {
        cursor: pointer;
        svg {
          font-size: 13px;
          margin-left: 5px;
        }
      }
    }
    span {
      color: #000;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.03px;
      border-radius: 4px;
      text-transform: capitalize;
      padding: 3px 10px;
    }
  }
}
.send_list_page_detai_approval_status_title {
  color: #252525;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  span {
    color: #1a7fdd;
  }
}
.task_date_div {
  display: flex;
  justify-content: end;
  p {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.035px;
    margin-bottom: 0;
  }
}
.task_date_div_rel {
  position: relative;
}
.task_date_div_rel2 {
  position: absolute;
  top: -6px;
}
.task_date_div_rel_new {
  width: 6rem;
  margin-left: -38px;
}
.create_task_date_piker {
  display: flex;
  .input_one {
    margin-right: 16px;
    margin-left: 7px;
    margin-bottom: 28px;
  }
}
.new_task_detail_div {
  .inner_edite_input {
    display: flex;
    align-items: center;
  }
  .sub_task_checkbox {
    display: none;
  }
  &:hover {
    .sub_task_checkbox {
      display: block;
    }
    .task_detail_chakbox_new {
      .task_icon {
        display: none;
      }
    }
  }
}
.task_scrol_div4 {
  overflow: scroll;
  min-height: auto;
  max-height: 25rem;
}
.task_detail_chakbox_new {
  position: relative;
  .task_icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 9px;
    left: 6px;
    @include bg_img;
  }
  .MuiButtonBase-root {
    padding-top: 7px !important;
    padding-left: 14px !important;
  }
  .sub_task_checkbox {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.create_task_title_modal {
  display: inline-block;
  background: white;
  margin-left: 0;
  padding: 0;
  font-weight: 600;
}
.create_task_date_piker_new {
  label {
    display: none;
  }
  .input_one {
    margin-bottom: 0;
    margin-left: 16px;
  }
}
.new_start_date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.end_start_date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.end_and_start_date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
}
.new_start_date2 {
  justify-content: flex-end;
  margin-right: 32px;
}
.end_start_date2 {
  justify-content: flex-end;
  margin-right: 42px;
}
.add_new_subtask_text_view_div_new {
  position: absolute;
  top: -4px;
  right: -50px;
}
.sub_task_delete_btn {
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    background: rgba(209, 36, 47, 0.05) !important;
    svg {
      color: #d1242f;
    }
  }
}
.add_new_subtask_pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.delete_modal_sub_task {
  .rs-modal-dialog {
    width: 20rem;
  }
}
// approval send lists page end
// new
.Reassign_Modal_New_div {
  .modal-content {
    padding: 20px;
  }
}
.new_editer {
  .ql-editor {
    min-height: 20rem !important;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
.feed_back_icon_ui {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 5rem;
    height: 5rem;
    margin: auto;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 400;
  }
}
// New fiFilters one
.new_filters_one_main_div_top {
  .MuiPaper-root {
    margin-top: 81px !important;
  }
}
.new_filters_one_main_div {
  background-color: var(--element-bg);
  width: 344px;
  height: 100vh;
  .top_title_and_close_btn {
    padding: 16px;
    position: relative;
    h3 {
      color: var(--first-text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.15px;
      margin-bottom: 0;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 24px;
      border-radius: 6px;
      transition: all 0.3s ease-in-out;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
      &:hover {
        background-color: var(--search-box-data-hover);
      }
      svg {
        font-size: 16px;
        color: var(--icon-btn-text-color);
      }
    }
  }
  .inner_div {
    padding: 16px;
    border-top: 1px solid var(--search-box-border-color);
  }
  .new_filters_bottom_btn {
    margin-top: 1.5rem;
    .btn_1 {
      padding: 0.375rem 0.75rem;
      color: #fff;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      letter-spacing: -0.00938rem;
      text-transform: capitalize;
      background: #3656f4;
      margin-right: 0.5rem;
    }
    .btn_2 {
      padding: 0.375rem 0.75rem;
      color: #24292f;
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      letter-spacing: -0.00938rem;
      border: 1px solid #d0d7de;
      background: #f6f8fa;
      height: 32px;
    }
  }
}
// New fiFilters one end
// .ql-font-monospace {
//   font-family: 'monospace' !important;
// }
.top_offer_div {
  position: relative;
  width: 100%;
  height: 32px;
  .bg_img {
    object-fit: cover;
    @include bg_img;
    width: 100%;
    height: 32px;
  }
  .inner_data {
    width: 100%;
    height: 32px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    img {
      @include bg_img;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    a {
      color: #00f562;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      text-decoration-line: underline !important;
    }
    b {
      font-weight: 700;
      margin: 0px 3px;
    }
  }
  .close_btn {
    position: absolute;
    top: 8px;
    right: 3rem;
    background-color: #fff;
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    svg {
      color: #1f2328;
      width: 12px;
      height: 12px;
    }
  }
}
//
.MuiDrawer-root {
  z-index: 1000 !important;
}
