@import '../../../../scss/--var.scss';
@import '../../../../scss/--mixing.scss';
.search_box_div {
  position: relative;
  width: 200px;
  input {
    padding-left: 34px;
    border-radius: 0.375rem;
    border: 1px solid var(--search-box-border-color);
    background: var(--element-bg);
    width: 200px;
    height: 40px;
    color: var(--search-box-text-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
  }
  .input_icon {
    padding: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    left: 6px;
    svg {
      font-size: 18px;
      color: var(--search-box-text-color);
    }
  }
  .no_data {
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 550;
    color: var(--first-text-color);
    margin-bottom: 0;
  }
  .search_result_div {
    background: var(--element-bg);
    position: absolute;
    right: 0;
    top: 43px;
    z-index: 3;
    border-radius: 6px;
    width: 20rem;
    min-height: auto;
    max-height: 23rem;
    overflow: scroll;
    box-shadow: var(--element-bg-box-shadow);
    .search_result_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      color: var(--first-text-color);
      font-size: 15px;
      font-weight: 400;
      text-transform: capitalize;
      margin: 0 0.5rem;
      border-radius: 6px;
      margin-bottom: 0.5rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--search-box-data-hover);
      }
      span {
        margin-right: 0.5rem;
        background-color: var(--element-bg);
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid var(--search-box-border-color);
        svg {
          font-size: 15px;
          color: var(--first-text-color);
        }
      }
    }
  }
}
