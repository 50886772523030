@import '../../../../scss/--mixing.scss';
@import '../../../../scss/--var.scss';
.timesheet_profile_card_div {
  background-color: var(--element-bg2);
  padding: 1rem;
  border-radius: 6px 6px 0 0;
  border: 1px solid var(--search-box-border-color);
  .inner_card_div_1 {
    div {
      display: flex;
      align-items: center;
      margin-bottom: -2px;
      img {
        @include bg_img;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
      }
      h3 {
        color: var(--first-text-color);
        text-transform: capitalize;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0;
        margin-left: 0.5rem;
      }
    }
    span {
      color: var(--first-text-color);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      margin-left: 41px;
    }
  }
  .inner_card_div_2 {
    div {
      p {
        margin-bottom: 0;
        color: var(--first-text-color);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        span {
          margin-left: 0.31rem;
          cursor: pointer;
          svg {
            width: 1rem;
            height: 1rem;
            color: var(--first-text-color);
          }
        }
      }
      h3 {
        color: var(--first-text-color);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }
      small {
        color: var(--first-text-color);
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
.card_total_time_tooltip {
  background-color: black;
  width: 15rem;
  padding: 5px 0px;
  hr {
    color: #30363d;
    opacity: 1;
    margin: 5px 0;
    margin-bottom: 10px;
  }
  .line_div {
    margin-top: 3px;
    border-left: 3px solid #3656f4;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
  }
  .li_1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    div {
      color: #fff;
      text-transform: capitalize;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .li_2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    div {
      span {
        border-radius: 3px;
        padding: 0.125rem 0.25rem;
        background-color: #30363d;
        color: #fff;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.card_total_variance_tooltip {
  width: 100%;
  ul {
    display: flex;
    align-items: baseline;
    width: 100%;

    .li_1 {
      svg {
        color: #fff;
        margin-bottom: 2px;
      }
    }
    .li_2 {
      color: #fff;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
