@import "../../../../scss/--var.scss";
@import "../../../../scss/--mixing.scss";

.navbar_container {
  background-color: white;
  --nav_width: 14rem;
  height: 100%;
  transition: transform 300ms ease-in-out;

  @media screen and (max-width: 1024px) {
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
  }

  @media screen and (max-width: 18rem) {
    width: 100vw;
  }
}

.navbar_mobile_active {
  @media screen and (max-width: 1024px) {
    transform: translateX(0);
  }
}

nav {
  position: relative;
  width: var(--nav_width);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: width linear 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }
}

.logo {
  padding: 0 0.35rem;
  font-size: 2.2rem;
  display: flex;
  .logo_icon {
    color: var(--color_nav_active);
  }

  .mobile_cancel_icon {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .logo_icon {
      display: none;
    }

    .mobile_cancel_icon {
      display: block;
      cursor: pointer;
      font-size: 2.2rem;
      padding: 0.2rem;
      color: var(--color_nav_active);
    }
  }
}

.menu_container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  height: 30rem;
}

.categories {
  color: var(--color_nav_txt);
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: var(--margin-xxs);

  svg {
    font-size: 1rem;
  }
}

.second_category {
  margin-top: 3rem;
}

.li_navlink a {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 5px 8px 15px;
  text-decoration: none;
  color: $c2;
  transition: all ease-in-out 0.2s;
  background-color: white;
  border-left: 3px solid white;
  font-size: 12px;
  span {
    margin-left: 1rem;
    svg {
      margin-right: 5px;
    }
  }
  .description {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 14px;
    transition: opacity 200ms ease;
    text-transform: capitalize;
  }

  svg {
    font-size: 18px;
    color: var(--color_nav_not_active);
    flex-shrink: 0;
  }

  &:hover {
    background: $c1;
    border-left: 3px solid $c2;
    color: $c2;
  }

  &:hover svg {
    color: var(--color_nav_active);
  }
}
.test {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 5px 8px 15px;
  text-decoration: none;
  color: $c2;
  transition: all ease-in-out 0.2s;
  background-color: white;
  cursor: pointer;
  border-left: 3px solid white;

  .description {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 15px;
    transition: opacity 200ms ease;
    text-transform: capitalize;
  }

  svg {
    font-size: 13px;
    color: var(--color_nav_not_active);
    flex-shrink: 0;
  }

  &:hover {
    background: $c1;
    border-left: 3px solid $c1;
    color: $c2;
  }

  &:hover svg {
    color: var(--color_nav_active);
  }
}

.li_navlink .active {
  background: $c1;
  border-left: 3px solid $c2;
}

.btn_logout {
  position: absolute;
  right: 12%;
  bottom: 2%;

  svg {
    font-size: 1.5rem;
    color: var(--color_nav_active);
    cursor: pointer;
    transform: scaleX(-1);
  }
}

.mobile_nav_background_active {
  width: 0;
  height: 100vh;
  transition: all ease-out 500ms;
  transition-delay: 300ms;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    left: calc(var(--nav_width) + var(--padding-md));
    width: calc(100vw - var(--nav_width) - var(--padding-md));
    background: rgba(0, 0, 0, 0.185);
    z-index: -1;
  }
}
.EmployeesAttendanceList_main_div {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

//NAVIGATION SMALL
.nav_small {
  width: 3rem;
  .li_navlink a {
    span {
      margin-left: 0;
      label {
        display: none;
      }
    }
  }
  .categories {
    padding: 0 1rem;
  }
  .description {
    opacity: 0;
  }

  .btn_logout {
    svg {
      transform: scaleX(1);
    }
  }
}
// media queries
@media (max-width: 576px) {
  .menu_container {
    height: 33rem;
  }
}
@media (min-width: 768px) {
  .menu_container {
    height: 35rem;
  }
}
@media (min-width: 1024px) {
  .menu_container {
    height: 40rem;
  }
}
@media (min-width: 1440px) {
  .nav_small {
    .EmployeesAttendanceList_main_div {
      display: inline-block !important;
    }
  }
}
@media (min-width: 1700px) {
}
