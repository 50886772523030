@import '../--mixing.scss';
@import '../--var.scss';
@media (max-width: 320px) {
  .task_right_drawer_ui_main_div {
    width: 19rem !important;
  }
}
@media (max-width: 576px) {
  .top_offer_div {
    display: none;
  }
  .new_filters_one_main_div {
    width: 100%;
  }

  .delete_modal_sub_task {
    .rs-modal-dialog {
      width: 100%;
    }
  }
  .create_task_date_piker_new2 {
    padding: 0 10px;

    label {
      display: block !important;
      margin-top: 5px;
    }
  }
  .task_scrol_div3 {
    width: 58rem !important;
  }
  .add_new_subtask_menu_div_top2 {
    margin-top: 1rem;
  }
  .add_new_subtask_menu_div_top {
    flex-direction: column;
    align-items: flex-start;
  }
  .task_detail_text_div {
    input {
      width: 39rem;
      transition: all 0.3s ease-in-out;
    }
  }
  .add_new_subtask_main_ui_new {
    position: relative;
    .add_new_subtask_top_ui_inner_div {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .create_task_date_piker {
    display: flex;
    flex-direction: column;
    .input_one {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  .task_detail_top_main_div {
    .my_checkbox {
      display: block;
    }
  }
  .task_detail_chakbox {
    .task_icon {
      display: none;
    }
  }
  .task_date_div_rel_new {
    width: unset;
    margin-left: unset;
  }
  .task_detail_main_btn_div {
    .show_btn {
      display: flex;
    }
  }
  .add_new_subtask_Uploaded_mai_div {
    .item_new2 {
      margin-right: 10px !important;
    }
    .item_new3 {
      margin-right: 10px !important;
    }
    .item_new4 {
      margin-right: 10px !important;
    }
    .item_new5 {
      margin-right: -15px !important;
    }
    .item_new6 {
      margin-right: 10px !important;
    }
  }
  .approval_request_details_feedback_ul {
    .feedback_modal_ul_commant {
      span {
        width: 100%;
      }
    }
    .feedback_modal_ul_commant_coyp_btn {
      top: -18px;
    }
  }
  .task_scrol_div1 {
    width: 100%;
    overflow: scroll;
  }
  .task_scrol_div2 {
    width: 46rem;
  }
  .approval_modal_sub_title_div {
    ul {
      .li_first {
        width: 8rem;
      }
    }
  }
  .feedback_modal_ul {
    padding-left: 18px !important;
    .feedback_modal_ul_commant {
      span {
        width: 100%;
      }
    }
    .add_new_subtask_Uploaded_mai_div {
      width: 100%;
    }
    .feedback_modal_ul_commant_coyp_btn {
      top: -21px;
    }
  }
  .add_new_subtask_Uploaded_mai_div {
    .item2 {
      margin-right: 10px;
    }
  }
  .approval_modal_btn {
    margin-top: 1rem;
  }
  .add_new_subtask_date_picker_div {
    display: flex;
    justify-content: flex-end;
  }
  .add_new_subtask_data_top_ui {
    .show_btn {
      display: block;
    }
    .show_btn2 {
      margin-right: 8px !important;
    }
  }
  .add_new_subtask_main_ui {
    flex-direction: column;
    .add_new_subtask_main_ui_input_div {
      width: 100%;
      margin-bottom: 10px;
      input {
        width: 100%;
      }
    }
  }
  .add_new_subtask_top_ui_inner_div {
    width: 100%;
    .add_new_subtask_uploader_div {
      label {
        margin-left: 0;
      }
    }
  }
  .approval_modal_sub_task_date_title {
    justify-content: center;
    display: flex;
  }
  .approval_modal_sub_task_list_date_title {
    justify-content: center;
    display: flex;
  }
  .approval_new_sub_task_main_div {
    .new_sub_task_top_div {
      padding-left: 0;
      ::-webkit-scrollbar {
        display: block !important;
      }
    }
  }
  .new_sub_task_list_item_edite {
    .input1 {
      input {
        width: 21rem;
      }
    }
  }
  .new_sub_task_outer_btn_div {
    text-align: start;
  }
  .new_sub_task_main_div {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }
  .new_sub_task_top_div {
    overflow-x: auto;
    width: 50rem;
    padding-left: 15px;
  }
  .add_sub_task_date_new_icon_div {
    position: absolute;
    top: 4px;
    right: 0;
  }
  .add_sub_task_date_new_div_top {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 21px 5px;
    margin-bottom: 30px;
  }
  .edit_btn_meeting_div {
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    .timer_switch_div {
      flex-direction: row;
      p {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      svg {
        display: block;
      }
    }
  }
  .attendee_add_btn {
    right: -25px;
  }
  .join_meeting_time {
    p {
      font-size: 13px;
    }
  }
  .join_meeting_sub_time_div {
    margin-left: 0px;
  }
  .join_meeting_side_link_div {
    margin-left: 0;
    width: 100%;
  }
  .join_meeting_side_line {
    display: none;
  }
  .setting_profile_edit_inner_img_div {
    width: 7rem;
    height: 7rem;
  }
  .setting_profile_img_div {
    text-align: center;
    h3 {
      margin-top: 1rem;
    }
  }
  .setting_profile_edit_img_div {
    width: 7rem;
    height: 7rem;
  }
  .setting_profile_save_btn {
    align-items: center;
    justify-content: center;
  }
  .setting_profile_img_div {
    flex-direction: column;
    align-items: center;
    img {
      margin-right: 0;
      width: 7rem;
      height: 7rem;
    }
  }
  .new_uplode_btn {
    span {
      display: none;
    }
  }
  .new_uplode_btn_div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .approval_request_input_remove_btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    top: 0;
    right: 4px;
  }
  .task_right_drawer_ui_body_tab {
    .nav-link {
      font-size: 13px;
    }
  }
  .task_right_drawer_ui_body_nav_div {
    p {
      font-size: 8px;
    }
  }
  .task_details_complete_btn {
    span {
      display: none;
    }
  }
  .timer_switch_div {
    position: relative;
    flex-direction: column;
    .switch {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    p {
      margin-right: 0;
      margin-bottom: 5px;
    }
    svg {
      display: none;
    }
  }
  .join_meeting_ul {
    h3 {
      font-size: 20px;
    }
  }
  .completed_btn_mob {
    width: 100%;
  }
  .management_btn_top_div {
    flex-direction: column;
    .mobile_btn {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    .management_btn_outline {
      width: 100%;
      text-align: center;
    }
    .management_btn_full {
      text-align: center;
      width: 100%;
    }
  }
  .notes_details_add_todo {
    padding-bottom: 45px;
  }
  .notes_details_bottom_btn_div_inner_top_div {
    margin-top: 10px;
  }
  .notes_details_bottom_btn_div {
    position: relative;
    display: block;
    height: 4rem;
    margin-top: 10px;
    .notes_details_bottom_btn_div_inner {
      .notes_details_bottom_btn {
        margin-bottom: 10px;
      }
    }
  }
  .new_note_box_div {
    max-height: 315px;
    min-height: auto;
  }
  .task_details_user_avatar_top {
    margin-left: -30px;
  }
  .date_filter_div {
    display: flex;
    flex-direction: column;
    .from {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    .to {
      margin-left: 0;
    }
  }
  .new_note_box_div {
    h3 {
      font-size: 18px;
    }
  }
  .new_note_box_user_bottom_div {
    margin-top: 1rem;
    position: relative;
    left: unset;
    bottom: unset;
  }
  .time_zone_div {
    display: flex;
    flex-direction: column;
    .nav {
      width: 100% !important;
      align-items: center;
    }
    .time_zone_inner_div {
      display: flex;
      align-items: center;
    }
  }
  .add_reminder_notification_btn_div {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  .sehedule_notification_delete_btn {
    position: absolute;
    top: -3px;
    right: 28px;
  }

  .sehedule_notification_time_div {
    flex-direction: column;
    p {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .sehedule_notification_top_div {
    border: 1px solid #ced4da;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    padding-bottom: 17px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .my_m {
      margin-right: 0;
    }
    .all_user_page {
      margin-bottom: 15px;
      width: 100% !important;
    }
  }
  .sehedule_notification_number_input_div {
    input {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .meeting_calendar_top_div {
    .fc .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .fc-toolbar-chunk {
      margin-bottom: 1rem;
    }
    .fc .fc-toolbar-title {
      font-size: 24px;
    }
  }
  .schedule_meeting_btn {
    display: block;
    .management_btn_full {
      font-size: 14px;
      width: 100%;
    }
  }
  .approval_btn {
    .management_btn_full {
      width: 100%;
      font-size: 14px;
    }
  }
  .approval_request_btn2 {
    .management_btn_outline {
      margin-right: 0;
    }
    .management_btn_full {
      margin-left: 10px;
    }
  }
  .rec_list_mid {
    border-right: none;
    border-bottom: 1px solid #d9d9d9;
  }
  .approval_request_title {
    h3 {
      font-size: 17px;
      span {
        margin-left: 0;
      }
    }
  }
  .scheduling_details_time_select_div {
    flex-direction: column;
    .all_user_page {
      width: 100% !important;
    }
    .scheduling_details_time_select_p {
      margin: 9px 0;
    }
  }
  .notes_details_user_info_div {
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
    }
    h3 {
      margin-bottom: 0;
    }
  }
  .join_meeting_btn_div {
    .management_btn_full {
      width: 100%;
    }
  }
  .task_right_drawer_ui_body_created {
    .li_1 {
      margin-right: 0;
    }
  }
  .issues_top_new_btn_div {
    margin-left: 2rem;
  }
  .issues_top_new_div {
    align-items: flex-start;
    flex-direction: column;
  }
  .new_comments_doc_div {
    .new_comments_doc_img_top_div {
      .new_comments_doc_download_div {
        display: flex;
      }
    }
  }
  .comments_doc_div {
    justify-content: center;
  }
  .new_comments_doc_div_top {
    justify-content: center;
  }
  .set_view_type_btn {
    margin-bottom: 1.5rem;
    .set_view_type_btn_inner {
      margin-right: 0;
    }
  }
  .set_view_type_btn_top {
    .all_user_page {
      margin-bottom: 0;
    }
  }
  .set_view_type_btn {
    display: flex;
    flex-direction: column;
    .new_btn_nav {
      display: block;
      margin-top: 15px;
    }
  }
  .search_result_data_inner_top_div {
    .search_result_data_inner_div {
      flex-direction: column;
      justify-content: center;
    }
    .search_result_data_inner_div img {
      margin-right: 0;
      width: 4rem;
      margin-bottom: 1rem;
      height: 4rem;
    }
    .search_result_data_inner_div {
      p {
        text-align: center;
      }
    }
  }
  .search_result_data_id p {
    font-size: 12px !important;
  }
  .chats_search_result_time_date {
    margin-left: 0;
  }
  .new_btn_nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .management_btn_outline_with_icon {
      width: 100%;
    }
  }
  .all_user_page_new {
    width: 100%;
  }
  .comments_typing_btn_inner_div {
    display: block;
    .management_btn_full2 {
      width: 100%;
      margin: 10px 0;
    }
    .management_btn_outline {
      width: 100%;
      margin-left: 0;
    }
  }
  .comments_typing_div {
    .comments_img {
      display: none;
    }
  }
  .comments_doc_name_dovnload_div {
    background-color: transparent;
    display: block;
  }
  .comments_doc_name_dovnload {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
  }

  .comments_doc_item {
    &:hover {
      .comments_doc_name {
        display: block;
        transition: all 0.3s;
      }
    }
  }
  .comments_img_ui_div {
    .comments_img_div:hover {
      .comments_img_name {
        display: flex;
      }
    }
  }
  .comments_img_name_download_div {
    display: block;
  }
  .comments_menu_btn {
    display: block;
  }
  .comments_menu_top_div {
    border-bottom: 1px solid #e4d9d9;
    margin-bottom: 15px;
  }
  .comments_img_inner {
    padding-left: 0;
    margin-top: 15px;
  }
  .comments_img_ui_div {
    // min-width: 20rem;
    .comments_img {
      height: 10rem;
    }
  }
  .task_right_drawer_ui_body_Subtasks_input_div {
    input {
      width: 90%;
    }
  }
  .task_right_drawer_ui_body_created2 {
    display: block;
  }
  .task_right_drawer_ui_body_created {
    display: block;
  }
  .task_right_drawer_ui_body_user_add {
    margin-top: 5px;
  }
  .task_right_drawer_ui_body_user_add_date {
    margin-top: 5px;
  }
  .task_right_drawer_ui_body_user {
    position: relative;
    top: unset;
    right: unset;
  }
  .task_right_drawer_ui_body_project_title {
    p {
      font-size: 16px;
    }
  }
  .task_right_drawer_ui_body_Dependency_select {
    select {
      width: 100%;
    }
  }
  .task_right_drawer_ui_main_div {
    width: 23rem;
  }
  .team_members_ui_modal {
    width: 95%;
  }
  .poll_settings_div_modal {
    width: 95% !important;
  }
  .create_poll_modal_top_div {
    border-right: unset;
    padding-right: 15px !important;
  }
  .create_poll_modal {
    width: 95%;
    height: 40rem;
    margin-top: 0rem;
    overflow: scroll;
    padding-bottom: 2rem !important;
  }
  .filter_new_top_div_inner {
    width: 17rem;
    span {
      font-size: 15px;
    }
  }
  .all_user_page {
    .view_change_label {
      span {
        display: block;
      }
    }
  }
  .team_list_box {
    width: 100%;
  }
  .user_card_div {
    width: 100%;
    overflow: scroll;
  }
  .Project_list_card_time {
    padding: 5px 4px;
    font-size: 10px;
    right: 7px;
    top: 10px;
    display: none;
    span {
      svg {
        font-size: 15px;
        margin-right: 1px;
      }
    }
  }
  .Project_list_card_progress {
    p {
      font-size: 13px;
    }
  }
  .Project_list_card_progress {
    .progress {
      height: 15px;
    }
  }
  .Project_list_card_inner_card_div {
    justify-content: center;
    .project_details_card_box {
      width: 7rem;
    }
  }
  .Project_list_card_inner_img_div {
    justify-content: center;
  }
  .Project_list_card_inner_img_top_div {
    margin-top: 1rem;
  }
  .Project_list_card_img_div {
    top: 0;
  }
  .Project_list_card_title {
    text-align: center;
  }
  .client_tasks_div {
    position: relative;

    .management_btn_full {
      position: absolute;
      width: 61%;
      right: -8px;
      top: -4px;
      text-align: center;
    }
  }
  new .clint_profile_card_poits_mob {
    justify-content: flex-end;
  }
  .clint_profile_card_poits {
    align-items: baseline;
    margin-bottom: 5px;
    p {
      font-size: 9px;
    }
  }
  .clint_profile_card_text {
    margin-top: 1rem;
    text-align: center;
  }
  .clint_profile_card_img {
    text-align: center;
  }
  .notes_warning {
    position: relative;
    right: 0;
    margin-left: 2rem;
  }
  .blue_btn_top_div {
    a {
      width: 100%;
      display: block;
      text-align: center;
      padding: 10px 20px;
    }
  }
  .filter_btn_top_div {
    .management_btn_outline_with_icon {
      width: 100%;
    }
  }
  .view_all_tasks_title_div {
    position: relative;
    a {
      position: absolute;
      top: -31px;
      right: 0;
    }
  }
  .view_all_tasks_title_div {
    p {
      font-size: 15px;
    }
  }
  .classic_view_inner_body {
    padding: 0px 10px 10px 10px !important;
  }
  .classic_view_outer {
    .classic_view_outer_body {
      padding: 0px;
    }
  }
  .view_change_main_div {
    .css-1s2u09g-control {
      min-height: 37px;
    }
  }
  .view_change_label {
    span {
      display: none;
    }
  }
  .view_change_main_div {
    top: -62px;
    right: 48px;
  }
  .project_list_tabs_inner_btn {
    .management_btn_outline_with_icon {
      padding: 10px 10px;
      span {
        display: none;
      }
      svg {
        font-size: 15px;
      }
    }
  }
  .header_my {
    .header_btn {
      .management_btn_full2 {
        width: 50%;
      }
    }
  }
  .project_tab_mb {
    margin-bottom: 1.5rem;
  }
  .Dashboard_menu_right {
    position: absolute;
    top: 48px;
    right: 1px;
    background-color: white;
    border-radius: 3px;
    padding: 3px 10px;
    .chat_div_dropdown .rs-dropdown-toggle svg,
    .Dashboard_menu_right .chat_div_dropdown .rs-dropdown-toggle.rs-btn svg {
      transform: rotate(90deg);
      font-size: 23px !important;
    }
  }
  .Progress_title_details_white {
    height: auto;
  }
  .team_status_inner_title2 {
    li {
      margin-right: 40px;
    }
  }
  .project_details_card_box {
    span {
      font-size: 11px;
    }
  }
  .Progress_title_details_top {
    position: relative;
  }
  .Progress_title_details {
    position: absolute;
    bottom: -33px;
  }
  .avatar_inner_div {
    margin-top: 10px;
    justify-content: center;
  }
  .inner_mr {
    margin: 0 0;
  }
  .service_overview_details {
    h4 {
      font-size: 11px;
    }
    h5 {
      font-size: 11px;
    }
  }
  .doc_img_main_div .doc_img_main_div_inner {
    svg {
      right: -10px;
      font-size: 14px;
    }
  }
  .documents_app_btn {
    .management_btn_full2 {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    label {
      span {
        display: none;
      }
    }
  }
  .all_edit_icon .all_edit_icon_inner {
    right: -8px;
    font-size: 13px;
    width: 20px;
    height: 20px;
  }
  .overview_basic_details_div {
    ul {
      li {
        font-weight: 600;
        font-size: 12px;
        p {
          font-size: 10px;
        }
      }
    }
  }
  .circular_static_div {
    span {
      width: 5rem !important;
      height: 5rem !important;
    }
  }
  .team_work_progress {
    display: block;
  }
  .circular_static_div_text {
    h3 {
      display: block;
      font-size: 16px;
    }
    p {
      display: block;
      font-size: 10px;
      margin-bottom: 7px;
    }
  }
  .circular_static_div_main_div {
    justify-content: center;
    margin-top: 1.5rem;
  }
  .circular_static_div_text {
    margin: 0;
  }
  .ProjectDetailsCard_line_mobile {
    display: block;
  }
  .circular_static_div {
    display: block;
    margin-right: 10px;
    .css-ol7wxs-MuiTypography-root {
      width: 90%;
      height: 90%;
      font-size: 15px;
    }
  }
  .project_details_card_box {
    padding: 7px 7px;
    width: 6rem;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 5px;
    p {
      font-size: 10px;
    }
  }
  .project_details_card_box_main_div {
    text-align: center;
    display: block;
    .MuiAvatarGroup-root {
      justify-content: center !important;
      margin-top: 18px !important;
    }
  }
  .project_details_card_name {
    margin-bottom: 10px;
    text-align: center;
    h3 {
      font-size: 19px;
      margin-top: 10px;
    }
  }
  .ProjectDetailsCard_img {
    width: 15rem;
    height: 15rem;
  }
  .img_dashbaord_white_card_new {
    text-align: center;
  }
  .dashbaord_white_card_new {
    padding: 1rem;
  }
  .page_All_top_title {
    h3 {
      font-size: 13px;
    }
  }
  .project_list_name_top_div {
    position: relative;
    .today_meetings_btn_div {
      position: absolute;
      top: 0;
      right: 5px;
      .management_btn_outline {
        padding: 4px 10px;
      }
    }
  }
  .milestones_emp {
    margin-left: unset;
  }
  .projec_tabelt_list_pagination_div {
    ul {
      li {
        button {
          min-width: 25px;
          height: 25px;
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  .progress_all_tabel_tabel {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }
  .progress_all_tabel {
    .progress {
      display: none;
    }
  }
  .project_list_tabs_inner_btn {
    top: -62px;
  }
  .project_list_tabs_main_div {
    .nav-pills .nav-link {
      width: 13rem;
      text-align: center;
    }
    .nav {
      overflow-x: scroll;
      width: 100%;
      flex-wrap: nowrap;
    }
  }

  .welcome_note_btn_div {
    .management_btn_full2 {
      font-size: 12px;
      width: 100px;
      position: absolute;
      top: 0;
      right: 10px;
      padding: 8px 8px;
    }
  }
  .project_summary_title {
    h3 {
      font-size: 16px;
    }
  }
  .project_list_progress {
    width: 13rem;
    margin-top: 7px;
    .progress {
      width: 10rem;
    }
    span {
      position: absolute;
      top: -10px;
      right: 50px;
    }
  }
  .project_tabel {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }
  .CreateBenchmark_brn {
    button {
      width: 50%;
    }
  }
  .SavedBenchmark_btn {
    button {
      width: 50%;
    }
  }
  .select_team_leaders_CheckBox_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Select_Services_row_new {
    border: 2px solid #e6dede;
    padding: 20px 0px;
    border-radius: 8px;
    padding-top: 13px;
    width: 98%;
  }
  .Select_Services_row_new_inner_top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem !important;
  }

  .Select_Services_row_new_inner {
    position: absolute;
    top: -17px;
    background: white;
    width: 25px;
    left: 20px;
    color: #d6caca;

    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 550;
    }
  }

  .add_Department_btn_div {
    margin-top: 3rem !important;
  }
  .Select_Services_row {
    position: relative;

    .management_btn_outline {
      padding: 10px 20px;
      font-size: 15px;
      width: 94%;
      position: absolute;
      left: 0;
      margin-top: 10px;
      margin-left: 12px;
    }
  }
  .management_btn_outline {
    font-size: 11px;
  }
  .Client_Profile_title {
    display: block;
    text-align: center;
  }
  .Client_Profile_main_top_div {
    display: none;
  }
  .Client_Profile_svg {
    svg {
      margin-left: 25px;
      top: 3px;
    }
    label {
      display: none;
    }
  }
  .Client_Profile_svg_last_text {
    display: none;
  }

  .welcome_note_mobile {
    position: absolute;
    right: 0;
    top: 0;
  }
  .cl_new_btn {
    .management_btn_full {
      display: block;
      text-align: center;
      font-size: 14px;
    }
  }
  .management_btn_full {
    font-size: 11px;
  }
  .welcome_note_div {
    h3 {
      font-size: 17px;
      margin-bottom: 4px;
    }
  }
}
@media (min-width: 768px) {
  .task_detail_text_div {
    input {
      width: 39rem;
      transition: all 0.3s ease-in-out;
    }
  }
  .create_task_date_piker_input {
    input {
      width: 100% !important;
    }
  }
  .task_detail_top_main_div {
    .my_checkbox {
      display: block;
    }
  }
  .task_detail_chakbox {
    .task_icon {
      display: none;
    }
  }
  .task_date_div_rel_new {
    width: unset;
    margin-left: unset;
  }
  .task_detail_main_btn_div {
    .show_btn {
      display: flex;
    }
  }
  .add_new_subtask_Uploaded_mai_div {
    .item_new2 {
      margin-right: 10px !important;
    }
    .item_new3 {
      margin-right: 10px !important;
    }
    .item_new4 {
      margin-right: 10px !important;
    }
    .item_new5 {
      margin-right: 10px !important;
    }
    .item_new6 {
      margin-right: 10px !important;
    }
  }
  .task_scrol_div1 {
    width: 100%;
    overflow: scroll;
  }
  .task_scrol_div2 {
    width: 46rem;
  }
  .add_new_subtask_Uploaded_mai_div {
    .item2 {
      margin-right: 10px;
    }
  }
  .approval_new_sub_task_main_div {
    .new_sub_task_top_div {
      padding-left: 0;
    }
  }
  .approval_modal_sub_task_date_title {
    justify-content: center;
    display: flex;
  }
  .approval_modal_sub_task_list_date_title {
    justify-content: center;
    display: flex;
  }
  .new_sub_task_main_div {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }
  .new_sub_task_top_div {
    overflow-x: auto;
    width: 50rem;
    padding-left: 15px;
  }
  .management_btn_top_div {
    justify-content: flex-start;
  }
  .new_note_box_div {
    h3 {
      font-size: 20px;
    }
  }
  .time_zone_div {
    display: flex;
    flex-direction: column;
    .nav {
      margin-bottom: 1rem;
      width: 100% !important;
      align-items: center;
    }
  }
  .schedule_meeting_btn {
    display: flex;
  }
  .join_meeting_ul {
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
  }
  .join_meeting_side_line {
    width: 100%;
    height: 2px;
    margin-bottom: 1rem;
  }
  .join_meeting_side_line_div {
    flex-direction: column;
  }
  .task_right_drawer_ui_main_div {
    width: 40rem;
  }
  .create_poll_modal {
    width: 90%;
  }
  .notes_warning {
    position: relative;
    right: 0;
    margin-left: 2rem;
  }
  .filter_btn_top_div {
    .management_btn_outline_with_icon {
      width: 20%;
    }
  }
  .view_all_tasks_title_div {
    p {
      font-size: 13px;
    }
  }
  .view_change_main_div {
    .css-1s2u09g-control {
      min-height: 37px;
    }
  }
  // .view_change_label {
  //   span {
  //     display: none;
  //   }
  // }
  .view_change_main_div {
    top: -60px;
    right: 48px;
  }
  .project_list_tabs_inner_btn {
    .management_btn_outline_with_icon {
      padding: 10px 10px;
      span {
        display: none;
      }
      svg {
        font-size: 15px;
      }
    }
  }
  .header_my {
    .header_btn {
      .management_btn_full2 {
        width: 70%;
      }
    }
  }
  .Dashboard_menu_right {
    position: absolute;
    top: 46px;
    right: 1px;
    background-color: white;
    border-radius: 3px;
    padding: 3px 10px;
    .chat_div_dropdown .rs-dropdown-toggle svg,
    .Dashboard_menu_right .chat_div_dropdown .rs-dropdown-toggle.rs-btn svg {
      transform: rotate(0deg);
    }
  }
  .project_tab_mb {
    margin-bottom: 1.5rem;
  }
  .avatar_inner_div {
    margin-top: 10px;
  }
  .ProjectDetailsCard_line_mobile {
    display: none;
  }
  .circular_static_div_text {
    h3 {
      display: none;
    }
    p {
      display: none;
    }
    button {
      position: absolute;
      top: 22px;
      right: 20px;
      width: 9rem;
    }
  }
  .circular_static_div {
    display: block;
    position: absolute;
    top: 48%;
    right: 8%;
    span {
      width: 4rem !important;
      height: 4rem !important;
    }
  }
  .project_details_card_box_main_div {
    display: block;
    .MuiAvatarGroup-root {
      justify-content: flex-end !important;
      margin-top: 10px !important;
    }
  }
  .project_details_card_name {
    margin-bottom: 10px;
  }
  .progress_all_tabel_tabel {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }
  .progress_all_tabel {
    .progress {
      display: none;
    }
  }
  .project_list_tabs_inner_btn {
    top: -60px;
  }
  .project_list_tabs_main_div {
    .nav-pills .nav-link {
      width: 13rem;
      text-align: center;
    }
    .nav {
      overflow-x: scroll;
      width: 100%;
      flex-wrap: nowrap;
    }
  }
  .project_list_progress {
    width: 10rem;
  }
}
@media (min-width: 1024px) {
  .task_detail_text_div {
    input {
      width: 40.9rem;
      transition: all 0.3s ease-in-out;
    }
  }
  .task_detail_top_main_div {
    .my_checkbox {
      display: none;
    }
  }
  .task_detail_chakbox {
    .task_icon {
      display: block;
    }
  }
  .task_date_div_rel_new {
    width: 6rem;
    margin-left: -38px;
  }
  .task_detail_main_btn_div {
    .show_btn {
      display: none;
    }
  }
  .add_new_subtask_Uploaded_mai_div {
    .item_new2 {
      margin-right: 10px !important;
    }
    .item_new3 {
      margin-right: 10px !important;
    }
    .item_new4 {
      margin-right: 10px !important;
    }
    .item_new5 {
      margin-right: -17px !important;
    }
    .item_new6 {
      margin-right: -17px !important;
    }
  }
  .task_scrol_div1 {
    width: 100%;
    overflow: unset;
  }
  .task_scrol_div2 {
    width: 100%;
  }
  .add_new_subtask_Uploaded_mai_div {
    .item2 {
      margin-right: 4px;
    }
  }
  .approval_new_sub_task_main_div {
    .new_sub_task_top_div {
      padding-left: 0;
    }
  }
  .new_sub_task_top_div {
    overflow-x: unset;
    width: 100%;
    padding-left: 15px;
  }
  .management_btn_top_div {
    justify-content: flex-end;
  }
  .new_note_box_div {
    h3 {
      font-size: 18px;
    }
  }
  .time_zone_div {
    display: flex;
    flex-direction: row;
    .nav {
      margin-bottom: 0;
      width: 31% !important;
      align-items: center;
    }
  }
  .scheduling_details_time_select_div {
    .all_user_page {
      width: 6rem;
      .css-1xc3v61-indicatorContainer {
        padding-right: 1px;
      }
    }
  }
  .join_meeting_ul {
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  .join_meeting_side_line {
    width: 2px;
    height: 100%;
    margin-bottom: 0;
  }
  .join_meeting_side_line_div {
    flex-direction: row;
  }
  .project_list_name_new {
    width: 19rem;
  }
  .task_right_drawer_ui_main_div {
    width: 50rem;
  }
  .create_poll_modal {
    width: 80%;
  }
  .notes_warning {
    position: absolute;
    right: 13%;
    margin-left: 2rem;
  }
  .filter_btn_top_div {
    .management_btn_outline_with_icon {
      width: 14%;
      position: relative;
    }
  }
  .view_all_tasks_title_div {
    p {
      font-size: 24px;
    }
  }
  .view_change_main_div {
    .css-1s2u09g-control {
      min-height: 42px;
    }
  }
  .view_change_label {
    span {
      display: block;
    }
  }

  .view_change_main_div {
    top: 3px;
    right: 136px;
  }
  .project_list_tabs_inner_btn {
    .management_btn_outline_with_icon {
      padding: 10px 20px;
      span {
        display: block;
      }
      svg {
        font-size: 20px;
      }
    }
  }
  .header_my {
    .header_btn {
      .management_btn_full2 {
        width: 50%;
      }
    }
  }
  .Dashboard_menu_right {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
  .project_tab_mb {
    margin-bottom: 1.5rem;
  }
  .avatar_inner_div {
    margin-top: 0;
  }
  .circular_static_div_text {
    h3 {
      display: block;
    }
    p {
      display: block;
    }
    button {
      position: relative;
    }
  }
  .project_details_card_box_main_div {
    display: flex;
    .css-1ytufz-MuiAvatarGroup-root {
      justify-content: unset;
      margin-top: unset;
    }
  }
  .circular_static_div_text {
    margin: 0 11px;
  }
  .circular_static_div {
    position: absolute;
    top: 17px;
    right: 25%;
    span {
      width: 5rem !important;
      height: 5rem !important;
    }
  }
  .ProjectDetailsCard_img {
    width: 9rem;
    height: 9rem;
  }
  .project_details_card_box {
    padding: 7px 12px;
  }
  .project_details_card_name {
    margin-bottom: 2.4rem;
  }
  .progress_all_tabel_tabel {
    display: inline-table;
  }
  .progress_all_tabel {
    .progress {
      display: flex;
    }
  }
  .project_list_tabs_inner_btn {
    top: 0px;
  }
  .project_list_tabs_main_div {
    .nav-pills .nav-link {
      width: unset;
    }
    .nav {
      overflow-x: unset;
      width: 100%;
      flex-wrap: wrap;
    }
  }
  .project_list_progress {
    width: 7rem;
  }
}
@media (min-width: 1440px) {
  .add_new_subtask_Uploaded_mai_div {
    .item_new2 {
      margin-right: -32px !important;
    }
    .item_new3 {
      margin-right: -25px !important;
    }
    .item_new4 {
      margin-right: -6px !important;
    }
    .item_new5 {
      margin-right: -10px !important;
    }
    .item_new6 {
      margin-right: -10px !important;
    }
  }
  .new_note_box_div {
    h3 {
      font-size: 20px;
    }
  }
  .time_zone_div {
    display: flex;
    .nav {
      width: 27% !important;
      align-items: center;
    }
  }
  .scheduling_details_time_select_div {
    .all_user_page {
      width: 8rem;
      .css-1xc3v61-indicatorContainer {
        padding-right: 5px;
      }
    }
  }
  .project_list_name_new {
    width: auto;
  }
  .create_poll_modal {
    width: 50%;
  }
  .filter_btn_top_div {
    .management_btn_outline_with_icon {
      width: 12%;
    }
  }
  .header_my {
    .header_btn {
      .management_btn_full2 {
        width: 35%;
      }
    }
  }
  .circular_static_div_text {
    margin: 0 20px;
  }
  .ProjectDetailsCard_img {
    width: 10rem;
    height: 10rem;
  }
  .project_details_card_box {
    padding: 7px 13px;
  }
  .project_details_card_name {
    margin-bottom: 3.4rem;
  }
  .project_list_progress {
    width: 13rem;
  }
  .circular_static_div {
    position: relative;
    top: unset;
    right: unset;
    span {
      width: 7rem !important;
      height: 7rem !important;
    }
  }
}
