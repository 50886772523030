@import '../../../../../scss/--mixing.scss';
@import '../../../../../scss/--var.scss';
.DefaultDataTabs_div {
  padding-top: 0;
}

.ToDoList_card {
  position: relative;
  padding: 16px;
  padding-left: 22px;
  border-bottom: 1px solid var(--search-box-border-color);
  transition: all 0.3s ease-in-out;
  .ToDoListCardTitle_div {
    display: flex;
    align-items: flex-start;
    span {
      display: flex;
      svg {
        font-size: 18px;
        color: var(--first-text-color);
        margin-top: 2px;
        margin-right: 8px;
      }
    }
    h3 {
      color: var(--first-text-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
      @include FontFamily;
    }
  }
  .ToDoListCardPoint_div {
    // Participants
    .MuiAvatar-root {
      border-color: var(--search-box-border-color);
      color: var(--first-text-color);
    }
    .MuiSvgIcon-root {
      color: var(--second-text-color);
    }
    .MuiAvatar-circular {
      background-color: var(--element-bg2);
    }
    margin-left: 26px;
    .Participants_icon {
      font-size: 12px;
      right: -13px;
      top: 6px;
      color: var(--second-text-color);
    }
    .Participants_main_div_inner {
      .MuiAvatar-circular {
        width: 20px;
        height: 20px;
      }
    }

    // Participants end
    .ToDoListCardInnerPoint_div {
      p {
        color: var(--first-text-color);
        margin-bottom: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span {
        color: var(--first-text-color);
        margin-bottom: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .ToDoListCardCreatedBy_div {
        margin-top: 5px;
        display: flex;
        align-items: center;
        .ToDoListCardCreatedByAvatar_div {
          width: 20px;
          height: 20px;
          font-size: 12px;
          margin-right: 8px;
        }
        .inner_icon {
          margin-right: 8px;
          color: var(--second-text-color);
          width: 16px;
          height: 16px;
        }
        .inner_iconText {
          color: var(--first-text-color);
        }
        small {
          color: var(--first-text-color);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
        }
        a {
          color: #3656f4;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
  &:hover {
    background-color: var(--search-box-data-hover);
    .box_ui {
      display: flex;
    }
  }
  .box_ui {
    background-color: var(--element-bg2);
    border-radius: 6px;
    max-width: fit-content;
    padding: 4px;
    padding-right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    height: 48px;
    position: absolute;
    top: 6px;
    right: 41%;
    border: 1px solid var(--search-box-border-color);
    .icon_btn_div {
      cursor: pointer;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease-in-out;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      &:hover {
        background-color: var(--search-box-data-hover);
      }
      img {
        width: 16px;
        height: 16px;
        @include bg_img;
      }
      .svg1 {
        width: 16px;
        height: 16px;
        color: var(--second-text-color);
      }
      .svg2 {
        font-size: 20px;
        color: var(--delete-color);
      }
    }
  }
  .Mark_delete {
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
  }
}
.modalUi_img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
.NewAttachments_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  .NewAttachmentsView_inner {
    align-self: flex-start;
  }
  .add_new_subtask_Uploaded_img_div {
    p {
      color: var(--first-text-color) !important;
      background-color: var(--search-box-data-hover);
    }
  }
}
.NewAttachments_view_modal {
  .rs-modal-content {
    background-color: var(--element-bg2);
  }
  .sub_task_modul_title_div2 {
    color: var(--first-text-color);
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-transform: capitalize;
  }
  .close_icon {
    background-color: transparent;
    width: 25px;
    display: flex;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: var(--search-box-data-hover);
    }
  }
  .sub_task_modul_top_close_btn_div {
    color: var(--first-text-color);
    position: relative !important;
    top: unset !important;
    right: unset !important;
  }
}
.AddReminder_top_div {
  padding: 16px;
  .addNotes_title_inputDiv {
    padding: 0;
  }
}
.AddReminder_DatePicker {
  display: flex;
  align-items: center;
  border: 1px solid var(--search-box-border-color);
  justify-content: center;
  width: 219px;
  border-radius: 6px;
  .main_btn {
    padding: 0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    span {
      margin-left: 0;
      margin-right: 8px;
      svg {
        width: 16px;
        height: 16px;
        color: var(--second-text-color);
      }
    }
    &:hover {
      background-color: transparent;
    }
  }
  .new_select_box {
    margin-left: 5px;
    .css-1n6sfyn-MenuList {
      width: 100px;
    }
    .css-t3ipsp-control {
      box-shadow: none;
      border-color: transparent;
      &:hover {
        border: none;
      }
    }
    .css-1nmdiq5-menu {
      width: 100px;
    }
    input {
      color: var(--first-text-color) !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      margin: 0 !important;
      @include FontFamily;
      &::placeholder {
        color: var(--first-text-color) !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        @include FontFamily;
      }
    }
    .css-1jqq78o-placeholder {
      color: var(--first-text-color);
      margin: 0;
    }
    label {
      display: none;
    }
    .css-13cymwt-control {
      border: none;
      padding: 0;
    }
    .css-1fdsijx-ValueContainer {
      padding: 0;
    }
    .small {
      display: none;
    }
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }
}
.AddReminder_DatePicker_top_inner {
  display: flex;
  align-items: center;
}
.AddReminder_DatePicker_top {
  display: flex;
  align-items: center;
  .quick_note_filter_btn_div {
    margin-right: 8px;
    margin-left: 8px;
    height: 40px;
    border: 1px solid var(--search-box-border-color);
    svg {
      margin-left: 0;
      margin-right: 8px;
      color: var(--second-text-color);
    }
  }
}
.NewShort_btn {
  background-color: transparent;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: var(--search-box-data-hover);
  }
  svg {
    font-size: 15px;
    color: var(--second-text-color);
  }
}
.Showcontent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.Showcontent_open {
  max-height: 200px; /* Adjust height based on content */
  opacity: 1;
}

.Showcontent_closed {
  max-height: 0;
  opacity: 0;
}
.saved-link {
  .MuiButtonBase-root {
    .text {
      color: #d1242f !important;
    }
  }
}
.BlankImage_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30rem;
  text-align: center;
  img {
    @include bg_img;
  }
  p {
    margin-top: 10px;
    color: #636c76;
    margin-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.inner_icon_div {
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 0 !important;
  margin-right: 4px;
  svg {
    margin-right: 0 !important;
  }
}
.ant-list-split .ant-list-item {
  border-bottom: 0;
}
.ant-list .ant-list-item {
  padding: 0;
}
.addReminder_linkBtn1 {
  .text_btn {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
}
.MuiBackdrop-root {
  // background-color: rgba(22, 27, 34, 0.4) !important;
  background-color: transparent !important;
}
.New_mui_Pagination {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 16px;
  padding-top: 16px;
  .Mui-selected {
    background-color: var(--search-box-data-hover) !important;
  }
  .MuiButtonBase-root {
    color: var(--first-text-color);
    svg {
      color: var(--first-text-color);
    }
    &:hover {
      background-color: var(--search-box-data-hover) !important;
    }
  }
}
@media (max-width: 576px) {
  .ToDoList_card {
    .Mark_delete {
      top: -15px;
      right: 7px;
    }
  }
  .AddReminder_DatePicker_top {
    flex-direction: column;
    align-items: flex-start;
    .AddReminder_DatePicker {
      margin-bottom: 8px;
    }
    .quick_note_filter_btn_div {
      margin-left: 0;
    }
  }
  .ToDoList_card {
    .ToDoListCardPoint_div {
      margin-left: 16px;
    }
  }
}
