.App2 {
  background-color: var(--background-color);
  transition: background-color 0.25s ease-in-out;
  display: flex;
}

.title {
  color: var(--primary-text-color);
  margin-bottom: 50px;
}

.box {
  padding: 20px;
  border-radius: 10px;
  background-color: var(--foreground-color);
  color: var(--secondary-text-color);
}
/*  */
