$c1: #dde2ff;
$c2: #0a0e30;
$cor: #2dcd1f;
$cgr: #a8a8a8;
$text: #666;
$red: #da1e28;
$green: #24a148;
$blue: #0f62fe;
$hover: #0f62fed9;
:root {
  --search-box-border-color: #d0d7de;
  --search-box-text-color: #636c76;
  --search-box-bg-color: #fff;
  --search-box-data-hover: #eef1f4;
  --element-bg: #ffffff;
  --element-bg-box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12),
    0px 1px 3px 0px rgba(31, 35, 40, 0.12);
  --first-text-color: #1f2328;
  --second-text-color: #636c76;
  --selected-second-bg-color: #3656f4;
  --icon-btn-text-color: #636c76;
  --icon-btn-bg-color: #fff;
  --icon-btn-hover-border-color: #1f2328;
  --element-bg2: #fff;
  --bg: #f6f8fa;
  --tabel-box-bg: #f6f8fa;
  --timesheet-top-bg: rgba(209, 204, 254, 0.5);
  --top-nav-bg: #fff;
  --b-w: #000;
  --checked-bg: #dafbe1;
  --mic-color: rgb(95, 99, 104);
  --delete-color: #d1242f;
}

[data-theme='dark'] {
  --search-box-border-color: #30363d;
  --search-box-text-color: #848d97;
  --search-box-bg-color: #0d1117;
  --search-box-data-hover: #292e36;
  --element-bg: #0d1117;
  --element-bg-box-shadow: 0px 1px 3px 0px rgba(31, 35, 40, 0.12),
    0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  --first-text-color: #e6edf3;
  --second-text-color: #848d97;
  --selected-second-bg-color: #2a3284;
  --icon-btn-text-color: #c9d1d9;
  --icon-btn-bg-color: #21262d;
  --icon-btn-hover-border-color: #fff;
  --element-bg2: #161b22;
  --bg: #161b22;
  --tabel-box-bg: #21262d;
  --timesheet-top-bg: rgba(36, 39, 98, 0.3);
  --top-nav-bg: #24292f;
  --b-w: #fff;
  --checked-bg: #dafbe129;
  --mic-color: #fff;
  --delete-color: #f85149;
}
