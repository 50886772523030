.container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    overflow-x: hidden;

    main {
        padding: var(--padding-sm) var(--padding-md);
        max-width: 1600px;
        @media screen and (min-width: 1600px) {
            margin: 0 auto;
        }
        @media screen and (max-width: 300px) {
            padding: var(--padding-xs);
        }
    }
}
