:root {
  --background-color: #f6f6f6;
  --foreground-color: #003cff;
  --primary-text-color: #0a0a0a;
  --secondary-text-color: #ffffff;
  /* right-navbar */
  --right-navbar-background-color: white;
  /* right-navbar end */
}

[data-theme='dark'] {
  --background-color: #0a0a0a;
  --foreground-color: #567aff;
  --primary-text-color: #f6f6f6;
  --secondary-text-color: #0a0a0a;
  /* right-navbar */
  --right-navbar-background-color: #161b22;
  /* right-navbar end */
}
/*  right-navbar */
.right_nav {
  background-color: var(--right-navbar-background-color);
}
/*  right-navbar end */
